import { Route, Switch, useRouteMatch } from 'react-router'
import React from 'react'
import CustomerDetailsPage from './CustomerDetailsPage'
import CustomerSearchPage from './CustomerSearchPage'

function CustomerPage() {
    const { path } = useRouteMatch()

    return (
        <div>
            <Switch>
                <Route path={`${path}/search`}>
                    <CustomerSearchPage />
                </Route>
                <Route exact path={`${path}/:customerId`}>
                    <CustomerDetailsPage />
                </Route>
            </Switch>
        </div>
    )
}

export default CustomerPage
