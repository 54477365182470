import { Page } from '../../common'
import { CButton, CCol, CFormInput, CFormLabel, CRow, CSpinner } from '@coreui/react-pro'
import { useCreatePurchaseOrder } from '../components/useCreatePurchaseOrder'
import { SelectPackageLocationDropdown } from '../components/SelectPackageLocationDropdown'
import { SelectSuppliersDropdown } from '../components/SelectSupplierDropdown'
import OrderLineTable from '../components/OrderLineTable'
import { useEffect } from 'react'

export const CreatePurchaseOrderPage = () => {
    const {
        isLoading,
        error,
        createPurchaseOrder,
        addOrUpdateOrderLine,
        setSupplier,
        setPackageLocationId,
        packageLocations,
        products,
        setOrderNo,
        suppliers,
        purchaseOrder,
    } = useCreatePurchaseOrder()

    const supplierSearchParam = new URLSearchParams(window.location.search).get('supplier')

    useEffect(() => {
        if (supplierSearchParam) {
            const supplier = suppliers?.find((s) => s.id === supplierSearchParam)
            if (supplier) {
                setSupplier(supplier)
            }
        }
    }, [supplierSearchParam, suppliers])

    const selectedSupplier = suppliers?.find((supplier) => supplier.externalId === purchaseOrder.supplierId)

    if (error || isLoading) {
        return (
            <Page title={'Opprett innkjøpsordre'}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {error || <CSpinner />}
                </div>
            </Page>
        )
    }

    return (
        <Page title={'Opprett innkjøpsordre'}>
            <CRow>
                <CCol md={4} sm={12}>
                    <CFormLabel className={'mt-3'}>Ordrenummer</CFormLabel>
                    <CFormInput
                        style={{ width: '200px' }}
                        type="text"
                        value={purchaseOrder.orderNo}
                        onChange={(e) => setOrderNo(e.currentTarget.value)}
                    />
                </CCol>

                <CCol md={4} sm={12}>
                    <CFormLabel className={'mt-3'}>Pakksted</CFormLabel>
                    <SelectPackageLocationDropdown
                        locations={packageLocations}
                        onSelect={(packageLocation) => setPackageLocationId(packageLocation.code)}
                        selected={packageLocations?.find(
                            (location) => location.code === purchaseOrder.packageLocationId
                        )}
                    />
                </CCol>

                <CCol
                    md={4}
                    sm={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CFormLabel className={'mt-3'}>Leverandør</CFormLabel>
                    <div
                        style={{
                            width: '200px',
                        }}
                    >
                        <SelectSuppliersDropdown
                            suppliers={suppliers}
                            onSelect={setSupplier}
                            selected={selectedSupplier}
                        />
                    </div>
                </CCol>
            </CRow>
            <CRow className={'mt-4'}>
                <CCol>
                    <OrderLineTable
                        noItemsLabel={
                            purchaseOrder.supplierId
                                ? `Ingen produkter funnet for ${selectedSupplier?.name} (som har SKU)`
                                : 'Velg leverandør'
                        }
                        products={products || []}
                        purchaseOrder={purchaseOrder}
                        onOrderLineChange={addOrUpdateOrderLine}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    Produkter: {purchaseOrder.orderLines.length}
                    <ul>
                        {purchaseOrder.orderLines.map((ol) => (
                            <li key={ol.sku}>
                                {ol.title} - {ol.quantity} fPak
                            </li>
                        ))}
                    </ul>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CButton
                        disabled={!purchaseOrder.orderLines.length}
                        color={'primary'}
                        style={{ float: 'right' }}
                        onClick={createPurchaseOrder}
                    >
                        Opprett
                    </CButton>
                </CCol>
            </CRow>
        </Page>
    )
}
