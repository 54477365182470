import { CFormSwitch } from '@coreui/react-pro'
import { usePublishToRegionMutation, useUnpublishFromRegionMutation } from '../store/productApi'
import { useEffect, useState } from 'react'
import { addToast } from '../../common/store/commonSlice'
import { useDispatch } from 'react-redux'
import { useRegions } from '../../region/store/regionApi'

interface PublishToRegionSelectProps {
    productId: string
    activeRegionIds: number[]
}

export default function PublishToRegionSelect({ productId, activeRegionIds }: PublishToRegionSelectProps) {
    const regions = useRegions()
    const dispatch = useDispatch()
    const [publishToRegion, publishToRegionResult] = usePublishToRegionMutation()
    const [unpublishFromRegion, unpublishFromRegionResult] = useUnpublishFromRegionMutation()

    const [publishedRegions, setPublishedRegions] = useState<number[]>(activeRegionIds)

    useEffect(() => {
        setPublishedRegions(activeRegionIds)
    }, [activeRegionIds])

    useEffect(() => {
        if (publishToRegionResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produkt lagt til i region',
                    color: 'success',
                })
            )
        }
        if (publishToRegionResult.isError) {
            setPublishedRegions(activeRegionIds)
            dispatch(
                addToast({
                    message: 'Kunne ikke legge produkt i region',
                    color: 'danger',
                })
            )
        }
    }, [publishToRegionResult])

    useEffect(() => {
        if (unpublishFromRegionResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produkt fjernet fra region',
                    color: 'success',
                })
            )
        }
        if (unpublishFromRegionResult.isError) {
            setPublishedRegions(activeRegionIds)
            dispatch(
                addToast({
                    message: 'Kunne ikke fjerne produkt fra region',
                    color: 'danger',
                })
            )
        }
    }, [unpublishFromRegionResult])

    const handleSwitchChange = (regionId: number, checked: boolean) => {
        if (checked) {
            publishToRegion({ regionId, productId })
        } else {
            unpublishFromRegion({ regionId, productId })
        }

        setPublishedRegions((prev) => (checked ? [...prev, regionId] : prev.filter((id) => id !== regionId)))
    }

    return (
        <>
            {regions.map((region) => {
                const isChecked = publishedRegions.includes(region.id)
                return (
                    <CFormSwitch
                        key={region.id}
                        style={
                            {
                                '--cui-form-check-input-checked-bg-color': 'var(--cui-success)',
                                '--cui-form-check-input-checked-border-color': 'var(--cui-success)',
                            } as React.CSSProperties
                        }
                        color={'success'}
                        size={'xl'}
                        className={'mb-3'}
                        label={region.name}
                        checked={isChecked}
                        onChange={(e) => handleSwitchChange(region.id, e.target.checked)}
                    />
                )
            })}
        </>
    )
}
