import { CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import React from 'react'
import { IndexedOrder } from '../types'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import OrderStatusBadge from './OrderStatusBadge'

interface OrderSearchResultTableProps {
    orders: IndexedOrder[]
}

function OrderSearchResultTable({ orders }: OrderSearchResultTableProps) {
    const history = useHistory()

    return (
        <CCol md={12}>
            <CTable hover>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">Ordrenummer</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Distribusjonsdato</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Kunde</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Adresse</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Region</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {orders &&
                        orders.map((o) => {
                            return (
                                <CTableRow
                                    key={o.id}
                                    onClick={() => history.push(`/ordre/${o.id}`)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <CTableHeaderCell scope="row">{o.id}</CTableHeaderCell>
                                    <CTableDataCell>{moment(o.deliveryDate).format('ll')}</CTableDataCell>
                                    <CTableDataCell>
                                        <OrderStatusBadge orderStatus={o.status} />
                                    </CTableDataCell>
                                    <CTableDataCell>{o.customer.email}</CTableDataCell>
                                    <CTableDataCell>
                                        {o.address.street} {o.address.zip} {o.address.city}
                                    </CTableDataCell>
                                    <CTableDataCell>{o.region}</CTableDataCell>
                                </CTableRow>
                            )
                        })}
                </CTableBody>
            </CTable>
        </CCol>
    )
}

export default OrderSearchResultTable
