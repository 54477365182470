import { Page } from '../../common'
import { CBadge, CButton, CCol, CRow, CSmartTable, CSpinner } from '@coreui/react-pro'
import { useExportSuppliersMutation, useGetSuppliers2Query, useSyncSuppliersMutation } from '../store/supplierApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { Supplier2 } from '../types'
import CIcon from '@coreui/icons-react'
import { cilTransfer, cilVerticalAlignTop } from '@coreui/icons'
import { useHistory } from 'react-router-dom'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import { useRegions } from '../../region/store/regionApi'

export default function SupplierListPage2() {
    const history = useHistory()
    const { data: suppliers, isSuccess, isLoading, isError, error } = useGetSuppliers2Query()
    const [syncSuppliers] = useSyncSuppliersMutation()
    const [exportSuppliers] = useExportSuppliersMutation()
    const dispatch = useDispatch()
    const regions = useRegions()

    const handleSupplierSync = () => {
        executeMutation(
            syncSuppliers(),
            dispatch,
            'Startet synkronisering av leverandører',
            'Kunne starter synkronisering',
            () => {}
        )
    }

    const handleSuppliersExport = () => {
        executeMutation(
            exportSuppliers(),
            dispatch,
            'Startet eksport av leverandører',
            'Kunne ikke starte eksport',
            () => {}
        )
    }

    return (
        <Page title={'Leverandører'}>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && suppliers && (
                <>
                    <CRow>
                        <CCol
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        ></CCol>
                        <CCol
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <CButton className={'mb-3 mr-1'} color={'primary'} onClick={handleSupplierSync}>
                                <CIcon className={'mr-1'} icon={cilTransfer} size="sm" />
                                Synkroniser
                            </CButton>
                            <CButton variant={'outline'} className={'mb-3 mr-1'} onClick={handleSuppliersExport}>
                                <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
                                Eksporter til WMS
                            </CButton>
                        </CCol>
                    </CRow>
                    <CSmartTable
                        columns={[
                            {
                                key: 'externalId',
                                label: 'Ekstern ID',
                                filter: true,
                                sorter: true,
                                _style: { width: '10%' },
                            },
                            {
                                key: 'name',
                                label: 'Navn',
                                filter: true,
                                sorter: true,
                                _style: { width: '30%' },
                            },
                            {
                                key: 'regions',
                                label: 'Regioner',
                                filter: false,
                                sorter: false,
                            },
                        ]}
                        tableProps={{
                            responsive: true,
                            striped: true,
                            hover: true,
                            borderless: true,
                        }}
                        clickableRows
                        onRowClick={(item) => history.push(`/leverandører/v2/${item.id}`)}
                        columnSorter
                        columnFilter
                        scopedColumns={{
                            regions: (item: Supplier2) => (
                                <td>
                                    {item.regions.map((r) => (
                                        <CBadge key={r.regionId} className={'ml-1'} color={'dark'}>
                                            {regions.find((region) => region.id == r.regionId)?.name}
                                        </CBadge>
                                    ))}
                                </td>
                            ),
                        }}
                        items={suppliers}
                        itemsPerPage={100000}
                    />
                </>
            )}
        </Page>
    )
}
