import { Page } from '../../common'
import {
    CNav,
    CNavItem,
    CNavLink,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import { useGetSuppliersQuery } from '../store/supplierApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

export default function SupplierListPage() {
    const { data: suppliers, isSuccess, isLoading, isError, error } = useGetSuppliersQuery()
    const history = useHistory()

    const [filterRegion, setFilterRegion] = useState<any>()

    const filteredSuppliers = suppliers
        ?.filter((s) => (filterRegion == undefined ? true : s.regionName == filterRegion))
        .sort((a, b) => a.supplierId - b.supplierId)

    return (
        <Page title={'Leverandører'}>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && (
                <>
                    <CNav className={'mb-3'} variant="pills">
                        <CNavItem>
                            <CNavLink
                                href="#"
                                active={filterRegion == undefined}
                                onClick={() => setFilterRegion(undefined)}
                            >
                                Alle regioner
                            </CNavLink>
                        </CNavItem>
                        {Array.from(new Set(suppliers?.map((s) => s.regionName))).map((region) => (
                            <CNavItem key={region}>
                                <CNavLink
                                    href="#"
                                    active={filterRegion == region}
                                    onClick={() => setFilterRegion(region)}
                                >
                                    {region}
                                </CNavLink>
                            </CNavItem>
                        ))}
                    </CNav>
                    <CTable hover>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>ID</CTableHeaderCell>
                                <CTableHeaderCell>Craft ID</CTableHeaderCell>
                                <CTableHeaderCell>Region</CTableHeaderCell>
                                <CTableHeaderCell>Navn</CTableHeaderCell>
                                <CTableHeaderCell>Pakksted</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {filteredSuppliers?.map((supplier) => {
                                return (
                                    <CTableRow
                                        onClick={() => history.push(`/leverandører/${supplier.supplierId}`)}
                                        style={{ cursor: 'pointer' }}
                                        color={supplier.packageLocation == undefined ? 'danger' : ''}
                                        key={supplier.supplierId}
                                    >
                                        <CTableDataCell>{supplier.supplierId}</CTableDataCell>
                                        <CTableDataCell>{supplier.externalSupplierId}</CTableDataCell>
                                        <CTableDataCell>{`#${supplier.regionId} ${supplier.regionName}`}</CTableDataCell>
                                        <CTableDataCell>{supplier.name}</CTableDataCell>
                                        <CTableDataCell>{supplier.packageLocation}</CTableDataCell>
                                    </CTableRow>
                                )
                            })}
                        </CTableBody>
                    </CTable>
                </>
            )}
        </Page>
    )
}
