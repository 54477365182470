import {
    CButton,
    CCallout,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CProgress,
    CProgressStacked,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { CreateInventoryManagementForm } from '../../inventory/components/CreateInventoryManagementForm'
import ProductStatusDropdown from './ProductStatusDropdown'
import PublishToRegionSelect from './PublishToRegionSelect'
import { ProductGroup, RegisteredProduct } from '../types'
import { inventoryApi } from '../../inventory/store/inventoryApi'
import { Input, Select } from '../../common/components/Input/Input'
import { useGenerateSkuQuery, useSetSkuMutation, useUpdateProductMutation } from '../store/productApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

interface ProductOnboardingWizardProps {
    product: RegisteredProduct
}

enum StepState {
    NOT_STARTED,
    ACTIVE,
    COMPLETED,
}

export default function ProductOnboardingWizard({ product }: ProductOnboardingWizardProps) {
    const [visible, setVisible] = React.useState(true)
    const [activeStep, setActiveStep] = React.useState(0)
    const [steps, setSteps] = React.useState([
        {
            title: '1. SKU',
            state: StepState.ACTIVE,
        },
        {
            title: '2. Lagertype',
            state: StepState.NOT_STARTED,
        },
        {
            title: '3. Produktgruppe',
            state: StepState.NOT_STARTED,
        },
        {
            title: '4. Aktiver',
            state: StepState.NOT_STARTED,
        },
        {
            title: '5. Publiser',
            state: StepState.NOT_STARTED,
        },
    ])
    const updateStepState = (index: number, newState: StepState) => {
        setSteps((prevSteps) => {
            const newSteps = [...prevSteps] // Create a copy of the original array
            newSteps[index] = { ...newSteps[index], state: newState } // Update the state of the specified index
            return newSteps // Return the updated array
        })
    }
    const dispatch = useDispatch()
    const { data: availability } = inventoryApi.useGetInventoryQuery(product.id)
    const [updateProduct] = useUpdateProductMutation()
    const [setSku] = useSetSkuMutation()
    const [sku, setSkuValue] = useState('')
    const { data: skuSuggestion } = useGenerateSkuQuery({ productId: product.id })

    useEffect(() => {
        if (skuSuggestion) {
            setSkuValue(skuSuggestion.sku)
        }
    }, [skuSuggestion])

    const handleSetProductGroup = (productGroup: ProductGroup) => {
        executeMutation(
            updateProduct({
                productId: product.id,
                data: { productGroup },
            }),
            dispatch,
            'Produktgruppe satt',
            'Kunne ikke oppdatere produktgruppe',
            () => {},
            completeProductGroupStep
        )
    }

    const handleSetSku = () => {
        executeMutation(
            setSku({
                productId: product.id,
                sku: sku,
            }),
            dispatch,
            'SKU satt',
            'Kunne ikke oppdatere SKU',
            () => {},
            completeSkuStep
        )
    }

    const completeSkuStep = () => {
        setActiveStep(1)
        updateStepState(0, StepState.COMPLETED)
        updateStepState(1, StepState.ACTIVE)
    }

    const completeInventoryStep = () => {
        setActiveStep(2)
        updateStepState(1, StepState.COMPLETED)
        updateStepState(2, StepState.ACTIVE)
    }

    const completeProductGroupStep = () => {
        setActiveStep(3)
        updateStepState(2, StepState.COMPLETED)
        updateStepState(3, StepState.ACTIVE)
    }

    const completeActivateStep = () => {
        setActiveStep(4)
        updateStepState(3, StepState.COMPLETED)
        updateStepState(4, StepState.ACTIVE)
    }

    const completeRegionStep = () => {
        updateStepState(4, StepState.COMPLETED)
        setActiveStep(-1)
    }

    useEffect(() => {
        // Skip first step if SKU is set
        if (product.sku) {
            completeSkuStep()
            // Skip 2nd step if availability is set
            if (availability && availability.type) {
                completeInventoryStep()
                if (product.productGroup) {
                    completeProductGroupStep()
                    if (product.status != 'CREATED') {
                        completeActivateStep()
                        if (product.activeInRegions.length > 0) {
                            completeRegionStep()
                        }
                    }
                }
            }
        }
    }, [availability])

    const styles = {
        fontSize: '1rem',
        color: 'black',
    }

    const activeStyles = {
        ...styles,
        color: 'white',
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <>
                        <CCallout style={{ color: 'var(--cui-gray)' }}>
                            <h5>Sett SKU</h5>
                            <p>
                                SKU er en unik identifikator for produktet. Denne må være i små bokstaver, ikke være
                                over 40 tegn, og bør følge standarden for SKU: <br />
                                <code style={{ fontSize: '0.75rem' }}>
                                    {'<'}leverandør 3 bokstaver{'>'}-{'<'}kategori 3 bokstaver{'>'}-{'<'}tittel 6
                                    bokstaver{'>'}-{'<'}størrelse/antall{'>'}
                                </code>
                            </p>
                        </CCallout>
                        <div className={'mb-3'}>
                            <Input
                                type="text"
                                label="SKU"
                                text="Dette er et forslag til SKU, endre eller bekreft. Denne kan ikke endres senere."
                                defaultValue={skuSuggestion?.sku}
                                valid={sku.length > 0 && sku.length < 40}
                                onChange={(e) => {
                                    setSkuValue(e.target.value)
                                }}
                            />
                        </div>
                        <CButton onClick={handleSetSku} disabled={sku.length == 0 || sku.length > 40}>
                            Bruk som SKU
                        </CButton>
                    </>
                )
            case 1:
                return (
                    <>
                        <CCallout style={{ color: 'var(--cui-gray)' }}>
                            <h5>Velg lagertype</h5>
                            <p>
                                Dette bestemmer om produktet skal styres av produksjonskapasitet eller tilgjengelighet
                                på lageret.
                            </p>
                        </CCallout>
                        <CreateInventoryManagementForm productId={product.id} onSuccess={completeInventoryStep} />
                    </>
                )
            case 2:
                return (
                    <>
                        <CCallout style={{ color: 'var(--cui-gray)' }}>
                            <h5>Velg produktgruppe</h5>
                            <p>
                                Velg hvilken produktgruppe produktet tilhører. Dette brukes for å gi beskjed til lageret
                                hvor produktet skal oppbevares.
                            </p>
                        </CCallout>
                        <div className={'d-flex justify-content-center'}>
                            <Select
                                id="productGroup"
                                label="Produktgruppe"
                                options={[
                                    { value: undefined, label: 'Ikke valgt' },
                                    { value: 'CHILLED', label: 'Kjøl' },
                                    { value: 'CHILL_BEV', label: 'Kjølt drikke' },
                                    { value: 'DRY', label: 'Tørr' },
                                    { value: 'FRESH', label: 'Fersk' },
                                    { value: 'FROZEN', label: 'Frys' },
                                ]}
                                text={'Brukes for å gi beskjed til lageret hvor produktet skal oppbevares.'}
                                value={product.productGroup}
                                onChange={(e) => handleSetProductGroup(e.target.value as ProductGroup)}
                            />
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <CCallout style={{ color: 'var(--cui-gray)' }}>
                            <h5>Aktiver</h5>
                            <p>
                                Et aktivt produkt er et produkt som har fylt inn alle nødvendige felter og er klart for
                                salg. Dersom produktet ikke lar seg aktivere, må du lukke denne modalen og fylle inn
                                manglende informasjon.
                            </p>
                        </CCallout>
                        <div className={'d-flex justify-content-center'}>
                            <ProductStatusDropdown
                                productId={product.id}
                                status={product.status}
                                onSuccess={completeActivateStep}
                            />
                        </div>
                    </>
                )
            case 4:
                return (
                    <>
                        <CCallout style={{ color: 'var(--cui-gray)' }}>
                            <h5>Publiser</h5>
                            <p>
                                Velg hvilke regioner produktet skal være tilgjengelig i, eller hopp over for å gjøre
                                dette senere.
                            </p>
                        </CCallout>
                        <PublishToRegionSelect productId={product.id} activeRegionIds={product.activeInRegions} />
                    </>
                )
            default:
                return <p>FEIL</p>
        }
    }

    const getColor = (state: StepState) => {
        switch (state) {
            case StepState.NOT_STARTED:
                return 'light'
            case StepState.ACTIVE:
                return 'primary'
            case StepState.COMPLETED:
                return 'success'
        }
    }

    if (product.status == 'ACTIVE') {
        return null
    }

    return (
        <CModal color={'warning'} visible={visible} size={'lg'}>
            <CModalHeader>
                <CModalTitle>Fullfør oppsett av {product.title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className={'w-100 m-auto'}>
                    <CProgressStacked style={{ height: '30px' }} className={'mb-3'}>
                        {steps.map((step, index) => {
                            return (
                                <CProgress
                                    key={step.title}
                                    height={30}
                                    value={100 / steps.length}
                                    color={getColor(step.state)}
                                    style={activeStep == index ? activeStyles : styles}
                                    onClick={() => setActiveStep(index)}
                                >
                                    {step.title}
                                </CProgress>
                            )
                        })}
                    </CProgressStacked>
                </div>
                <div>{getStepContent(activeStep)}</div>
            </CModalBody>
            {activeStep == 2 && (
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Hopp over
                    </CButton>
                    <CButton color="primary" onClick={() => setVisible(false)}>
                        Ferdig
                    </CButton>
                </CModalFooter>
            )}
        </CModal>
    )
}
