import { RegisteredProduct } from '../types'
import Card from '../../common/components/Card'
import { CCardBody, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react-pro'
import CardHeader from '../../common/components/CardHeader'
import CIcon from '@coreui/icons-react'
import { cilBasket, cilImage } from '@coreui/icons'
import React from 'react'

interface ProductInBundleCardProps {
    product: RegisteredProduct
    products: RegisteredProduct[]
}

export default function ProductInBundleCard({ product, products }: ProductInBundleCardProps) {
    const handleRowClick = (product: RegisteredProduct) => {
        window.location.href = `/produkter/${product.id}`
    }

    const columns = [
        { key: 'listImageUrl', label: '' },
        { key: 'externalProductId', label: 'Ekstern produktid' },
        { key: 'title', label: 'Navn' },
        { key: 'status', label: 'Aktiv' },
    ]

    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilBasket} size={'xl'} className={'mr-1'} />
                Produkt bundle
            </CardHeader>
            <CCardBody>
                <p style={{ color: 'var(--cui-gray)' }}>Dette produktet inngår i én eller flere bundles:</p>
                <CTable columns={columns} borderless small hover align={'middle'}>
                    <CTableBody>
                        {product.containedInBundles.map((productId) => {
                            const product = products.find((p) => p.id === productId)
                            if (!product) {
                                return null
                            }
                            return (
                                <CTableRow
                                    key={productId}
                                    onClick={() => handleRowClick(product)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <CTableDataCell>
                                        {!product.listImageUrl && (
                                            <CIcon size={'xxl'} icon={cilImage} className="text-danger m-2" />
                                        )}
                                        {product.listImageUrl && (
                                            <img
                                                src={product.listImageUrl}
                                                width={56}
                                                height={56}
                                                style={{ borderRadius: '50%' }}
                                                alt="Et bilde av produktet"
                                            />
                                        )}
                                    </CTableDataCell>
                                    <CTableDataCell>{product?.externalProductId}</CTableDataCell>
                                    <CTableDataCell>{product?.title}</CTableDataCell>
                                    <CTableDataCell>{product?.status === 'ACTIVE' ? 'Ja' : 'Nei'}</CTableDataCell>
                                </CTableRow>
                            )
                        })}
                    </CTableBody>
                </CTable>
            </CCardBody>
        </Card>
    )
}
