import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { AdminUser } from '../types'

interface CreateAdminUser {
    email: string
    roles: string[]
}

interface UpdateAdminUser {
    id: number
    roles: string[]
}

export const adminUserApi = createApi({
    reducerPath: 'adminUser',
    tagTypes: ['AdminUser'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getAdminUsers: builder.query<AdminUser[], void>({
            query: () => ({
                url: '/admin/users',
                method: 'GET',
            }),
            providesTags: ['AdminUser'],
        }),
        createAdminUser: builder.mutation<void, CreateAdminUser>({
            query: (data) => ({
                url: '/admin/users',
                method: 'POST',
                body: {
                    email: data.email,
                    roles: data.roles,
                },
            }),
            invalidatesTags: ['AdminUser'],
        }),
        updateAdminUser: builder.mutation<void, UpdateAdminUser>({
            query: (data) => ({
                url: `/admin/users/${data.id}`,
                method: 'PUT',
                body: {
                    roles: data.roles,
                },
            }),
            invalidatesTags: ['AdminUser'],
        }),
        deleteAdminUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `/admin/users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdminUser'],
        }),
    }),
})

export const useAdminUsers = () => adminUserApi.useGetAdminUsersQuery().data ?? []
export const { useCreateAdminUserMutation, useDeleteAdminUserMutation, useUpdateAdminUserMutation } = adminUserApi
