import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'

export interface ListCapacitiesResponse {
    id: string
    packageLocation: string
    defaultCapacities: DefaultCapacity[]
    orderedAmounts: OrderedAmounts[]
}

export interface DefaultCapacity {
    dayOfWeek: number
    capacity: number
}

export interface OrderedAmounts {
    date: string // Dates are represented as strings in JSON
    dayOfWeek: number
    amount: number
}

export interface EditCapacitiesRequest {
    id: string
    capacities: Record<number, number>
}

export const capacityApi = createApi({
    reducerPath: 'capacity',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['capacity'],
    endpoints: (builder) => ({
        list: builder.query<ListCapacitiesResponse[], void>({
            query: () => ({
                url: `/admin/package-location/capacity`,
                method: 'GET',
            }),
            providesTags: ['capacity'],
        }),
        editCapacities: builder.mutation<void, EditCapacitiesRequest>({
            query: (request) => ({
                url: `/admin/package-location/${request.id}/capacity`,
                method: 'PUT',
                body: { capacities: request.capacities },
            }),
            invalidatesTags: ['capacity'],
        }),
    }),
})
