import { CLoadingButton } from '@coreui/react-pro'
import { useExportUnshippedOrdersToWmsMutation } from '../store/orderAdminApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

export const ExportOrdersToWMSButton = ({ ...rest }) => {
    const [exportOrders, { isLoading: isExporting }] = useExportUnshippedOrdersToWmsMutation()
    const dispatch = useDispatch()
    const exportOrdersToWMS = () => {
        if (
            !confirm(
                'Er du sikker på at du vil eksportere alle ordre som ikke er booket til WMS?\n\n' +
                    'Dette gjelder kun ordre i regioner der WMS er aktivert.'
            )
        ) {
            return
        }

        executeMutation(exportOrders(), dispatch, 'Orders exported to WMS', 'Could not export orders to WMS', () => {})
    }

    return (
        <CLoadingButton loading={isExporting} disabled={isExporting} onClick={exportOrdersToWMS} {...rest}>
            Eksporter ordre til WMS
        </CLoadingButton>
    )
}
