import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getUnauthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Region } from '../types'

export const regionApi = createApi({
    reducerPath: 'region',
    baseQuery: getUnauthorizedBaseQuery(),
    endpoints: (builder) => ({
        getRegions: builder.query<Region[], void>({
            query: () => ({
                url: '/region',
                method: 'GET',
            }),
        }),
    }),
})

export const useRegions = () => regionApi.useGetRegionsQuery().data || []
