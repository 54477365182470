import { ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'
import React, { FC } from 'react'

interface LineChartProps {
    values: {
        [key: string]: {
            color: string
        }
    }
    data: any[]
}

export const LineChart: FC<LineChartProps> = ({ data, values }) => {
    console.log(data)
    console.log({ values })
    return (
        <ComposedChart width={500} height={300} data={data}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="bottom" height={36} />
            <defs>
                {Object.keys(values).map((key) => (
                    <linearGradient key={key} id={`color${key}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={values[key].color} stopOpacity={0.5} />
                        <stop offset="95%" stopColor={values[key].color} stopOpacity={0} />
                    </linearGradient>
                ))}
            </defs>
            {Object.keys(values).map((key) => (
                <Line
                    key={key}
                    type="monotone"
                    dataKey={key}
                    stroke={values[key].color}
                    fill={`url(#color${key})`}
                    connectNulls
                />
            ))}
        </ComposedChart>
    )
}
