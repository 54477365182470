import { CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow, CSpinner } from '@coreui/react-pro'
import { useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { Page } from '../../common'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import PackageLocationSelect from '../components/PackageLocationSelect'
import { useGetSupplierQuery, useUpdateSupplierMutation } from '../store/supplierApi'
import { EditSupplier } from '../types'

interface SupplierDetailsPageRouteParams {
    supplierId: string
}

export function SupplierDetailsPage() {
    const { supplierId } = useParams<SupplierDetailsPageRouteParams>()
    const history = useHistory()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'suppliers.write')
    const { data: supplier, isLoading, isSuccess, isError, error } = useGetSupplierQuery(supplierId)
    const [updateSupplier] = useUpdateSupplierMutation()

    const [changes, updateChanges] = useState<EditSupplier>({
        supplierId: supplierId,
        packageLocation: undefined,
    })

    return (
        <>
            {isError && <ErrorMessage error={error} />}
            {isLoading && <CSpinner />}
            {isSuccess && (
                <Page title={`#${supplier.supplierId} ${supplier.name}`} preamble={`${supplier.regionName}`}>
                    <CForm>
                        <CContainer>
                            <CRow xs={{ cols: 1, gutterY: 4 }}>
                                <CCol>
                                    <CFormInput value={supplier.name} label={'Navn'} disabled />
                                </CCol>
                                <CCol>
                                    <CFormInput value={supplier.externalSupplierId} label={'Craft ID'} disabled />
                                </CCol>
                                <CCol>
                                    <CFormInput value={supplier.regionId} label={'Region Id'} disabled />
                                </CCol>
                                <CCol>
                                    <CFormInput value={supplier.regionName} label={'Region'} disabled />
                                </CCol>
                                <CCol>
                                    <CFormLabel>Pakksted</CFormLabel>
                                    <PackageLocationSelect
                                        currentPackageLocation={supplier.packageLocation}
                                        onChange={(pl) => updateChanges({ ...changes, packageLocation: pl })}
                                    />
                                </CCol>
                                <CCol md={'auto'} className={'me-auto'}>
                                    <CButton color={'secondary'} onClick={() => history.goBack()}>
                                        Tilbake
                                    </CButton>
                                </CCol>
                                <CCol md={'auto'}>
                                    <CButton
                                        onClick={() =>
                                            updateSupplier({
                                                supplierId,
                                                packageLocation: changes.packageLocation,
                                            })
                                        }
                                        disabled={!canChange}
                                    >
                                        Lagre
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CForm>
                </Page>
            )}
        </>
    )
}
