import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Subscription } from '../types'

interface PauseSubscription {
    subscriptionId: number
    startDate: string
    endDate: string
}

export const subscriptionApi = createApi({
    reducerPath: 'subscription',
    tagTypes: ['Subscription'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getSubscriptions: builder.query<Subscription[], string>({
            query: (customerId) => ({
                url: `/admin/subscription?customerId=${customerId}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result ? result.map(({ subscriptionId }) => ({ type: 'Subscription', id: subscriptionId })) : [],
        }),
        pauseSubscription: builder.mutation<void, PauseSubscription>({
            query: (pauseSubscription) => ({
                url: `/admin/subscription/${pauseSubscription.subscriptionId}/pause`,
                method: 'PUT',
                body: {
                    startDate: pauseSubscription.startDate,
                    endDate: pauseSubscription.endDate,
                },
            }),
            invalidatesTags: (_, __, pauseSubscription) => [
                { type: 'Subscription', id: pauseSubscription.subscriptionId },
            ],
        }),
        unpauseSubscription: builder.mutation<void, number>({
            query: (subscriptionId) => ({
                url: `/admin/subscription/${subscriptionId}/unpause`,
                method: 'PUT',
            }),
            invalidatesTags: (_, __, subscriptionId) => [{ type: 'Subscription', id: subscriptionId }],
        }),
        stopSubscription: builder.mutation<void, number>({
            query: (subscriptionId) => ({
                url: `/admin/subscription/${subscriptionId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, __, subscriptionId) => [{ type: 'Subscription', id: subscriptionId }],
        }),
    }),
})

export const useSubscriptions = (customerId: string) => subscriptionApi.useGetSubscriptionsQuery(customerId).data || []
export const { usePauseSubscriptionMutation, useUnpauseSubscriptionMutation, useStopSubscriptionMutation } =
    subscriptionApi
