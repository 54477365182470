import { RegisteredProduct } from '../../product/types'
import {
    CButton,
    CFormCheck,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CMultiSelect,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilImage } from '@coreui/icons'
import { inventoryApi } from '../store/inventoryApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

interface ProductModalProps {
    products: RegisteredProduct[]
    visible: boolean
    onClose?: () => void
    onSetInventoryType?: () => void
}

interface Changes {
    selectedProducts: string[]
    type?: 'STOCK' | 'PRODUCE'
}

export default function InventoryProductModal({ products, onSetInventoryType, visible, onClose }: ProductModalProps) {
    const dispatch = useDispatch()
    const [changes, setChanges] = useState<Changes>({
        selectedProducts: [],
        type: undefined,
    })
    const [setInventoryType] = inventoryApi.useAddInventoryManagementMutation()
    const options = products.map((product) => {
        return {
            value: product.id,
            text: product.title + ' ' + product.id,
            displayText: product.title,
            listImageUrl: product.listImageUrl,
        }
    })
    const handleSubmit = (productId: string, type: 'STOCK' | 'PRODUCE') => {
        executeMutation(
            setInventoryType({ productId, type }),
            dispatch,
            'Lagertype satt',
            'Kunne ikke sette lagertype',
            () => {
                onSetInventoryType && onSetInventoryType()
            }
        )
    }

    return (
        <CModal visible={visible} onClose={onClose} title="Velg type">
            <CModalHeader closeButton={true}>
                <CModalTitle id="LiveDemoExampleLabel">Sett lagertype</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CMultiSelect
                    className={'mb-3'}
                    label="Velg produkt"
                    selectAll={false}
                    options={options}
                    optionsStyle="text"
                    onChange={(e) => {
                        setChanges({ ...changes, selectedProducts: e.map((option) => option.value.toString()) })
                    }}
                    optionsTemplate={(option) => (
                        <td>
                            {!option.listImageUrl && <CIcon size={'sm'} icon={cilImage} className="text-danger" />}
                            {option.listImageUrl && (
                                <img
                                    src={option.listImageUrl}
                                    width={24}
                                    height={24}
                                    style={{ borderRadius: '50%' }}
                                    alt={option.displayText}
                                />
                            )}
                            <span className={'ml-1'}>{option.displayText}</span>
                        </td>
                    )}
                />
                <div className={'d-grid gap-2'}>
                    <CFormCheck
                        checked={changes.type === 'PRODUCE'}
                        onChange={() => setChanges({ ...changes, type: 'PRODUCE' })}
                        type="radio"
                        label="Kapasitetstyring"
                    />
                    <CFormCheck
                        checked={changes.type === 'STOCK'}
                        onChange={() => setChanges({ ...changes, type: 'STOCK' })}
                        type="radio"
                        label="Lagerstyring"
                    />
                </div>
            </CModalBody>
            <CModalFooter>
                <CButton
                    color="primary"
                    onClick={() => {
                        if (changes.selectedProducts.length === 0 && changes.type) {
                            return
                        }

                        if (!changes.type) {
                            return
                        }

                        changes.selectedProducts.forEach((productId) => {
                            handleSubmit(productId, changes.type as 'STOCK' | 'PRODUCE')
                        })
                    }}
                >
                    Lagre
                </CButton>
            </CModalFooter>
        </CModal>
    )
}
