import { useGetSupplier2Query } from '../store/supplierApi'
import { useRegions } from '../../region/store/regionApi'
import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'
import { CAlert, CBadge, CButton, CCardBody, CSpinner } from '@coreui/react-pro'
import React from 'react'
import { craftUrl } from '../../config'
import { cilFactory } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Input } from '../../common/components/Input/Input'

export default function SupplierCard({ supplierId }: { supplierId: string }) {
    const { data: supplier, isSuccess, isLoading, isError } = useGetSupplier2Query(supplierId)
    const regions = useRegions()
    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilFactory} size={'xl'} className={'mr-1'} />
                Leverandørinfo
            </CardHeader>
            <CCardBody>
                {isLoading && <CSpinner />}
                {isError && <CAlert color={'danger'}>Kunne ikke hente leverandør</CAlert>}
                {isSuccess && regions && (
                    <>
                        <div className={'mb-3'}>
                            <Input disabled type="text" label="Leverandør" value={supplier.name} />
                        </div>
                        <div className="mb-3">
                            <Input
                                label="Ekstern leverandør ID"
                                value={supplier.externalId}
                                text={'ID som brukes for å identifisere leverandøren i Craft'}
                                disabled
                            />
                        </div>
                        <div>
                            <h5>Pakksteder for {supplier.name}</h5>
                            {supplier.regions.length === 0 && (
                                <CAlert color={'danger'}>Leverandøren mangler pakksteder</CAlert>
                            )}
                            <dl>
                                {supplier.regions.map((supplierRegion) => (
                                    <>
                                        <dt>{regions.find((r) => r.id == supplierRegion.regionId)?.name}</dt>
                                        <dd>
                                            {supplierRegion.packageLocation || (
                                                <CBadge color={'danger'}>Pakksted mangler</CBadge>
                                            )}
                                        </dd>
                                    </>
                                ))}
                            </dl>
                        </div>
                        <CButton
                            as={'a'}
                            href={`${craftUrl}/admin/entries/supplier/${supplier.externalId}`}
                            target={'_blank'}
                            type="button"
                            color="secondary"
                            variant="outline"
                            id="button-addon1"
                        >
                            Åpne leverandør i Craft
                        </CButton>
                    </>
                )}
            </CCardBody>
        </Card>
    )
}
