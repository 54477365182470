import axios, { AxiosInstance } from 'axios'

function authenticatedClient(): AxiosInstance {
    const client = axios.create()
    client.interceptors.request.use((config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    })
    return client
}

export default authenticatedClient()
