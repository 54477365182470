import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../common/utils/apiUtils'

export const projectionsApi = createApi({
    reducerPath: 'projections',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getProjections: builder.query<string[], void>({
            query: () => ({
                url: '/admin/projection',
                method: 'GET',
            }),
        }),
        rebuildProjection: builder.mutation<void, string>({
            query: (projectionName) => ({
                url: `/admin/projection/${projectionName}/rebuild`,
                method: 'POST',
            }),
        }),
    }),
})
