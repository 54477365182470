import ProgressBar, { ProgressBarType } from '../../common/components/ProgressBar/ProgressBar'

export function AvailabilityProgressBar({
    availableQuantity,
    reservedQuantity,
}: {
    reservedQuantity: number
    availableQuantity: number
}) {
    const getType = (quantityPossible: number, quantityOrdered: number) => {
        if (quantityPossible === 0) {
            return ProgressBarType.Warning
        }
        if (quantityOrdered === 0) {
            return ProgressBarType.Default
        }
        if (quantityOrdered < quantityPossible) {
            return ProgressBarType.Default
        }
        if (quantityOrdered >= quantityPossible) {
            return ProgressBarType.Full
        }
        return ProgressBarType.Warning
    }
    return (
        <ProgressBar
            progress={(reservedQuantity / availableQuantity) * 100}
            type={getType(availableQuantity, reservedQuantity)}
            text={`${reservedQuantity ?? 0} / ${availableQuantity ?? 0}`}
        />
    )
}
