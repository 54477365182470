import { Route, Switch, useRouteMatch } from 'react-router'
import React from 'react'
import ProductRegistryListPage from './ProductRegistryListPage'
import ProductDetailsPage from './ProductDetailsPage'

export function ProductRegistryPage() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${path}/:productId`}>
                <ProductDetailsPage />
            </Route>
            <Route exact path={`${path}`}>
                <ProductRegistryListPage />
            </Route>
        </Switch>
    )
}
