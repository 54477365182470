import classNames from 'classnames'
import ProgressBar, { ProgressBarType } from '../../../common/components/ProgressBar/ProgressBar'
import css from './Capacity.module.scss'

interface CapacityProps {
    label: string
    total: number
    used: number
    className?: string
}

function Capacity({ label, total, used, className }: CapacityProps) {
    let progress
    if (total === 0) {
        progress = -1
    } else if (used > total || total == Infinity) {
        progress = 100
    } else {
        progress = (used / total) * 100
    }
    const getProgressBarType = (progress: number) => {
        if (total == Infinity) return ProgressBarType.Default
        if (progress < 0) {
            return ProgressBarType.Disabled
        } else if (progress > 99) {
            return ProgressBarType.Full
        } else if (progress >= 90) {
            return ProgressBarType.Warning
        }
        return ProgressBarType.Default
    }
    const getProgressBarText = (progress: number) => {
        if (total == Infinity) return `${used}`
        return progress < 0 ? 'Stengt' : `${used} / ${total}`
    }

    const classes = classNames(css.capacity, { [`${className}`]: !!className })

    return (
        <div className={classes}>
            <ProgressBar progress={progress} type={getProgressBarType(progress)} text={getProgressBarText(progress)} />
            <span>{label}</span>
        </div>
    )
}

export default Capacity
