import { CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { useTestAllNotificationsMutation } from '../store/notificationAdminApi'

export default function TestAllNotificationsForm() {
    const email = useAuthenticatedUser()?.email
    const [testNotifications] = useTestAllNotificationsMutation()
    return (
        <CForm
            onSubmit={(e) => {
                e.preventDefault()
                const data = new FormData(e.currentTarget)
                testNotifications({
                    recipient: data.get('recipient') as string,
                    orderId: data.get('orderId') as string,
                })
            }}
        >
            <CRow className="mb-3">
                <CFormLabel className="col-sm-2">E-postmottaker</CFormLabel>
                <CCol sm={5}>
                    <CFormInput
                        name={'recipient'}
                        type={'text'}
                        defaultValue={email}
                        autoComplete={'E-post du vil sende til'}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CFormLabel className="col-sm-2">OrdreID for datagrunnlag</CFormLabel>
                <CCol sm={5}>
                    <CFormInput name={'orderId'} type={'text'} autoComplete={'Ordrenummer'} />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CButton type={'submit'}>Test alle e-poster</CButton>
                </CCol>
            </CRow>
        </CForm>
    )
}
