import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro'
import { Customer } from '../../types'
import { DeleteCustomerModalContent } from './DeleteCustomerModalContent'

interface DeleteCustomerModalProps {
    visible: boolean
    closeModal: () => void
    customer: Customer
}

export function DeleteCustomerModal({ visible, closeModal, customer }: DeleteCustomerModalProps) {
    return (
        <CModal visible={visible} onClose={closeModal}>
            <CModalBody>
                <CModalHeader>
                    <CModalTitle>Sletting av kunde</CModalTitle>
                </CModalHeader>
                <DeleteCustomerModalContent customer={customer} closeModal={closeModal} />
            </CModalBody>
        </CModal>
    )
}
