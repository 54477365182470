import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CButton,
    CCol,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../../auth/authUtils'
import { useAuthenticatedUser } from '../../../auth/authenticationApi'
import { Page } from '../../../common'
import { executeMutation } from '../../../common/utils/apiUtils'
import {
    DistributionCompanyData,
    DistributionCompanyForm,
} from '../../components/DistributionCompanyForm/DistributionCompanyForm'
import { DistributionCompanySettings } from '../../components/DistributionCompanySettings/DistributionCompanySettings'
import { StandardCapacityForm } from '../../components/StandardCapacityForm/StandardCapacityForm'
import { useCreateDistributionCompanyMutation, useDistributionCompanies } from '../../store/distributionApi'
import style from './DistributionCompaniesPage.module.scss'

export function DistributionCompaniesPage() {
    const dispatch = useDispatch()
    const hasElevatedPermissions = userHasSomePermissions(useAuthenticatedUser(), 'distribution.elevatedwrite')
    const distributionCompanies = useDistributionCompanies()
    const [createDistributionCompany] = useCreateDistributionCompanyMutation()

    const [showNewModal, setShowNewModal] = useState(false)
    const [newCompany, setNewCompany] = useState<DistributionCompanyData>({
        name: '',
        externalId: 1,
        regionId: 7,
        orderDeadline: '',
    })
    const [canCreate, setCanCreate] = useState(false)

    useEffect(() => {
        const allFieldsHaveValue =
            !!newCompany.name && newCompany.externalId > 0 && newCompany.regionId > 0 && !!newCompany.orderDeadline

        setCanCreate(allFieldsHaveValue)
    }, [newCompany])

    const createCompany = () => {
        executeMutation(
            createDistributionCompany(newCompany),
            dispatch,
            `${newCompany.name} ble opprettet`,
            'Kunne ikke opprette distribusjonsselskap',
            () => {
                setShowNewModal(false)
                setNewCompany({ name: '', externalId: 1, regionId: 7, orderDeadline: '' })
            }
        )
    }

    return (
        <>
            <Page title="Distribusjonsselskaper">
                <CAccordion className="mb-4">
                    {[...distributionCompanies]
                        .sort((a, b) => a.id - b.id)
                        .map((company) => (
                            <CAccordionItem key={company.id} itemKey={company.id}>
                                <CAccordionHeader>{company.name}</CAccordionHeader>
                                <CAccordionBody>
                                    <CRow xs={{ gutter: 5 }}>
                                        <CCol>
                                            <DistributionCompanySettings company={company} />
                                        </CCol>
                                        <CCol>
                                            <StandardCapacityForm companyId={company.id} />
                                        </CCol>
                                    </CRow>
                                </CAccordionBody>
                            </CAccordionItem>
                        ))}
                </CAccordion>
                <div className={style.newButtonWrapper}>
                    <CButton color="primary" onClick={() => setShowNewModal(true)} disabled={!hasElevatedPermissions}>
                        Nytt distribusjonsselskap
                    </CButton>
                </div>
            </Page>
            <CModal visible={showNewModal} alignment="center" onClose={() => setShowNewModal(false)}>
                <CModalHeader>
                    <CModalTitle>Opprett nytt distribusjonsselskap</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <DistributionCompanyForm
                        company={newCompany}
                        onChange={setNewCompany}
                        onlyAllowDeadlineChange={false}
                    />
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setShowNewModal(false)}>
                        Avbryt
                    </CButton>
                    <CButton color="primary" disabled={!canCreate} onClick={createCompany}>
                        Opprett selskap
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}
