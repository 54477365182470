import { CButton, CCard } from '@coreui/react-pro'
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'

interface CardProps {
    onAction?: () => void
    actionIcon?: string | string[]
    style?: React.CSSProperties
}

export default function Card({ onAction, actionIcon, children, style }: React.PropsWithChildren<CardProps>) {
    return (
        <CCard
            className={'mb-3'}
            style={{
                '--cui-card-border-width': '1px',
                '--cui-card-border-color': 'transparent',
                '--cui-card-cap-bg': 'transparent',
                boxShadow: 'none',
                ...style,
            }}
        >
            {onAction && (
                <CButton
                    style={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        borderRadius: '50%',
                    }}
                    color={'transparent'}
                    onClick={onAction}
                >
                    <CIcon icon={actionIcon || cilPencil} />
                </CButton>
            )}

            {children}
        </CCard>
    )
}
