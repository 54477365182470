import {
    CButton,
    CFormInput,
    CFormSelect,
    CInputGroup,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CSpinner,
} from '@coreui/react-pro'
import { useState } from 'react'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { useCreatePackageLocationMutation } from '../store/supplierApi'
import { packageLocationApi } from '../../packagelocation/store/packageLocationApi'

interface PackageLocationSelect {
    currentPackageLocation: string | undefined
    onChange: (packageLocation: string | undefined) => any
}

export default function PackageLocationSelect({
    currentPackageLocation,
    onChange: handleChange,
}: PackageLocationSelect) {
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'suppliers.write')
    const canCreate = userHasSomePermissions(useAuthenticatedUser(), 'distribution.elevatedwrite')
    const {
        data: packageLocations,
        isLoading,
        isSuccess,
        isError,
        error,
    } = packageLocationApi.useGetPackageLocationsQuery()
    const [createPackageLocation] = useCreatePackageLocationMutation()
    const [visible, setVisible] = useState(false)
    const [newPackageLocation, setNewPackageLocation] = useState<string>()
    return (
        <>
            {isError && <ErrorMessage error={error} />}
            {isLoading && <CSpinner />}
            {isSuccess && (
                <>
                    <CInputGroup>
                        <CFormSelect
                            invalid={currentPackageLocation == undefined}
                            onChange={(e) => handleChange(e.target.value)}
                            disabled={!canChange}
                            defaultValue={currentPackageLocation}
                        >
                            <option value={undefined}></option>
                            {packageLocations.map((pl) => (
                                <option key={pl.name} value={pl.name}>
                                    {pl.name}
                                </option>
                            ))}
                        </CFormSelect>
                        <CButton onClick={() => setVisible(!visible)} disabled={!canCreate}>
                            Nytt pakksted
                        </CButton>
                    </CInputGroup>
                    <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                        <CModalHeader>
                            <CModalTitle>Opprett pakksted</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <p>
                                Et pakksted er et sted å hente ut etiketter fra. Pakkstedsnavnet MÅ eksistere i DI Flow
                                for å fungere.
                                <br />
                                Navngivningen bør følge navngivingstandarden "navn_region"
                            </p>
                            <CFormInput
                                label={'Pakksteds navn'}
                                onChange={(e) => setNewPackageLocation(e.target.value)}
                            />
                        </CModalBody>
                        <CModalFooter>
                            <CButton color="secondary" onClick={() => setVisible(false)}>
                                Lukk
                            </CButton>
                            <CButton
                                color="primary"
                                onClick={() => {
                                    if (newPackageLocation) {
                                        createPackageLocation({ packageLocation: newPackageLocation })
                                        setVisible(false)
                                    }
                                }}
                            >
                                Lagre
                            </CButton>
                        </CModalFooter>
                    </CModal>
                </>
            )}
        </>
    )
}
