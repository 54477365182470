import { Route, Switch, useRouteMatch } from 'react-router'
import React from 'react'
import { SupplierDetailsPage } from './SupplierDetailsPage'
import SupplierListPage from './SupplierListPage'
import SupplierListPage2 from './SupplierListPage2'
import SupplierDetailsPage2 from './SupplierDetailsPage2'

export default function SupplierPage() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${path}/v2`}>
                <SupplierListPage2 />
            </Route>
            <Route exact path={`${path}/v2/:supplierId`}>
                <SupplierDetailsPage2 />
            </Route>
            <Route exact path={`${path}/:supplierId`}>
                <SupplierDetailsPage />
            </Route>
            <Route exact path={`${path}`}>
                <SupplierListPage />
            </Route>
        </Switch>
    )
}
