import { Page } from '../../common'
import { featureApi } from '../store/featureApi'
import { FeaturesTable } from '../components/FeatureTable'

export function FeaturesPage() {
    const { data: features } = featureApi.useGetFeaturesQuery()

    if (!features) {
        return null
    }

    return (
        <Page title="Features" preamble="Skru av eller på features.">
            <FeaturesTable features={features} />
        </Page>
    )
}
