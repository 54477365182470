import { CAlert, CButton } from '@coreui/react-pro'
import { useState } from 'react'
import { userHasSomePermissions } from '../../../auth/authUtils'
import { useAuthenticatedUser } from '../../../auth/authenticationApi'
import { Customer } from '../../types'
import { DeleteCustomerModal } from './DeleteCustomerModal'

interface DataDeletionProps {
    customer: Customer
}

export default function DataDeletion({ customer }: DataDeletionProps) {
    const canDelete = userHasSomePermissions(useAuthenticatedUser(), 'customers.write')
    const [showModal, setShowModal] = useState<boolean>(false)
    const isAnonymousCustomer = !customer.userId

    return (
        <>
            {isAnonymousCustomer ? (
                <CButton onClick={() => setShowModal(true)} disabled={!canDelete}>
                    Slett kundedata
                </CButton>
            ) : (
                <CAlert color="warning">
                    Datasletting er kun tilgjengelig utloggede brukere. Sletting av data for innloggede brukere kan
                    gjøres gjennom Schibsted kontoen.
                </CAlert>
            )}
            <DeleteCustomerModal visible={showModal} closeModal={() => setShowModal(false)} customer={customer} />
        </>
    )
}
