import { LineChart } from '../../common/components/Chart/LineChart'
import { FC, useState } from 'react'
import { CMultiSelect } from '@coreui/react-pro'

interface SalesReportChartProps {
    graphData: any[]
}

export const SalesReportChart: FC<SalesReportChartProps> = ({ graphData }) => {
    const [selectedValue, setSelectedValue] = useState<string[]>([])

    const productsInData = graphData.flatMap((item) => Object.keys(item)).filter((item) => item !== 'date')
    const uniqueProducts = Array.from(new Set(productsInData))
    const multiSelectOptions = uniqueProducts.map((item) => ({
        text: item,
        value: item,
    }))

    console.log({ uniqueProducts, selectedValue })
    console.log({ productsInData })
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'space-between',
            }}
        >
            <CMultiSelect
                options={multiSelectOptions}
                onChange={(selected) => setSelectedValue(selected.map((item) => `${item.value}`))}
            />
            <div className={'mt-5'}>
                <LineChart
                    values={graphData.reduce((acc, item) => {
                        Object.keys(item).forEach((key) => {
                            if (selectedValue.includes(key)) {
                                if (!acc[key]) {
                                    acc[key] = {
                                        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                                    }
                                }
                            }
                        })
                        return acc
                    }, {})}
                    data={graphData}
                />
            </div>
        </div>
    )
}
