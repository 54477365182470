import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Status } from '../../common/types'
import { apiUrl } from '../../config'
import authenticatedClient from '../../services/axios'
import { HistoricOrder } from '../types'

export interface OrderHistoryState {
    orderHistory: HistoricOrder[]
    status: Status
    error: string | null | undefined
}

const initialState: OrderHistoryState = {
    orderHistory: [],
    status: Status.Idle,
    error: null,
}

export const fetchOrderHistory = createAsyncThunk('orderHistory/fetchOrderHistory', async (customerId: string) => {
    const response = await authenticatedClient.get(`${apiUrl}/admin/order/customer/${customerId}/history`)
    return response.data
})

const orderHistorySlice = createSlice({
    name: 'orderHistory',
    initialState,
    reducers: {
        resetOrderHistory: (state) => {
            state.status = Status.Idle
            state.error = null
            state.orderHistory = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrderHistory.pending, (state) => {
            state.status = Status.Loading
        })
        builder.addCase(fetchOrderHistory.fulfilled, (state, action) => {
            state.status = Status.Succeeded
            state.orderHistory = action.payload
        })
        builder.addCase(fetchOrderHistory.rejected, (state, action) => {
            state.status = Status.Failed
            state.error = action.error.message
        })
    },
})

export const { resetOrderHistory } = orderHistorySlice.actions

export default orderHistorySlice.reducer
