import { useActivateProductMutation, useDeactivateProductMutation, useDeleteProductMutation } from '../store/productApi'
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputGroup,
    CInputGroupText,
} from '@coreui/react-pro'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { productStatusColor, productStatusText } from '../util'
import { addToast } from '../../common/store/commonSlice'

interface ProductStatusDropdownProps {
    productId: string
    status: string
    onSuccess?: () => void
}

export default function ProductStatusDropdown({
    productId,
    status: currentStatus,
    onSuccess,
}: ProductStatusDropdownProps) {
    const [status, setStatus] = useState(currentStatus)

    const dispatch = useDispatch()
    const [activateProduct, activateResult] = useActivateProductMutation()
    const [deactivateProduct] = useDeactivateProductMutation()
    const [deleteProduct] = useDeleteProductMutation()

    useEffect(() => {
        if (currentStatus !== status) {
            setStatus(currentStatus)
        }
    }, [currentStatus])

    useEffect(() => {
        if (activateResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produkt aktivert',
                    color: 'success',
                })
            )
            setStatus('ACTIVE')
            onSuccess && onSuccess()
        }
        if (activateResult.isError) {
            if ('data' in activateResult.error) {
                dispatch(
                    addToast({
                        message: 'Kunne ikke aktivere produkt: ' + activateResult.error.data.message,
                        color: 'danger',
                    })
                )
            }
        }
    }, [activateResult])

    const handleDeactivateProduct = () => {
        executeMutation(
            deactivateProduct({ productId }),
            dispatch,
            'Produkt deaktivert',
            'Kunne ikke deaktivere produkt',
            undefined,
            () => {
                setStatus('INACTIVE')
            }
        )
    }
    const handleDeleteProduct = () => {
        executeMutation(
            deleteProduct({ productId }),
            dispatch,
            'Produkt sanert',
            'Kunne ikke sanere produkt',
            undefined,
            () => {
                setStatus('DELETED')
            }
        )
    }
    return (
        <CInputGroup>
            <CInputGroupText>Status</CInputGroupText>
            <CDropdown variant={'input-group'}>
                <CDropdownToggle
                    style={{ '--cui-elevation-base-color': 'transparent' }}
                    color={productStatusColor(status)}
                >
                    {productStatusText(status)}
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem onClick={() => activateProduct({ productId })}>Aktiver</CDropdownItem>
                    <CDropdownItem onClick={handleDeactivateProduct}>Deaktiver</CDropdownItem>
                    <CDropdownItem onClick={handleDeleteProduct}>Saner</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </CInputGroup>
    )
}
