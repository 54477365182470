import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/index.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import moment from 'moment'
import 'moment/locale/nb'

moment.locale('nb')

const hash = window.location.hash
if (hash.indexOf('#token=') === 0) {
    const token = hash.substring(7)
    const path = window.location.pathname
    localStorage.setItem('token', token)
    if (window.history.pushState) {
        window.history.pushState({}, document.title, `${path}`)
    } else {
        window.location.hash = ''
    }
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
