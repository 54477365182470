import { PackageLocation } from '../../packagelocation/types'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'

interface SelectPackageLocationDropdownProps {
    locations?: PackageLocation[]
    onSelect: (location: PackageLocation) => void
    selected?: PackageLocation
}

export const SelectPackageLocationDropdown = ({
    locations,
    onSelect,
    selected,
}: SelectPackageLocationDropdownProps) => {
    const sortedLocations = [...(locations || [])]
    sortedLocations?.sort((a, b) => {
        // Return everything starting with sampakk as the first element
        if (a.name.startsWith('sampakk') && !b.name.startsWith('sampakk')) {
            return -1
        }
        if (!a.name.startsWith('sampakk') && b.name.startsWith('sampakk')) {
            return 1
        }
        return a.name.localeCompare(b.name)
    })

    return (
        <CDropdown variant={'dropdown'}>
            <CDropdownToggle className="p-2" color={'secondary'}>
                {selected?.name || 'Velg et pakksted'}
            </CDropdownToggle>
            <CDropdownMenu
                style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                }}
            >
                {sortedLocations?.map((location) => (
                    <CDropdownItem key={location.id} onClick={() => onSelect(location)}>
                        {location.name}
                    </CDropdownItem>
                ))}
            </CDropdownMenu>
        </CDropdown>
    )
}
