import { createApi } from '@reduxjs/toolkit/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'

export const orderSearchSettingsApi = createApi({
    reducerPath: 'orderSearchSettings',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        createIndex: builder.mutation<any, void>({
            query: () => ({
                url: `/admin/order-search/index`,
                method: 'POST',
            }),
        }),
        deleteIndex: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/order-search/index`,
                method: 'DELETE',
            }),
        }),
        reIndex: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/order-search/index`,
                method: 'PUT',
            }),
        }),
    }),
})

export const { useCreateIndexMutation, useDeleteIndexMutation, useReIndexMutation } = orderSearchSettingsApi
