import { PaymentEvent } from '../types'
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import moment from 'moment'
import PaymentStatusBadge from './PaymentStatusBadge'
import React from 'react'

interface PaymentHistoryProps {
    events: PaymentEvent[]
}

function PaymentHistoryTable({ events }: PaymentHistoryProps) {
    return (
        <CTable>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>Historikk</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {[...events]
                    .sort((a, b) => moment(a.created).diff(moment(b.created)))
                    .map((event) => {
                        return (
                            <CTableRow key={event.created}>
                                <CTableDataCell>{moment(event.created).format('MM.DD HH:mm:ss')}</CTableDataCell>
                                <CTableDataCell>
                                    <PaymentStatusBadge paymentStatus={event.paymentStatus} />
                                </CTableDataCell>
                            </CTableRow>
                        )
                    })}
            </CTableBody>
        </CTable>
    )
}

export default PaymentHistoryTable
