import {
    cilBellExclamation,
    cilBuilding,
    cilCalendar,
    cilEnvelopeLetter,
    cilLockLocked,
    cilMoney,
    cilPeople,
    cilSearch,
    cilSettings,
    cilTruck,
    cilUserFemale,
    cilFactory,
    cilMoodVeryGood,
    cilLibrary,
    cilSpeedometer,
    cilStorage,
    cilTerrain,
    cilClipboard,
    cilBarcode,
    cilSortNumericDown,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CBadge, CNavGroup, CNavItem, CNavTitle, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react-pro'
import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/images/logo_v2.svg'
import { AuthorizedContent } from '../../../auth/AuthorizedContent'
import { IRootState } from '../../../store'
import './Sidebar.override.scss'

function Sidebar() {
    const sidebarShow = useSelector<IRootState, boolean>((state) => state.common.showSidebar)
    const environmentColor = getEnvironmentColor()

    return (
        <CSidebar
            size={'lg'}
            visible={sidebarShow}
            position={'sticky'}
            style={{
                backgroundColor: environmentColor,
                overflowY: 'auto',
            }}
        >
            <CSidebarBrand>
                <img alt="Morgenlevering logo" height={36} src={logo} />
                &nbsp;Morgenlevering Admin
            </CSidebarBrand>

            <CSidebarNav>
                <CNavTitle>Oversikt</CNavTitle>
                <AuthorizedContent requiredPermissions={['reports.sales']}>
                    <CNavItemWrapper to={'/dashboard'}>
                        <CIcon customClassName="nav-icon" icon={cilMoodVeryGood} />
                        Dashboard
                    </CNavItemWrapper>
                </AuthorizedContent>
                <AuthorizedContent requiredPermissions={['distribution.read']}>
                    <CNavItemWrapper to={'/distribusjon/kalender'}>
                        <CIcon customClassName="nav-icon" icon={cilCalendar} />
                        Distribusjonskalender
                    </CNavItemWrapper>
                </AuthorizedContent>
            </CSidebarNav>

            <AuthorizedContent requiredPermissions={['products.read', 'suppliers.read']}>
                <CSidebarNav>
                    <CNavTitle>Produkter</CNavTitle>
                    <AuthorizedContent requiredPermissions={['products.read']}>
                        <CNavItemWrapper to={'/produkter'}>
                            <CIcon customClassName="nav-icon" icon={cilLibrary} />
                            Produktregister
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['suppliers.read']}>
                        <CNavItemWrapper to={'/leverandører'}>
                            <CIcon customClassName="nav-icon" icon={cilFactory} />
                            Leverandører
                        </CNavItemWrapper>
                        <CNavItemWrapper to={'/leverandører/v2'}>
                            <CIcon customClassName="nav-icon" icon={cilFactory} />
                            Leverandører v2 <CBadge className={'ms-auto'}>BETA</CBadge>
                        </CNavItemWrapper>
                    </AuthorizedContent>
                </CSidebarNav>
            </AuthorizedContent>

            <AuthorizedContent requiredPermissions={['inventory.read', 'packingcapacity.read', 'sorting.read']}>
                <CSidebarNav>
                    <CNavTitle>Lager og Kapasitet</CNavTitle>
                    <AuthorizedContent requiredPermissions={['inventory.read']}>
                        <CNavItemWrapper to={'/lager'}>
                            <CIcon customClassName="nav-icon" icon={cilStorage} />
                            Lager
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['sorting.read']}>
                        <CNavItemWrapper to={'/utsortering'}>
                            <CIcon customClassName="nav-icon" icon={cilSortNumericDown} />
                            Utsortering
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['packingcapacity.read']}>
                        <CNavItemWrapper to={'/kapasitet'}>
                            <CIcon customClassName="nav-icon" icon={cilSpeedometer} />
                            Kapasitet
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['purchaseorders.read']}>
                        <CNavItemWrapper to={'/innkjøp'}>
                            <CIcon customClassName="nav-icon" icon={cilClipboard} />
                            Innkjøp
                        </CNavItemWrapper>
                    </AuthorizedContent>
                </CSidebarNav>
            </AuthorizedContent>

            <AuthorizedContent requiredPermissions={['orders.read', 'customers.read', 'ordermonitoring.read']}>
                <CSidebarNav>
                    <CNavTitle>Ordrebehandling</CNavTitle>
                    <AuthorizedContent requiredPermissions={['orders.read']}>
                        <CNavItemWrapper to="/ordre">
                            <CIcon customClassName="nav-icon" icon={cilSearch} />
                            Ordresøk
                            <CBadge className="ms-auto">BETA</CBadge>
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['customers.read']}>
                        <CNavItemWrapper to={'/kunde/search'}>
                            <CIcon customClassName="nav-icon" icon={cilUserFemale} />
                            Kundesøk
                            <CBadge className="ms-auto">BETA</CBadge>
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['ordermonitoring.read']}>
                        <CNavItemWrapper to={'/ordreovervakning'}>
                            <CIcon customClassName="nav-icon" icon={cilBellExclamation} />
                            Ordreovervåkning
                        </CNavItemWrapper>
                    </AuthorizedContent>
                </CSidebarNav>
            </AuthorizedContent>

            <AuthorizedContent requiredPermissions={['distribution.read', 'freight.read', 'features.read']}>
                <CSidebarNav>
                    <CNavTitle>Butikkinnstillinger</CNavTitle>
                    <AuthorizedContent requiredPermissions={['regions.read']}>
                        <CNavItemWrapper to={'/regioner'}>
                            <CIcon customClassName="nav-icon" icon={cilTerrain} />
                            Regioner
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['distribution.read']}>
                        <CNavItemWrapper to={'/distribusjon/selskaper'}>
                            <CIcon customClassName="nav-icon" icon={cilBuilding} />
                            Distribusjonsselskaper
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['freight.read']}>
                        <CNavItemWrapper to={'/frakt'}>
                            <CIcon customClassName="nav-icon" icon={cilTruck} />
                            Frakt
                        </CNavItemWrapper>
                    </AuthorizedContent>
                    <AuthorizedContent requiredPermissions={['features.read']}>
                        <CNavItemWrapper to={'/features'}>
                            <CIcon customClassName="nav-icon" icon={cilSettings} />
                            Features
                        </CNavItemWrapper>
                    </AuthorizedContent>
                </CSidebarNav>
            </AuthorizedContent>

            <AuthorizedContent requiredPermissions={['reports.distribution', 'reports.sales']}>
                <CSidebarNav>
                    <CNavGroup toggler="Rapporter">
                        <AuthorizedContent requiredPermissions={['reports.sales']}>
                            <CNavItemWrapper to={'/rapporter/salgstall'}>
                                <CIcon customClassName="nav-icon" icon={cilMoney} />
                                Salgstall
                            </CNavItemWrapper>
                            <CNavItemWrapper to={'/rapporter/orderlines'}>
                                <CIcon customClassName="nav-icon" icon={cilMoney} />
                                Orderlines
                            </CNavItemWrapper>
                        </AuthorizedContent>
                        <AuthorizedContent requiredPermissions={['reports.distribution']}>
                            <CNavItemWrapper to="/rapporter/distribusjon">
                                <CIcon customClassName="nav-icon" icon={cilTruck} />
                                Distribusjon
                            </CNavItemWrapper>
                            <CNavItemWrapper to="/rapporter/ruteeksport">
                                <CIcon customClassName="nav-icon" icon={cilTruck} />
                                Ruteeksport
                            </CNavItemWrapper>
                        </AuthorizedContent>
                    </CNavGroup>
                </CSidebarNav>
            </AuthorizedContent>

            <AuthorizedContent requiredPermissions={['users.read', 'permissions.read']}>
                <CSidebarNav>
                    <CNavGroup toggler="Sikkerhet">
                        <AuthorizedContent requiredPermissions={['users.read']}>
                            <CNavItemWrapper to="/innstillinger/brukere">
                                <CIcon customClassName="nav-icon" icon={cilPeople} />
                                Brukere
                            </CNavItemWrapper>
                        </AuthorizedContent>
                        <AuthorizedContent requiredPermissions={['permissions.read']}>
                            <CNavItemWrapper to="/innstillinger/roller">
                                <CIcon customClassName="nav-icon" icon={cilLockLocked} />
                                Roller
                            </CNavItemWrapper>
                        </AuthorizedContent>
                    </CNavGroup>
                </CSidebarNav>
            </AuthorizedContent>

            <AuthorizedContent requiredPermissions={['developer.search', 'developer.notifications', 'events.read']}>
                <CSidebarNav>
                    <CNavGroup toggler="Utviklerverktøy">
                        <AuthorizedContent requiredPermissions={['developer.search']}>
                            <CNavItemWrapper to="/innstillinger/søk">
                                <CIcon customClassName="nav-icon" icon={cilSettings} />
                                Søk
                                <CBadge color="danger ms-auto">BETA</CBadge>
                            </CNavItemWrapper>
                        </AuthorizedContent>
                        <AuthorizedContent requiredPermissions={['events.read']}>
                            <CNavItemWrapper to="/innstillinger/projiseringer">
                                <CIcon customClassName="nav-icon" icon={cilSettings} />
                                Projiseringer
                                <CBadge color="danger ms-auto">BETA</CBadge>
                            </CNavItemWrapper>
                        </AuthorizedContent>
                        <AuthorizedContent requiredPermissions={['developer.notifications']}>
                            <CNavItemWrapper to="/innstillinger/notifikasjoner">
                                <CIcon customClassName="nav-icon" icon={cilEnvelopeLetter} />
                                Notifikasjoner
                                <CBadge color="danger ms-auto">BETA</CBadge>
                            </CNavItemWrapper>
                        </AuthorizedContent>
                        <CNavItemWrapper to={'/strekkodegenerator'}>
                            <CIcon customClassName="nav-icon" icon={cilBarcode} />
                            Strekkodegenerator
                        </CNavItemWrapper>
                    </CNavGroup>
                </CSidebarNav>
            </AuthorizedContent>
        </CSidebar>
    )
}

interface CNavItemWrapperProps {
    to: string
    children?: React.ReactChild | React.ReactChild[]
}

function CNavItemWrapper({ to, children }: CNavItemWrapperProps) {
    const history = useHistory()
    const match = useRouteMatch({
        path: to,
        exact: false,
    })

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault()
        const href = e.currentTarget.getAttribute('href')
        history.push(href ? href : '#')
    }

    return (
        <CNavItem href={to} onClick={handleClick} active={match != null}>
            {children}
        </CNavItem>
    )
}

function getEnvironment() {
    const url = window.location.href
    if (url.includes('localhost')) {
        return 'local'
    } else if (url.includes('admin-dev.morgenlevering.no')) {
        return 'dev'
    } else if (url.includes('admin-pre.morgenlevering..no')) {
        return 'pre'
    } else {
        return 'prod'
    }
}

function getEnvironmentColor() {
    const environment = getEnvironment()
    switch (environment) {
        case 'local':
            return '#643c4b'
        case 'dev':
            return '#3c6455'
        case 'pre':
            return '#64553c'
        case 'prod':
            return '#3c4b64'
        default:
            return '#3c4b64'
    }
}

export default Sidebar
