import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { Page } from '../../common'
import { executeMutation } from '../../common/utils/apiUtils'
import { CreateRoleModal } from '../components/CreateRoleModal'
import { UpdateRoleModal } from '../components/UpdateRoleModal'
import {
    useAdminRoles,
    useCreateAdminRoleMutation,
    useDeleteAdminRoleMutation,
    useUpdateAdminRoleMutation,
} from '../store/adminRoleApi'
import { AdminRole } from '../types'

export function AdminRolePage() {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'permissions.write')
    const roles = useAdminRoles()
    const [createAdminRole] = useCreateAdminRoleMutation()
    const [updateAdminRole] = useUpdateAdminRoleMutation()
    const [deleteAdminRole] = useDeleteAdminRoleMutation()

    const [showCreateRoleModal, setShowCreateRoleModal] = useState(false)
    const [roleToBeEdited, setRoleToBeEdited] = useState<AdminRole>()
    const [roleToBeDeleted, setRoleToBeDeleted] = useState<string>()

    const createRole = (
        role: string,
        description: string,
        elevated: boolean,
        permissions: string[],
        resetFormFunc: () => void
    ) => {
        executeMutation(
            createAdminRole({ role, description, elevated, permissions }),
            dispatch,
            'Rollen ble opprettet',
            'Kunne ikke opprette rollen',
            () => {
                setShowCreateRoleModal(false)
                resetFormFunc()
            }
        )
    }

    const updateRole = (role: string, description: string, elevated: boolean, permissions: string[]) => {
        executeMutation(
            updateAdminRole({ role, description, elevated, permissions }),
            dispatch,
            'Rollen ble oppdatert',
            'Kunne ikke oppdatere rollen',
            () => {
                setRoleToBeEdited(undefined)
            }
        )
    }

    const deleteRole = (role: string) => {
        executeMutation(deleteAdminRole(role), dispatch, 'Rollen ble slettet', 'Kunne ikke slette rollen', () =>
            setRoleToBeDeleted(undefined)
        )
    }

    return (
        <Page title="Roller og rettigheter" preamble="Administrasjon av roller og rettigheter.">
            <CTable align="middle" borderless>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>Rolle</CTableHeaderCell>
                        <CTableHeaderCell>Beskrivelse</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {[...roles]
                        .sort((a, b) => a.role.localeCompare(b.role))
                        .map((r) => (
                            <CTableRow key={r.role}>
                                <CTableDataCell>
                                    {r.role}
                                    {r.elevated && (
                                        <>
                                            &nbsp;
                                            <CIcon icon={cilLockLocked} />
                                        </>
                                    )}
                                </CTableDataCell>
                                <CTableDataCell>{r.description}</CTableDataCell>
                                <CTableDataCell className="ta-r">
                                    <CButton color="primary" onClick={() => setRoleToBeEdited(r)} disabled={!canChange}>
                                        Endre
                                    </CButton>
                                    <CButton
                                        className="ml-1/2"
                                        color="danger"
                                        onClick={() => setRoleToBeDeleted(r.role)}
                                        disabled={!canChange}
                                    >
                                        Slett
                                    </CButton>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                </CTableBody>
            </CTable>
            <div className="ta-c">
                <CButton color="primary" onClick={() => setShowCreateRoleModal(true)} disabled={!canChange}>
                    Opprett rolle
                </CButton>
            </div>
            <CreateRoleModal
                visible={showCreateRoleModal}
                onClose={() => setShowCreateRoleModal(false)}
                onCreate={createRole}
            />
            <UpdateRoleModal role={roleToBeEdited} onClose={() => setRoleToBeEdited(undefined)} onUpdate={updateRole} />
            <CModal visible={!!roleToBeDeleted} alignment="center" onClose={() => setRoleToBeDeleted(undefined)}>
                <CModalHeader>
                    <CModalTitle>Slett {roleToBeDeleted}?</CModalTitle>
                </CModalHeader>
                <CModalBody>Merk: Det er ikke mulig å slette roller som er i bruk.</CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setRoleToBeDeleted(undefined)}>
                        Avbryt
                    </CButton>
                    <CButton
                        color="danger"
                        onClick={() => {
                            if (!roleToBeDeleted) {
                                console.warn('Trying to delete role without a role')
                                return
                            }

                            deleteRole(roleToBeDeleted)
                        }}
                    >
                        Slett rolle
                    </CButton>
                </CModalFooter>
            </CModal>
        </Page>
    )
}
