import moment from 'moment'
import css from './WeekSelector.module.scss'
import prevIcon from '../../../assets/images/chevron_prev.svg'
import nextIcon from '../../../assets/images/chevron_next.svg'
import React from 'react'

interface WeekSelectorProps {
    onNext: () => void
    onPrev: () => void
    weekStart: moment.Moment
}

function WeekSelector({ weekStart, onNext, onPrev }: WeekSelectorProps) {
    return (
        <div className={css.weekSelector}>
            <span>
                {weekStart.format('LL')} - {weekStart.clone().endOf('week').format('LL')}
            </span>
            <div className={css.buttonGroup}>
                <button onClick={onPrev}>
                    <img src={prevIcon} alt={'Forrige uke'} />
                </button>
                <button onClick={onNext}>
                    <img src={nextIcon} alt={'Neste uke'} />
                </button>
            </div>
        </div>
    )
}

export default WeekSelector
