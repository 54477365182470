import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'

export interface SortingAlias {
    id: number
    alias: string
    aliasFor: string
    aliasType: string
}

export interface CreateSortingAliasRequest {
    alias: string
    aliasFor: string
}

export const sortingApi = createApi({
    reducerPath: 'sorting',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['sorting'],
    endpoints: (builder) => ({
        list: builder.query<SortingAlias[], void>({
            query: () => ({
                url: `/admin/distribution/sorting-alias`,
                method: 'GET',
            }),
            providesTags: ['sorting'],
        }),

        deleteSortingAlias: builder.mutation<void, { id: number }>({
            query: (request) => ({
                url: `/admin/distribution/sorting-alias/${request.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['sorting'],
        }),

        createSortingAlias: builder.mutation<void, CreateSortingAliasRequest>({
            query: (request) => ({
                url: `/admin/distribution/sorting-alias`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['sorting'],
        }),
    }),
})
