import { CFormSwitch } from '@coreui/react-pro'
import { ChangeEvent, useState } from 'react'
import { useSetVisibilityMutation } from '../store/productApi'

export default function ProductVisibilityCheckbox({ productId, visible }: { productId: string; visible: boolean }) {
    const [updateVisibility] = useSetVisibilityMutation()
    const [hidden, setHidden] = useState(!visible)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setHidden(e.target.checked)
        updateVisibility({ productId, visible: !e.target.checked })
    }
    return (
        <CFormSwitch
            style={
                {
                    '--cui-form-check-input-checked-bg-color': 'var(--cui-danger)',
                    '--cui-form-check-input-checked-border-color': 'var(--cui-danger)',
                } as React.CSSProperties
            }
            label={'Skjul fra nettsiden'}
            size={'xl'}
            onChange={handleChange}
            checked={hidden}
        />
    )
}
