import { createApi } from '@reduxjs/toolkit/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { PaymentWithHistory } from '../types'

export interface CreateRefundRequest {
    paymentId: string
    refundCode: string
    message: string
    refundItems: RefundItem[]
}

export interface RefundItem {
    itemReference: string
    amount: number
}

export interface RefundCode {
    code: string
    description: string
}

export const paymentAdminApi = createApi({
    reducerPath: 'paymentAdmin',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getPaymentByOrderId: builder.query<PaymentWithHistory, string>({
            query: (orderId) => ({
                url: `/admin/payment/${orderId}`,
                method: 'GET',
            }),
        }),
        getRefundCodes: builder.query<RefundCode[], void>({
            query: () => ({
                url: `/admin/payment/refund-codes`,
                method: 'GET',
            }),
        }),
        capturePayment: builder.mutation<undefined, string>({
            query: (paymentId) => ({
                url: `/admin/payment/${paymentId}/capture`,
                method: 'POST',
            }),
        }),
        sendReceipt: builder.mutation<undefined, string>({
            query: (paymentId) => ({
                url: `/admin/payment/${paymentId}/receipt`,
                method: 'POST',
            }),
        }),
        requestRefund: builder.mutation<undefined, CreateRefundRequest>({
            query: (data) => ({
                url: `/admin/payment/${data.paymentId}/refund`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
})

export const {
    useGetPaymentByOrderIdQuery,
    useSendReceiptMutation,
    useRequestRefundMutation,
    useCapturePaymentMutation,
    useGetRefundCodesQuery,
} = paymentAdminApi
