import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'

export const notificationAdminApi = createApi({
    reducerPath: 'notificationAdmin',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        testAllNotifications: builder.mutation<any, TestAllNotificationsRequest>({
            query: (body) => ({
                url: `/admin/notification/test`,
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const { useTestAllNotificationsMutation } = notificationAdminApi

interface TestAllNotificationsRequest {
    recipient: string
    orderId: string
}
