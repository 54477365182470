import { CCol, CContainer, CRow } from '@coreui/react-pro'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { AuthorizedContent } from '../../auth/AuthorizedContent'
import { IRootState } from '../../store'
import CustomerCard from '../components/CustomerCard'
import OrdersCard from '../components/OrdersCard'
import PaymentMethodsCard from '../components/PaymentMethodsCard'
import PrivacyCard from '../components/PrivacyCard'
import SubscriptionCard from '../components/SubscriptionCard'
import { fetchCustomer } from '../store/customerSlice'
import { Customer } from '../types'

interface CustomerDetailsParams {
    customerId: string
}

function CustomerDetailsPage() {
    const { customerId } = useParams<CustomerDetailsParams>()
    const customer = useSelector<IRootState, Customer | undefined>((state) => state.customer.customer)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchCustomer(customerId))
    }, [customerId])

    return (
        <div>
            <h1>Kundedetaljer</h1>
            {customer && (
                <CContainer fluid>
                    <CRow xs={{ gutterY: 3 }}>
                        <CCol sm={6}>
                            <CustomerCard customer={customer} />
                        </CCol>
                        <CCol sm={6}>
                            <PrivacyCard customer={customer} />
                        </CCol>
                        <CCol sm={12}>
                            <SubscriptionCard customerId={customerId} />
                        </CCol>
                        <AuthorizedContent requiredPermissions={['orders.read']}>
                            <CCol sm={12}>
                                <OrdersCard customer={customer} />
                            </CCol>
                        </AuthorizedContent>
                        <CCol sm={12}>
                            <PaymentMethodsCard customer={customer} />
                        </CCol>
                    </CRow>
                </CContainer>
            )}
        </div>
    )
}

export default CustomerDetailsPage
