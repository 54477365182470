import { CCol, CForm, CFormInput, CInputGroup, CRow, CSpinner } from '@coreui/react-pro'
import { useSearchQuery } from '../store/orderAdminApi'
import { useState } from 'react'
import OrderSearchResultTable from '../components/OrderSearchResultTable'
import { Page } from '../../common'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { ExportOrdersToWMSButton } from '../components/ExportOrdersToWMSButton'
import { AuthorizedContent } from '../../auth/AuthorizedContent'

function OrderSearchPage() {
    const [query, setQuery] = useState('')
    const {
        data: searchResult,
        isLoading,
        isError,
        error,
        isSuccess,
    } = useSearchQuery(query, {
        pollingInterval: 15000,
    })

    return (
        <Page title="Ordresøk">
            <CForm>
                <CRow>
                    <CCol sm={8}>
                        <CInputGroup className="mb-3">
                            <CFormInput
                                name={'search_term'}
                                placeholder="Ordre ID"
                                aria-label="Ordre ID"
                                aria-describedby="search_button"
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </CInputGroup>
                    </CCol>
                    <CCol sm={1} />
                    <AuthorizedContent requiredPermissions={['orders.write']}>
                        <CCol sm={3}>
                            <ExportOrdersToWMSButton />
                        </CCol>
                    </AuthorizedContent>
                </CRow>
            </CForm>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && searchResult && (
                <>
                    <p>Viser {searchResult.hits} treff</p>
                    <OrderSearchResultTable orders={searchResult.orders} />
                </>
            )}
        </Page>
    )
}

export default OrderSearchPage
