import { CCol, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useRegions } from '../../../region/store/regionApi'

export interface DistributionCompanyData {
    name: string
    externalId: number
    regionId: number
    orderDeadline: string
}

interface DistributionCompanyFormProps {
    company: DistributionCompanyData
    onChange: (company: DistributionCompanyData) => void
    onlyAllowDeadlineChange: boolean
    className?: string
}

export function DistributionCompanyForm({
    company,
    onChange,
    onlyAllowDeadlineChange,
    className,
}: DistributionCompanyFormProps) {
    const regions = useRegions()

    const [name, setName] = useState(company.name)
    const [externalId, setExternalId] = useState(company.externalId)
    const [regionId, setRegionId] = useState(company.regionId)
    const [orderDeadline, setOrderDeadline] = useState(company.orderDeadline)

    useEffect(() => {
        onChange({ name, externalId, regionId, orderDeadline })
    }, [name, externalId, regionId, orderDeadline])

    return (
        <div className={className}>
            <CRow className="mb-1/2">
                <CFormLabel className="col-6 col-form-label">Selskapsnavn</CFormLabel>
                <CCol>
                    <CFormInput
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        disabled={onlyAllowDeadlineChange}
                    />
                </CCol>
            </CRow>
            <CRow className="mb-1/2">
                <CFormLabel className="col-6 col-form-label">Id hos DI</CFormLabel>
                <CCol>
                    <CFormInput
                        type="number"
                        min={1}
                        value={externalId}
                        onChange={(e) => setExternalId(e.currentTarget.valueAsNumber)}
                        disabled={onlyAllowDeadlineChange}
                    />
                </CCol>
            </CRow>
            <CRow className="mb-1/2">
                <CFormLabel className="col-6 col-form-label">Region</CFormLabel>
                <CCol>
                    <CFormSelect
                        options={regions.map((r) => ({ label: r.name, value: r.id.toString() }))}
                        type="text"
                        value={regionId.toString()}
                        onChange={(e) => setRegionId(+e.currentTarget.value)}
                        disabled={onlyAllowDeadlineChange}
                    />
                </CCol>
            </CRow>
            <CRow className="mb-1/2">
                <CFormLabel className="col-6 col-form-label">Bestillingsfrist</CFormLabel>
                <CCol>
                    <CFormInput
                        type="time"
                        value={orderDeadline}
                        onChange={(e) => setOrderDeadline(e.currentTarget.value)}
                    />
                </CCol>
            </CRow>
        </div>
    )
}
