import { CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import { Freight } from '../types'
import { FreightFormRow } from './FreightFormRow'
import style from './FreightTable.module.scss'

interface FreightTableProps {
    freight: Freight[]
}

export function FreightTable({ freight }: FreightTableProps) {
    return (
        <CTable align="middle" borderless className={style.table}>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>Region</CTableHeaderCell>
                    <CTableHeaderCell>Fraktpris</CTableHeaderCell>
                    <CTableHeaderCell>Minimumsgrense for gratis frakt</CTableHeaderCell>
                    <CTableHeaderCell></CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {freight.map((f) => (
                    <FreightFormRow freight={f} key={f.regionId} />
                ))}
            </CTableBody>
        </CTable>
    )
}
