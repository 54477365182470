import { CCardHeader } from '@coreui/react-pro'
import React, { ReactNode } from 'react'

interface CardHeaderProps {
    children: ReactNode
}

export default function CardHeader({ children }: CardHeaderProps) {
    return (
        <CCardHeader
            style={{
                '--cui-card-border-width': '0',
                fontSize: '1.5rem',
            }}
        >
            {children}
        </CCardHeader>
    )
}
