import { RegisteredProduct } from '../types'
import { CMultiSelect, CSmartTable } from '@coreui/react-pro'
import { cilExternalLink, cilImage } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useHistory } from 'react-router-dom'
import ProductStatusBadge from './ProductStatusBadge'
import { useRegions } from '../../region/store/regionApi'
import { useState } from 'react'
import type { ColumnFilterValue, SorterValue } from '@coreui/react-pro/src/components/smart-table/types'
import { SearchParamInput } from '../../common/components/Input/Input'

interface ProductTableProps {
    products: RegisteredProduct[]
    onSelectChange?: (ids: string[]) => void
}

const initialColumnFilterValues = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const values: ColumnFilterValue = {}
    searchParams.forEach((value, key) => {
        values[key] = value
    })
    return values
}

export default function ProductTable({ products, onSelectChange }: ProductTableProps) {
    const history = useHistory()
    const regions = useRegions()
    const [itemsShown, setItemsShown] = useState(products.length)
    const [columnFilterValues] = useState(initialColumnFilterValues())
    const currencyFormatter = new Intl.NumberFormat('no-NB', {
        style: 'currency',
        currency: 'NOK',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    const customFilter = (param: string, options: { fuzzy: boolean }) => {
        // eslint-disable-next-line react/display-name
        return (_: unknown[], onChange: (value: any) => void) => {
            return (
                <SearchParamInput
                    onChange={(value) => {
                        return onChange((item: string) => {
                            return options.fuzzy
                                ? fuzzySearch(value, item)
                                : item?.toLowerCase().includes(value.toLowerCase())
                        })
                    }}
                    parameter={param}
                />
            )
        }
    }

    const updateSearchParams = (key: string, value?: string) => {
        const searchParams = new URLSearchParams(window.location.search)
        if (key && value) {
            searchParams.set(key, value)
        } else {
            searchParams.delete(key)
        }
        history.replace({
            search: searchParams.toString(),
        })
    }

    const handleSorterChange = (value: SorterValue): void => {
        updateSearchParams('sort', value.state ? `${value.column}-${value.state}` : ``)
    }

    const sorterValue = (): SorterValue => {
        const searchParams = new URLSearchParams(window.location.search)

        return {
            column: searchParams.get('sort')?.split('-')?.at(0),
            state: searchParams.get('sort')?.split('-')?.at(1),
        }
    }

    const isStatusSelected = (status: string): boolean => {
        const searchParams = new URLSearchParams(window.location.search)
        return searchParams.get('status')?.toUpperCase()?.split('-')?.includes(status) ?? false
    }

    const isRegionSelected = (region: string): boolean => {
        const searchParams = new URLSearchParams(window.location.search)
        return searchParams.get('region')?.split('-')?.includes(region) ?? false
    }

    const tableFilterValue = (): string => {
        const searchParams = new URLSearchParams(window.location.search)
        return searchParams.get('filter') ?? ''
    }

    return (
        <>
            <p>
                Viser {itemsShown} av {products.length} produkter
            </p>
            <CSmartTable
                sorterValue={sorterValue()}
                onSorterChange={handleSorterChange}
                onTableFilterChange={(value) => updateSearchParams('filter', value)}
                tableFilterValue={tableFilterValue()}
                columnFilter
                columnFilterValue={columnFilterValues}
                tableFilterLabel={'Søk:'}
                tableFilterPlaceholder={'Skriv for å søke...'}
                tableFilter
                selectable={onSelectChange !== undefined}
                columnSorter
                clickableRows
                onFilteredItemsChange={(items) => setItemsShown(items.length)}
                onSelectedItemsChange={(items) => {
                    onSelectChange && onSelectChange(items.map((item) => item.id as string))
                }}
                onRowClick={(item) => {
                    history.push(`/produkter/${item.id}`)
                }}
                tableProps={{
                    responsive: true,
                    striped: true,
                    hover: true,
                    borderless: true,
                }}
                columns={[
                    {
                        key: 'listImageUrl',
                        label: '',
                        filter: false,
                        sorter: false,
                        _style: { width: '5%' },
                    },
                    {
                        key: 'externalProductId',
                        label: 'Ekstern produktid',
                        _style: { width: '10%' },
                        filter: customFilter('externalProductId', { fuzzy: false }),
                    },
                    {
                        key: 'sku',
                        label: 'SKU',
                        _style: { width: '10%' },
                        filter: customFilter('sku', { fuzzy: false }),
                    },
                    {
                        key: 'title',
                        label: 'Navn',
                        filter: customFilter('title', { fuzzy: true }),
                    },
                    {
                        key: 'supplierName',
                        label: 'Leverandør',
                        _style: { width: '10%' },
                        filter: customFilter('supplierName', { fuzzy: false }),
                    },
                    {
                        key: 'price',
                        label: 'Pris',
                        filter: false,
                        _style: { width: '5%' },
                    },
                    {
                        key: 'margin',
                        label: 'Margin',
                        filter: false,
                        _style: { width: '5%' },
                    },
                    {
                        key: 'category',
                        label: 'Kategori',
                        filter: customFilter('category', { fuzzy: true }),
                        _style: { width: '10%' },
                    },
                    {
                        key: 'activeInRegions',
                        label: 'Regioner',
                        sorter: true,
                        _style: { width: '15%' },
                        filter: (values, onChange) => {
                            return (
                                <CMultiSelect
                                    style={{ fontSize: '0.1rem' }}
                                    options={regions.map((r) => ({
                                        value: r.id,
                                        text: r.externalRegion,
                                        selected: isRegionSelected(`${r.id}`),
                                    }))}
                                    placeholder={'Filtrer...'}
                                    selectAll={false}
                                    onChange={(selected) => {
                                        updateSearchParams(
                                            'region',
                                            selected
                                                .map((option) => option.value as string)
                                                .join('-')
                                                .toLowerCase()
                                        )
                                        onChange((item: number[]) => {
                                            return selected.length == 0
                                                ? true
                                                : item.some((i) =>
                                                      selected.map((option) => option.value as number).includes(i)
                                                  )
                                        })
                                    }}
                                />
                            )
                        },
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        _style: { width: '15%' },
                        filter: (values, onChange) => {
                            return (
                                <CMultiSelect
                                    options={[
                                        { value: 'ACTIVE', text: 'Aktiv', selected: isStatusSelected('ACTIVE') },
                                        { value: 'INACTIVE', text: 'Inaktiv', selected: isStatusSelected('INACTIVE') },
                                        { value: 'DELETED', text: 'Slettet', selected: isStatusSelected('DELETED') },
                                        { value: 'CREATED', text: 'Opprettet', selected: isStatusSelected('CREATED') },
                                    ]}
                                    placeholder={'Filtrer...'}
                                    selectAll={false}
                                    onChange={(selected) => {
                                        updateSearchParams(
                                            'status',
                                            selected
                                                .map((option) => option.value as string)
                                                .join('-')
                                                .toLowerCase()
                                        )
                                        onChange((item: string) => {
                                            return selected.length == 0
                                                ? true
                                                : selected.map((option) => option.value as string).includes(item)
                                        })
                                    }}
                                />
                            )
                        },
                    },
                    {
                        key: 'sortingPriority',
                        label: 'Prio',
                    },
                    {
                        key: 'id',
                        label: '',
                        filter: false,
                        sorter: false,
                        _style: { width: '5%' },
                    },
                ]}
                scopedColumns={{
                    listImageUrl: (item: RegisteredProduct) => (
                        <td style={{ textAlign: 'center' }}>
                            {!item.listImageUrl && <CIcon size={'xl'} icon={cilImage} className="text-danger m-2" />}
                            {item.listImageUrl && (
                                <img src={item.listImageUrl} width={48} height={48} style={{ borderRadius: '50%' }} />
                            )}
                        </td>
                    ),
                    costPriceExVat: (item: RegisteredProduct) => (
                        <td>{item.costPriceExVat ? currencyFormatter.format(item.costPriceExVat) : '-'}</td>
                    ),
                    price: (item: RegisteredProduct) => <td>{currencyFormatter.format(item.price)}</td>,
                    margin: (item: RegisteredProduct) => <td>{item.margin}%</td>,
                    vatRate: (item: RegisteredProduct) => <td>{item.vatRate}%</td>,
                    grossWeight: (item: RegisteredProduct) => (
                        <td>{item.grossWeight ? `${item.grossWeight}g` : '-'}</td>
                    ),
                    status: (item: RegisteredProduct) => {
                        return (
                            <td>
                                <ProductStatusBadge status={item.status} />
                            </td>
                        )
                    },
                    activeInRegions: (item: RegisteredProduct) => {
                        const activeInRegions = regions.filter((r) => item.activeInRegions.includes(r.id))
                        return (
                            <td>
                                {activeInRegions.map((region) => {
                                    return (
                                        <>
                                            <small>
                                                {region.externalRegion.charAt(0).toUpperCase() +
                                                    region.externalRegion.slice(1)}
                                            </small>
                                            <br />
                                        </>
                                    )
                                })}
                            </td>
                        )
                    },
                    badge: (item: RegisteredProduct) => {
                        return <td>{item.badge?.name}</td>
                    },
                    id: (item: RegisteredProduct) => (
                        <td style={{ textAlign: 'center' }}>
                            <a
                                onClick={(e) => e.stopPropagation()}
                                href={`/produkter/${item.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-sm btn-outline-primary"
                                style={{
                                    borderRadius: '50%',
                                    height: '1.5rem',
                                    width: '1.5rem',
                                    padding: '0',
                                }}
                            >
                                <CIcon
                                    height={10}
                                    width={10}
                                    icon={cilExternalLink}
                                    style={{ marginBottom: '0.1rem', marginLeft: '0.1rem' }}
                                />
                            </a>
                        </td>
                    ),
                }}
                itemsPerPage={100000}
                items={products}
            />
        </>
    )
}

function fuzzySearch(pattern?: string, text?: string) {
    if (!pattern) {
        return true
    }

    if (!text) {
        return false
    }
    const sanitizedPattern = pattern.replace(/[^a-zA-Z0-9]/g, '')
    const patternLength = sanitizedPattern.length
    let patternIndex = 0
    for (let i = 0; i < text?.length ?? 0; i++) {
        if (text.toLowerCase()[i] === sanitizedPattern.toLowerCase()[patternIndex]) {
            patternIndex++
        }
        if (patternIndex === patternLength) {
            return true
        }
    }
    return false
}
