import { projectionsApi } from '../projectionsApi'
import { CButton } from '@coreui/react-pro'

export default function ProjectionManagementPage() {
    const { data, isLoading, isSuccess } = projectionsApi.useGetProjectionsQuery()
    const [rebuildProjection] = projectionsApi.useRebuildProjectionMutation()
    return (
        <div>
            <h1>Projiseringer</h1>
            {isLoading && <p>Loading...</p>}
            {isSuccess &&
                data.map((projection) => {
                    return (
                        <p key={projection}>
                            <CButton
                                onClick={() => {
                                    rebuildProjection(projection)
                                }}
                            >
                                Rebuild {projection}
                            </CButton>
                        </p>
                    )
                })}
        </div>
    )
}
