import {
    CButton,
    CCol,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSwitch,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useAdminPermissions } from '../store/adminRoleApi'
import { AdminRole } from '../types'

interface UpdateRoleModalProps {
    role?: AdminRole
    onClose: () => void
    onUpdate: (role: string, description: string, elevated: boolean, permissions: string[]) => void
}

export function UpdateRoleModal({ role, onClose, onUpdate }: UpdateRoleModalProps) {
    const permissions = useAdminPermissions()

    const [description, setDescription] = useState('')
    const [elevated, setElevated] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

    useEffect(() => {
        if (role) {
            setDescription(role.description || '')
            setElevated(role.elevated)
            setSelectedPermissions(role.permissions)
        }
    }, [role])

    const permissionIsSelected = (permission: string) => selectedPermissions.indexOf(permission) > -1

    const togglePermission = (permission: string) => {
        if (permissionIsSelected(permission)) {
            setSelectedPermissions(selectedPermissions.filter((p) => p !== permission))
        } else {
            setSelectedPermissions([...selectedPermissions, permission])
        }
    }

    const update = () => {
        if (!role) {
            console.warn('Trying to update role without a role')
            return
        }
        onUpdate(role.role, description, elevated, selectedPermissions)
    }

    return (
        <CModal scrollable size="lg" visible={!!role} alignment="center" onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Endre {role?.role}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CRow className="mb-1">
                    <CFormLabel className="col-3 col-form-label">Beskrivelse</CFormLabel>
                    <CCol>
                        <CFormInput
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.currentTarget.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="mb-2">
                    <CCol className="offset-3">
                        <CFormSwitch
                            checked={elevated}
                            onChange={(e) => setElevated(e.currentTarget.checked)}
                            label="Krev forhøyede rettigheter for å tildele denne rollen"
                        />
                    </CCol>
                </CRow>
                <CRow className="mb-1/2">
                    <CFormLabel className="col-3 col-form-label">Rettigheter</CFormLabel>
                    <CCol>
                        {[...permissions]
                            .sort((a, b) => a.permission.localeCompare(b.permission))
                            .map((p) => (
                                <CFormCheck
                                    key={p.permission}
                                    label={`${p.description} (${p.permission})`}
                                    checked={permissionIsSelected(p.permission)}
                                    onChange={() => togglePermission(p.permission)}
                                />
                            ))}
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={onClose}>
                    Avbryt
                </CButton>
                <CButton color="primary" onClick={update}>
                    Lagre
                </CButton>
            </CModalFooter>
        </CModal>
    )
}
