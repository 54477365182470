import CustomerPage from '../../../customer/pages/CustomerPage'
import DistributionPage from '../../../distribution/pages/DistributionPage'
import { FreightPage } from '../../../freight/pages/FreightPage'
import OrderPage from '../../../order/pages/OrderPage'
import { OrderMonitoringPage } from '../../../ordermonitoring/pages/OrderMonitoringPage'
import ReportsPage from '../../../reports/pages/ReportsPage'
import SettingsPage from '../../../settings/pages/SettingsPage'
import Dashboard from '../../../reports/pages/Dashboard'
import SupplierPage from '../../../product/pages/SupplierPage'
import { FeaturesPage } from '../../../feature/pages/FeaturesPage'
import InventoryPage from '../../../inventory/pages/InventoryPage'
import { ProductRegistryPage } from '../../../product/pages/ProductRegistryPage'
import PackageLocationPage from '../../../packagelocation/pages/PackageLocationPage'
import RegionsPage from '../../../region/pages/RegionsPage'
import PurchaseOrderPage from '../../../purchaseorder/pages/PurchaseOrderPage'
import BarcodeGeneratorPage from '../../../settings/pages/BarcodeGeneratorPage'
import SortingPage from '../../../distribution/pages/SortingPage'

const routes = [
    {
        path: '/distribusjon',
        name: 'Distribusjon',
        component: DistributionPage,
    },
    {
        path: '/frakt',
        name: 'Frakt',
        component: FreightPage,
    },
    {
        path: '/features',
        name: 'Features',
        component: FeaturesPage,
    },
    {
        path: '/innstillinger',
        name: 'Innstillinger',
        component: SettingsPage,
    },
    {
        path: '/kunde',
        name: 'Kunde',
        component: CustomerPage,
    },
    {
        path: '/ordre',
        name: 'Ordre',
        component: OrderPage,
    },
    {
        path: '/ordreovervakning',
        name: 'Ordreovervåkning',
        component: OrderMonitoringPage,
    },
    {
        path: '/rapporter',
        name: 'Rapporter',
        component: ReportsPage,
    },
    {
        path: '/produkter',
        name: 'Produkter',
        component: ProductRegistryPage,
    },
    {
        path: '/leverandører',
        name: 'Leverandører',
        component: SupplierPage,
    },
    {
        path: '/innkjøp',
        name: 'Innkjøp',
        component: PurchaseOrderPage,
    },
    {
        path: '/lager',
        name: 'Lager',
        component: InventoryPage,
    },
    {
        path: '/kapasitet',
        name: 'Kapasitet',
        component: PackageLocationPage,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/regioner',
        name: 'Regioner',
        component: RegionsPage,
    },
    {
        path: '/strekkodegenerator',
        name: 'Strekkodegenerator',
        component: BarcodeGeneratorPage,
    },
    {
        path: '/utsortering',
        name: 'Utsortering',
        component: SortingPage,
    },
]

export default routes
