import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Status } from '../../common/types'
import { apiUrl } from '../../config'
import authenticatedClient from '../../services/axios'
import { PaymentMethod } from '../types'

export interface PaymentMethodState {
    paymentMethods: PaymentMethod[]
    status: Status
    error: string | null | undefined
}

const initialState: PaymentMethodState = {
    paymentMethods: [],
    status: Status.Idle,
    error: null,
}

export const fetchPaymentMethodsForCustomer = createAsyncThunk(
    'paymentMethods/fetchForCustomer',
    async (customerId: string) => {
        const response = await authenticatedClient.get(`${apiUrl}/admin/paymentmethod/customer/${customerId}`)
        return response.data
    }
)

const paymentMethodSlice = createSlice({
    name: 'paymentmethod',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPaymentMethodsForCustomer.pending, (state) => {
            state.status = Status.Loading
        })
        builder.addCase(fetchPaymentMethodsForCustomer.fulfilled, (state, action) => {
            state.status = Status.Succeeded
            state.paymentMethods = action.payload
        })
        builder.addCase(fetchPaymentMethodsForCustomer.rejected, (state, action) => {
            state.status = Status.Failed
            state.error = action.error.message
        })
    },
})

export default paymentMethodSlice.reducer
