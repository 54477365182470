import { Route, Switch, useRouteMatch } from 'react-router'

import React from 'react'
import PurchaseOrderListPage from './PurchaseOrderListPage'
import PurchaseOrderDetails from './PurchaseOrderDetailsPage'
import { CreatePurchaseOrderPage } from './CreatePurchaseOrderPage'

export default function PurchaseOrderPage() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${path}/ny`}>
                <CreatePurchaseOrderPage />
            </Route>
            <Route exact path={`${path}/:purchaseOrderNumber`}>
                <PurchaseOrderDetails />
            </Route>
            <Route exact path={`${path}`}>
                <PurchaseOrderListPage />
            </Route>
        </Switch>
    )
}
