import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react-pro'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../store'
import { Toast } from '../../types'

export default function Toaster() {
    const toasts = useSelector<IRootState, Toast[]>((state) => state.common.toasts)

    // As keys should be unique, we can't use the index as key
    // However, the CToast component requires a number as key,
    // so we can't use the message as key either
    // We can create a unique key by combining the message, color and index and hash it
    const createKey = (toast: Toast, index: number) => {
        const key = toast.message + toast.color + index
        let hash = 0
        for (let i = 0; i < key.length; i++) {
            hash = (hash << 5) - hash + key.charCodeAt(i)
            hash |= 0
        }

        return hash
    }

    return (
        <CToaster placement="top-end">
            {toasts.map((toast, index) => {
                return (
                    <CToast
                        key={createKey(toast, index)}
                        autohide={true}
                        visible={true}
                        color={toast.color}
                        className="text-white align-items-center"
                    >
                        <div className="d-flex">
                            <CToastBody>{toast.message}</CToastBody>
                            <CToastClose className="me-2 m-auto" white />
                        </div>
                    </CToast>
                )
            })}
        </CToaster>
    )
}
