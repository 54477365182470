import { Route, Switch, useRouteMatch } from 'react-router'
import { AdminUserPage } from '../../adminuser/pages/AdminUserPage'
import NotificationSettingsPage from './NotificationSettingsPage'
import SearchSettingsPage from './SearchSettingsPage'
import { AdminRolePage } from '../../adminuser/pages/AdminRolePage'
import ProjectionManagementPage from '../../event/pages/ProjectionManagementPage'

export default function SettingsPage() {
    const { path } = useRouteMatch()
    return (
        <div>
            <Switch>
                <Route path={`${path}/søk`}>
                    <SearchSettingsPage />
                </Route>
                <Route path={`${path}/notifikasjoner`}>
                    <NotificationSettingsPage />
                </Route>
                <Route path={`${path}/brukere`}>
                    <AdminUserPage />
                </Route>
                <Route path={`${path}/projiseringer`}>
                    <ProjectionManagementPage />
                </Route>
                <Route path={`${path}/roller`}>
                    <AdminRolePage />
                </Route>
            </Switch>
        </div>
    )
}
