export function getFriendlyOrderStatus(orderStatus: string): string {
    switch (orderStatus) {
        case 'RECEIVED':
            return 'Registrert i systemet'
        case 'CONFIRMED':
            return 'Bekreftet (betaling godkjent)'
        case 'PROCESSING':
            return 'Booket hos DI'
        case 'DELIVERED':
            return 'Levert til kunde'
        case 'PARTIALLY_DELIVERED':
            return 'Delvis levert til kunde'
        case 'LOST':
            return 'Tapt eller returnert'
        case 'FAILED':
            return 'Feilet'
        case 'CANCELLED':
            return 'Kansellert'
        case 'CREDITED':
            return 'Kreditert'
    }

    return ''
}
