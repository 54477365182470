import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Status } from '../../common/types'
import { apiUrl } from '../../config'
import authenticatedClient from '../../services/axios'
import { Customer } from '../types'

export interface CustomerState {
    customer?: Customer
    searchResult: Customer[]
    updateStatus: Status
    status: Status
    error: string | null | undefined
    takeoutDownloadStatus: Status
    takeoutDownloadBlobUrl: string | null
    takeoutDownloadError: string | null
    deleteStatus: Status
    deleteError: string | null
}

const initialState: CustomerState = {
    customer: undefined,
    searchResult: [],
    updateStatus: Status.Idle,
    status: Status.Idle,
    error: null,
    takeoutDownloadStatus: Status.Idle,
    takeoutDownloadBlobUrl: null,
    takeoutDownloadError: null,
    deleteStatus: Status.Idle,
    deleteError: null,
}

export const fetchCustomer = createAsyncThunk('customer/fetchCustomer', async (customerId: string) => {
    const response = await authenticatedClient.get(`${apiUrl}/admin/customer/${customerId}`)
    return response.data
})

export const searchCustomer = createAsyncThunk('customer/searchCustomer', async (email: string) => {
    const response = await authenticatedClient.get(`${apiUrl}/admin/customer?email=${email}`)
    return response.data
})

export const downloadCustomerTakeout = createAsyncThunk(
    'customer/downloadCustomerTakeout',
    async (customerId: string) => {
        const response = await authenticatedClient.get(`${apiUrl}/admin/privacy/takeout/${customerId}`, {
            responseType: 'blob',
        })
        return URL.createObjectURL(new Blob([response.data]))
    }
)

export const deleteCustomer = createAsyncThunk('customer/deleteCustomer', async (customerId: string) => {
    return authenticatedClient.delete(`${apiUrl}/admin/privacy/${customerId}`)
})

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        resetTakeoutDownload: (state) => {
            state.takeoutDownloadStatus = Status.Idle
            state.takeoutDownloadError = null
            state.takeoutDownloadBlobUrl = null
        },
        resetCustomerDeletion: (state) => {
            state.deleteStatus = Status.Idle
            state.deleteError = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomer.pending, (state) => {
            state.status = Status.Loading
        })
        builder.addCase(fetchCustomer.fulfilled, (state, action) => {
            state.status = Status.Succeeded
            state.customer = action.payload
        })
        builder.addCase(fetchCustomer.rejected, (state, action) => {
            state.status = Status.Failed
            state.error = action.error.message
        })
        builder.addCase(searchCustomer.pending, (state) => {
            state.status = Status.Loading
        })
        builder.addCase(searchCustomer.fulfilled, (state, action) => {
            state.status = Status.Succeeded
            state.searchResult = action.payload
        })
        builder.addCase(searchCustomer.rejected, (state, action) => {
            state.status = Status.Failed
            state.error = action.error.message
        })
        builder.addCase(downloadCustomerTakeout.fulfilled, (state, action) => {
            state.takeoutDownloadStatus = Status.Succeeded
            state.takeoutDownloadBlobUrl = action.payload
        })
        builder.addCase(downloadCustomerTakeout.pending, (state) => {
            state.takeoutDownloadStatus = Status.Loading
        })
        builder.addCase(downloadCustomerTakeout.rejected, (state, action) => {
            state.takeoutDownloadStatus = Status.Failed
            state.takeoutDownloadError = action.error.message || null
        })
        builder.addCase(deleteCustomer.fulfilled, (state) => {
            state.deleteStatus = Status.Succeeded
        })
        builder.addCase(deleteCustomer.pending, (state) => {
            state.deleteStatus = Status.Loading
        })
        builder.addCase(deleteCustomer.rejected, (state, action) => {
            state.deleteStatus = Status.Failed
            state.deleteError = action.error.message || null
        })
    },
})

export const { resetTakeoutDownload, resetCustomerDeletion } = customerSlice.actions

export default customerSlice.reducer
