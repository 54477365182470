import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { AdminPermission, AdminRole } from '../types'

export const adminRoleApi = createApi({
    reducerPath: 'adminRole',
    tagTypes: ['AdminRole'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getAdminRoles: builder.query<AdminRole[], void>({
            query: () => ({
                url: '/admin/roles',
                method: 'GET',
            }),
            providesTags: ['AdminRole'],
        }),
        createAdminRole: builder.mutation<void, AdminRole>({
            query: (data) => ({
                url: '/admin/roles',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['AdminRole'],
        }),
        updateAdminRole: builder.mutation<void, AdminRole>({
            query: (data) => ({
                url: `/admin/roles/${data.role}`,
                method: 'PUT',
                body: {
                    description: data.description,
                    elevated: data.elevated,
                    permissions: data.permissions,
                },
            }),
            invalidatesTags: ['AdminRole'],
        }),
        deleteAdminRole: builder.mutation<void, string>({
            query: (role) => ({
                url: `/admin/roles/${role}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AdminRole'],
        }),
        getAdminPermissions: builder.query<AdminPermission[], void>({
            query: () => ({
                url: '/admin/permissions',
                method: 'GET',
            }),
        }),
    }),
})

export const useAdminRoles = () => adminRoleApi.useGetAdminRolesQuery().data ?? []
export const useAdminPermissions = () => adminRoleApi.useGetAdminPermissionsQuery().data ?? []
export const { useCreateAdminRoleMutation, useUpdateAdminRoleMutation, useDeleteAdminRoleMutation } = adminRoleApi
