import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { FeatureState } from '../types'

export const featureApi = createApi({
    reducerPath: 'features',
    tagTypes: ['Features'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getFeatures: builder.query<FeatureState[], void>({
            query: () => ({
                url: '/features',
                method: 'GET',
            }),
            providesTags: ['Features'],
        }),
        setState: builder.mutation<void, FeatureState>({
            query: (feature) => ({
                url: `/features`,
                method: 'PUT',
                body: feature,
            }),
            invalidatesTags: ['Features'],
        }),
    }),
})
