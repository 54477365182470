import { CButton, CButtonGroup, CSpinner } from '@coreui/react-pro'
import { useCreateIndexMutation, useDeleteIndexMutation, useReIndexMutation } from '../store/orderSearchSettingsApi'
import { useDispatch } from 'react-redux'
import { addToast } from '../../common/store/commonSlice'
import { Page } from '../../common'

export default function SearchSettingsPage() {
    const [deleteIndex, { isLoading: isDeleting }] = useDeleteIndexMutation()
    const [createIndex, { isLoading: isCreating }] = useCreateIndexMutation()
    const [reIndex, { isLoading: isIndexing }] = useReIndexMutation()
    const dispatch = useDispatch()

    return (
        <Page title={'Søkeinnstillinger'} preamble={'Ikke rør dette om du ikke vet hva du driver med!'}>
            <CButtonGroup>
                <CButton
                    onClick={() =>
                        createIndex()
                            .unwrap()
                            .then(() => dispatch(addToast({ message: 'Index opprettet', color: 'success' })))
                            .catch((error) =>
                                dispatch(addToast({ message: JSON.stringify(error.data), color: 'danger' }))
                            )
                    }
                    color={'success'}
                    disabled={isCreating}
                >
                    Opprett index
                </CButton>
                <CButton
                    onClick={() =>
                        deleteIndex()
                            .unwrap()
                            .then(() => dispatch(addToast({ message: 'Index slettet', color: 'success' })))
                            .catch((error) =>
                                dispatch(addToast({ message: JSON.stringify(error.data), color: 'danger' }))
                            )
                    }
                    disabled={isDeleting}
                    color={'danger'}
                >
                    Slett index
                </CButton>
                <CButton
                    onClick={() =>
                        reIndex()
                            .unwrap()
                            .then(() => dispatch(addToast({ message: 'Reindexering startet', color: 'success' })))
                            .catch((error) =>
                                dispatch(addToast({ message: JSON.stringify(error.data), color: 'danger' }))
                            )
                    }
                    disabled={isIndexing}
                    color={'warning'}
                >
                    Reindexer
                </CButton>
                {(isCreating || isDeleting || isIndexing) && <CSpinner />}
            </CButtonGroup>
        </Page>
    )
}
