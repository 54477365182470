import { Region } from '../../types'
import style from './RegionInfo.module.scss'
import { CCol, CRow } from '@coreui/react-pro'
interface RegionInfoProps {
    region: Region
}

export const RegionInfo = ({ region }: RegionInfoProps) => {
    return (
        <div>
            <h2 className={style.heading}>Info</h2>
            <div className={style.info}>
                <CRow className="mb-1/2">
                    <CCol>
                        <span className={style.label}>Navn: </span>
                    </CCol>
                    <CCol>
                        <span>{region.name}</span>
                    </CCol>
                </CRow>
                <CRow className="mb-1/2">
                    <CCol>
                        <span className={style.label}>Eksternnavn: </span>
                    </CCol>
                    <CCol>
                        <span>{region.externalRegion}</span>
                    </CCol>
                </CRow>
            </div>
        </div>
    )
}
