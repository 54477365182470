import { CButton, CFormInput, CTableDataCell, CTableRow } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useRegions } from '../../region/store/regionApi'
import { useUpdateFreightMutation } from '../store/freightApi'
import { Freight } from '../types'
import style from './FreightFormRow.module.scss'

interface FreightFormRowProps {
    freight: Freight
}

export function FreightFormRow({ freight }: FreightFormRowProps) {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'freight.write')
    const regions = useRegions()
    const [updateFreight] = useUpdateFreightMutation()

    const [isEditMode, setIsEditMode] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [newAmount, setNewAmount] = useState(freight.amount.toFixed(2))
    const [newFreeFreightOrderValue, setNewFreeFreightOrderValue] = useState(freight.freeFreightOrderValue.toFixed(2))

    const abortChanges = () => {
        setIsEditMode(false)
        setNewAmount(freight.amount.toFixed(2))
        setNewFreeFreightOrderValue(freight.freeFreightOrderValue.toFixed(2))
    }

    const save = () => {
        executeMutation(
            updateFreight({
                regionId: freight.regionId,
                amount: +newAmount,
                freeFreightOrderValue: +newFreeFreightOrderValue,
            }),
            dispatch,
            `Frakt for ${regions.find((r) => r.id === freight.regionId)?.name || freight.regionId} ble oppdatert`,
            'Kunne ikke oppdatere frakt',
            () => setIsEditMode(false)
        )
    }

    useEffect(() => {
        setCanSave(
            newAmount.length > 0 &&
                newFreeFreightOrderValue.length > 0 &&
                (+newAmount != freight.amount || +newFreeFreightOrderValue != freight.freeFreightOrderValue)
        )
    }, [newAmount, newFreeFreightOrderValue, freight])

    return (
        <CTableRow key={freight.regionId}>
            <CTableDataCell>{regions.find((r) => r.id === freight.regionId)?.name || freight.regionId}</CTableDataCell>
            {isEditMode ? (
                <>
                    <CTableDataCell className={style.inputTableCell}>
                        <CFormInput
                            type="number"
                            value={newAmount}
                            min={0}
                            onChange={(e) => setNewAmount(e.currentTarget.value)}
                        />
                    </CTableDataCell>
                    <CTableDataCell className={style.inputTableCell}>
                        <CFormInput
                            type="number"
                            value={newFreeFreightOrderValue}
                            min={0}
                            onChange={(e) => setNewFreeFreightOrderValue(e.currentTarget.value)}
                        />
                    </CTableDataCell>
                    <CTableDataCell className={style.buttonTableCell}>
                        <CButton color="secondary" onClick={abortChanges} className={style.button}>
                            Avbryt
                        </CButton>
                        <CButton color="primary" disabled={!canSave} onClick={save} className={style.button}>
                            Lagre
                        </CButton>
                    </CTableDataCell>
                </>
            ) : (
                <>
                    <CTableDataCell>{freight.amount.toFixed(2)}</CTableDataCell>
                    <CTableDataCell>{freight.freeFreightOrderValue.toFixed(2)}</CTableDataCell>
                    <CTableDataCell className={style.buttonTableCell}>
                        <CButton
                            color="primary"
                            onClick={() => setIsEditMode(true)}
                            className={style.button}
                            disabled={!canChange}
                        >
                            Endre
                        </CButton>
                    </CTableDataCell>
                </>
            )}
        </CTableRow>
    )
}
