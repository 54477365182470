import { CButton, CForm, CFormInput, CFormLabel, CFormText } from '@coreui/react-pro'
import format from 'date-fns/format'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { DateChangeEvent, DatePicker, Page } from '../../common'
import { addToast } from '../../common/store/commonSlice'
import { apiUrl } from '../../config'
import authenticatedClient from '../../services/axios'
import css from './common.module.scss'

function RouteExportPage() {
    const dispatch = useDispatch()
    const email = useAuthenticatedUser()?.email
    const [selectedDates, setSelectedDates] = useState<DateChangeEvent>({
        startDate: new Date(),
        endDate: new Date(),
    })
    const [recipientEmail, setRecipientEmail] = useState(email)

    const generateExport = () => {
        if (!recipientEmail) return

        authenticatedClient
            .post(`${apiUrl}/reports/route-export`, {
                fromDate: format(selectedDates.startDate, 'yyyy-MM-dd'),
                toDate: format(selectedDates.endDate, 'yyyy-MM-dd'),
                recipientEmail: recipientEmail,
            })
            .then(() => {
                dispatch(
                    addToast({ message: `Rapporten genereres og vil sendes til ${recipientEmail}`, color: 'success' })
                )
            })
            .catch((error) =>
                dispatch(
                    addToast({
                        message: `Det skjedde en feil: ${error.response.data.message || error.response.data}`,
                        color: 'danger',
                    })
                )
            )
    }

    const handleDateChange = (selectedDates: DateChangeEvent) => {
        setSelectedDates(selectedDates)
    }

    return (
        <Page
            title="Ruteeksport"
            preamble="Denne rapporten inneholder bilnummer og rutenummer for alle aktive ordre til valgte leveringsdager. Rapporten sendes som vedlegg (CSV) til valgt e-postadresse."
        >
            <div className={css.datePickerWrapper}>
                <h2>Velg leveringsdatoer</h2>
                <DatePicker onChange={handleDateChange} initialStartDate={new Date()} initialEndDate={new Date()} />
                <CForm>
                    <CFormLabel htmlFor="email">E-postadresse</CFormLabel>
                    <CFormInput
                        type="email"
                        id="email"
                        value={recipientEmail}
                        onChange={(e) => setRecipientEmail(e.currentTarget.value)}
                    />
                    <CFormText component="p" id="exampleFormControlInputHelpInline">
                        Rapporten sendes til denne e-postadressen
                    </CFormText>
                    <CButton disabled={!recipientEmail} onClick={generateExport}>
                        Generer rapport
                    </CButton>
                </CForm>
            </div>
        </Page>
    )
}

export default RouteExportPage
