import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { OrderMonitoringPhoneNumberTrigger } from '../types'

interface CreateOrderMonitoringPhoneNumberTriggerRequest {
    phoneNumber: string
    description?: string
}

export const orderMonitoringApi = createApi({
    reducerPath: 'orderMonitoring',
    tagTypes: ['OrderMonitoringPhoneNumberTrigger'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getOrderMonitoringPhoneNumberTriggers: builder.query<OrderMonitoringPhoneNumberTrigger[], void>({
            query: () => ({
                url: '/admin/order-monitoring/phone-numbers',
                method: 'GET',
            }),
            providesTags: ['OrderMonitoringPhoneNumberTrigger'],
        }),
        createOrderMonitoringPhoneNumberTrigger: builder.mutation<void, CreateOrderMonitoringPhoneNumberTriggerRequest>(
            {
                query: (request) => ({
                    url: '/admin/order-monitoring/phone-numbers',
                    method: 'POST',
                    body: {
                        phoneNumber: request.phoneNumber,
                        description: request.description,
                    },
                }),
                invalidatesTags: ['OrderMonitoringPhoneNumberTrigger'],
            }
        ),
        deleteOrderMonitoringPhoneNumberTrigger: builder.mutation<void, number>({
            query: (id) => ({
                url: `/admin/order-monitoring/phone-numbers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['OrderMonitoringPhoneNumberTrigger'],
        }),
    }),
})

export const useOrderMonitoringPhoneNumberTriggers = () =>
    orderMonitoringApi.useGetOrderMonitoringPhoneNumberTriggersQuery().data ?? []
export const {
    useCreateOrderMonitoringPhoneNumberTriggerMutation,
    useDeleteOrderMonitoringPhoneNumberTriggerMutation,
} = orderMonitoringApi
