import { CButton } from '@coreui/react-pro'
import { ReactNode } from 'react'
import logo_full from '../assets/images/logo_full.svg'
import { login } from '../services/identity'
import css from './AuthenticatedContent.module.scss'
import { useAuthenticatedUser } from './authenticationApi'

function getErrorFromUrl(): string {
    const hash = window.location.hash
    if (hash.indexOf('#login_error=') === 0) {
        const error = hash.substring(13)
        switch (error) {
            case 'Unauthorized':
                return 'Du har ikke tilgang til applikasjonen'
            default:
                return 'Det skjedde en feil ved innlogging'
        }
    }
    return ''
}

interface AuthenticatedContentProps {
    children: ReactNode
}

export function AuthenticatedContent({ children }: AuthenticatedContentProps) {
    const isLoggedIn = !!useAuthenticatedUser()

    const error = getErrorFromUrl()
    const returnPath = window.location.pathname
    const loginView = (
        <div className={css.loginBox}>
            <img src={logo_full} />
            <CButton color={'dark'} variant={'outline'} onClick={() => login(returnPath)}>
                Logg inn med Schibsted Account
            </CButton>
            {error ? <p className={css.error}>{error}</p> : <></>}
        </div>
    )

    return isLoggedIn ? <>{children}</> : loginView
}
