import { BarcodeType, RegisteredProduct } from '../types'
import React, { FormEvent, useEffect, useState } from 'react'
import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'
import { CButton, CCardBody, CForm } from '@coreui/react-pro'
import { useUpdateProductMutation } from '../store/productApi'
import { useDispatch } from 'react-redux'
import { addToast } from '../../common/store/commonSlice'
import Barcode from 'react-barcode'
import { Result, useZxing } from 'react-zxing'
import { cilBarcode, cilPencil, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Input, Select } from '../../common/components/Input/Input'

export default function ProductBarcodeCard({ product }: { product: RegisteredProduct }) {
    const [barcodeValue, setBarcodeValue] = useState<string | undefined>(product.barcode?.value)
    const [barcodeType, setBarcodeType] = useState<BarcodeType | undefined>(product.barcode?.type)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [updateProduct, updateProductResult] = useUpdateProductMutation()
    const dispatch = useDispatch()

    const isAnyChanges = barcodeValue !== product.barcode?.value || barcodeType !== product.barcode?.type

    useEffect(() => {
        if (updateProductResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produktet ble oppdatert',
                    color: 'success',
                })
            )
            setIsEditing(false)
        }

        if (updateProductResult.isError) {
            dispatch(
                addToast({
                    message: 'Kunne ikke oppdatere produktet: ' + updateProductResult.error,
                    color: 'danger',
                })
            )
        }
    }, [updateProductResult])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!barcodeValue || !barcodeType) {
            dispatch(
                addToast({
                    message: 'Både strekkodeverdi og strekkodetype må være satt',
                    color: 'danger',
                })
            )
            return
        }

        const updatedProduct = {
            ...product,
            barcode: {
                value: barcodeValue,
                type: barcodeType,
            },
        }

        updateProduct({ productId: product.id, data: updatedProduct })
    }

    const handleScan = (scan: { type: string; code: string }) => {
        setBarcodeType(scan.type as BarcodeType)
        setBarcodeValue(scan.code)
    }

    const cancelChanges = () => {
        setBarcodeValue(product.barcode?.value)
        setBarcodeType(product.barcode?.type)
    }

    const handleCardActionClick = () => {
        if (isEditing && isAnyChanges) {
            if (!window.confirm('Du har ulagrede endringer. Er du sikker på at du vil avbryte?')) {
                return
            }
            cancelChanges()
        }
        setIsEditing(!isEditing)
    }

    return (
        <Card onAction={handleCardActionClick} actionIcon={isEditing ? cilX : cilPencil}>
            <CardHeader>
                <CIcon icon={cilBarcode} size={'xl'} className={'mr-1'} />
                Strekkode
            </CardHeader>
            <CCardBody>
                <div className={'d-flex justify-content-center mb-3'}>
                    {isEditing && <BarcodeScanner onScan={handleScan} />}
                    {!isEditing && (
                        <Barcode value={barcodeValue ? barcodeValue : '0'} format={barcodeType} height={100} />
                    )}
                </div>
                <CForm onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <Select
                            label={'Strekkodetype'}
                            id="productGroup"
                            options={[
                                { value: undefined, label: 'Velg strekkodetype' },
                                { value: 'EAN13', label: 'EAN 13' },
                                { value: 'EAN8', label: 'EAN 8' },
                                { value: 'UPC', label: 'UPC' },
                                { value: 'CODE128', label: 'CODE 128' },
                            ]}
                            value={barcodeType}
                            onChange={(e) => setBarcodeType(e.target.value as BarcodeType)}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            id="barCode"
                            label={'Kode'}
                            value={barcodeValue}
                            onChange={(e) => setBarcodeValue(e.target.value)}
                            disabled={!isEditing}
                        />
                    </div>
                    {isEditing && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CButton
                                type="submit"
                                color="primary"
                                size="sm"
                                disabled={updateProductResult.isLoading || !isAnyChanges}
                            >
                                {'Lagre'}
                            </CButton>
                        </div>
                    )}
                </CForm>
            </CCardBody>
        </Card>
    )
}

interface BarcodeScannerProps {
    onScan: (scan: { type: string; code: string }) => void
}

function BarcodeScanner({ onScan }: BarcodeScannerProps) {
    const [scan, setScan] = React.useState<Result | undefined>()
    const dispatch = useDispatch()

    // Duplisert fra xzing, da vi ikke kunne importere
    enum BarcodeFormat {
        AZTEC = 0,
        CODABAR = 1,
        CODE_39 = 2,
        CODE_93 = 3,
        CODE_128 = 4,
        DATA_MATRIX = 5,
        EAN_8 = 6,
        EAN_13 = 7,
        ITF = 8,
        MAXICODE = 9,
        PDF_417 = 10,
        QR_CODE = 11,
        RSS_14 = 12,
        RSS_EXPANDED = 13,
        UPC_A = 14,
        UPC_E = 15,
        UPC_EAN_EXTENSION = 16,
    }

    function getKeyByValue(value: number, enumObj: any): string | undefined {
        return Object.keys(enumObj).find((key) => enumObj[key] === value)
    }

    const { ref } = useZxing({
        onDecodeResult(newScan) {
            setScan(newScan)
        },
    })

    useEffect(() => {
        if (scan?.getBarcodeFormat() && scan?.getText()) {
            const format = getKeyByValue(scan?.getBarcodeFormat(), BarcodeFormat)?.replace(/_/g, '')
            if (!format) {
                dispatch(
                    addToast({
                        message: 'Ugyldig strekkodeformat',
                        color: 'danger',
                    })
                )
            } else {
                onScan({ type: format, code: scan.getText() })
            }
        }
    }, [scan])

    return (
        <>
            <video width="300" ref={ref} />
        </>
    )
}
