import {
    CButton,
    CFormInput,
    CFormTextarea,
    CInputGroup,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CMultiSelect,
    CSmartTable,
    CSpinner,
} from '@coreui/react-pro'
import {
    CreateRefundRequest,
    RefundItem,
    useGetPaymentByOrderIdQuery,
    useGetRefundCodesQuery,
    useRequestRefundMutation,
} from '../store/paymentAdminApi'
import { useGetOrderQuery } from '../store/orderAdminApi'
import React, { useState } from 'react'
import { PaymentOrderLine } from '../types'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

interface CreateRefundModalProps {
    visible: boolean
    onClose: () => void
    orderId: string
}

export default function CreateRefundModal({ visible, onClose, orderId }: CreateRefundModalProps) {
    const { data: order, isLoading: loadingOrder } = useGetOrderQuery(orderId)
    const { data: payment, isLoading } = useGetPaymentByOrderIdQuery(orderId)
    const { data: refundCodes } = useGetRefundCodesQuery()
    const [refundItems, setRefundItems] = useState<RefundItem[]>([])
    const [refundCode, setRefundCode] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [createRefund] = useRequestRefundMutation()
    const dispatch = useDispatch()
    const handleUpdateRefundAmount = (itemId: string, amount: number) => {
        const itemExists = refundItems.find((item) => item.itemReference === itemId)
        const updatedItems = itemExists
            ? refundItems.map((item) =>
                  item.itemReference === itemId
                      ? ({
                            itemReference: itemId,
                            amount: amount,
                        } as RefundItem)
                      : item
              )
            : [...refundItems, { itemReference: itemId, amount: amount } as RefundItem]
        setRefundItems(updatedItems)
    }
    const handleCreateRefund = () => {
        if (payment) {
            const refundRequest: CreateRefundRequest = {
                paymentId: payment.paymentId,
                refundCode: refundCode,
                message: message,
                refundItems: refundItems,
            }
            executeMutation(createRefund(refundRequest), dispatch, 'Refusjon opprettet', 'Kunne ikke opprette refusjon')
        } else {
            alert('Kan ikke opprette refusjon uten betaling')
        }
    }

    const groupedRefunds = [] as { refundId: number; message: string; creditedAmount: number; isPending: boolean }[]

    payment &&
        payment.lines.forEach((line) => {
            line.refunds.forEach((refund) => {
                const { refundId, message, creditedAmount, isPending } = refund

                // Find if there's already an entry for the same refundId in the flattenedRefunds array
                const existingRefund = groupedRefunds.find((item) => item.refundId === refundId)

                if (existingRefund) {
                    // If found, accumulate the creditedAmount
                    existingRefund.creditedAmount += creditedAmount
                } else {
                    // If not found, create a new object and push it into the array
                    groupedRefunds.push({
                        refundId,
                        message,
                        creditedAmount,
                        isPending,
                    })
                }
            })
        })

    return (
        <CModal visible={visible} onClose={onClose} title="Velg type" size={'xl'}>
            <CModalHeader closeButton={true}>
                <CModalTitle id="LiveDemoExampleLabel">Opprett refusjon</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {loadingOrder || (isLoading && <CSpinner />)}
                {refundCodes && (
                    <CMultiSelect
                        className={'mb-3'}
                        label={'Refusjonskode'}
                        multiple={false}
                        options={refundCodes.map((code) => ({
                            value: code.code,
                            text: `${code.code} - ${code.description}`,
                        }))}
                        onChange={(value) => setRefundCode(value[0].value.toString())}
                        invalid={refundCode == ''}
                        feedbackInvalid={'Du må velge en refusjonskode'}
                    />
                )}
                <CFormTextarea
                    label={'Melding'}
                    className={'mb-3'}
                    value={message}
                    invalid={message == ''}
                    onChange={(e) => setMessage(e.target.value)}
                ></CFormTextarea>
                {order && payment && (
                    <>
                        <CSmartTable
                            items={payment.lines}
                            selectable
                            columns={[
                                {
                                    key: 'title',
                                    label: 'Tittel',
                                },
                                {
                                    key: 'quantity',
                                    label: 'Antall',
                                },
                                {
                                    key: 'pricePerItemIncVatAndDiscount',
                                    label: 'Enhetspris',
                                },
                                {
                                    key: 'grossTotal',
                                    label: 'Betalt',
                                },
                                {
                                    key: 'refundedAmount',
                                    label: 'Refundert',
                                },
                                {
                                    key: 'refundAmount',
                                    label: 'Refunder',
                                    _style: { width: '10%' },
                                },
                            ]}
                            onSelectedItemsChange={(items) => {
                                setRefundItems(
                                    items.map((item) => ({
                                        itemReference: item.itemReference,
                                        amount: item.grossTotal,
                                    }))
                                )
                            }}
                            scopedColumns={{
                                title: (row: PaymentOrderLine) => {
                                    const parsed = parseItemReference(row.itemReference)
                                    if (parsed.bundleId) {
                                        const bundleLine = order.orderLines.find(
                                            (line) => line.productItemId == parseInt(parsed.bundleId as string)
                                        )
                                        const productLine = bundleLine?.bundleLines?.find(
                                            (line) => line.productItemId == parseInt(parsed.productId as string)
                                        )
                                        return (
                                            <td>
                                                <em>{bundleLine?.title}</em> - {productLine?.title}
                                            </td>
                                        )
                                    } else {
                                        const line = order.orderLines.find(
                                            (line) => line.productItemId == parseInt(parsed.productId as string)
                                        )
                                        return (
                                            <td>
                                                {line?.title}
                                                <br />
                                            </td>
                                        )
                                    }
                                },
                                refundAmount: (row: PaymentOrderLine) => (
                                    <td>
                                        <CInputGroup>
                                            <CFormInput
                                                id={row.itemReference}
                                                size={'sm'}
                                                type={'text'}
                                                defaultValue={0}
                                                value={
                                                    refundItems.find((item) => item.itemReference === row.itemReference)
                                                        ?.amount || 0
                                                }
                                                invalid={
                                                    (refundItems.find(
                                                        (item) => item.itemReference === row.itemReference
                                                    )?.amount || 0) >
                                                    row.grossTotal - row.refundedAmount
                                                }
                                                feedbackInvalid={'Beløpet kan ikke være høyere enn betalt'}
                                                onChange={(e) =>
                                                    handleUpdateRefundAmount(
                                                        row.itemReference.toString(),
                                                        Number(e.target.value)
                                                    )
                                                }
                                            />
                                        </CInputGroup>
                                    </td>
                                ),
                            }}
                        />
                    </>
                )}
                <p>
                    <strong>Totalt å refundere: {refundItems.reduce((acc, item) => acc + item.amount, 0)}</strong>
                </p>
                <h5>Eksisterende refusjoner</h5>

                <ul>
                    {groupedRefunds.map((refund) => (
                        <li key={refund.refundId}>
                            {refund.message}: {refund.creditedAmount},- {refund.isPending && '(På vent)'}
                        </li>
                    ))}
                </ul>
            </CModalBody>
            <CModalFooter>
                <CButton color="danger" onClick={() => setRefundItems([])}>
                    Tøm
                </CButton>
                <CButton color="primary" onClick={handleCreateRefund}>
                    Opprett refusjon
                </CButton>
            </CModalFooter>
        </CModal>
    )
}

function parseItemReference(str: string) {
    const orderMatch = str.match(/O(\d+)/) // Match number after 'O'
    const productMatch = str.match(/P(\d+)/) // Match number after 'P'
    const bundleMatch = str.match(/B(\d+)/) // Match number after 'B'

    return {
        orderId: orderMatch ? orderMatch[1] : undefined,
        productId: productMatch ? productMatch[1] : undefined,
        bundleId: bundleMatch ? bundleMatch[1] : undefined,
    }
}
