import { Page } from '../../common'
import { regionApi } from '../store/regionApi'
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CCol, CRow } from '@coreui/react-pro'
import { RegionInfo } from '../components/RegionInfo/RegionInfo'
import { RegionSettings } from '../components/RegionSettings/RegionSettings'

export default function RegionsPage() {
    const { data: enabledRegions } = regionApi.useGetRegionsQuery()

    return (
        <Page title={'Regioner'} preamble={'Her kan du se og redigere regioner'}>
            <CAccordion>
                {enabledRegions?.map((region) => (
                    <CAccordionItem key={region.id} itemKey={region.id}>
                        <CAccordionHeader>{region.name}</CAccordionHeader>
                        <CAccordionBody>
                            <CRow xs={{ gutter: 5 }}>
                                <CCol md={6}>
                                    <RegionInfo region={region} />
                                </CCol>
                                <CCol>
                                    <RegionSettings region={region} />
                                </CCol>
                            </CRow>
                        </CAccordionBody>
                    </CAccordionItem>
                ))}
            </CAccordion>
        </Page>
    )
}
