import React, { useEffect, useState } from 'react'
import locale from 'date-fns/locale/nb'
import { DateRange } from 'react-date-range'
import moment from 'moment'
import './DatePicker.scss'

interface SelectedDates {
    startDate: Date | undefined
    endDate: Date | undefined
    key: string
}

export interface DateChangeEvent {
    startDate: Date
    endDate: Date
}

interface DatePickerProps {
    onChange: (dateChange: DateChangeEvent) => any
    initialStartDate?: Date
    initialEndDate?: Date
}

export function DatePicker({ onChange, initialStartDate, initialEndDate }: DatePickerProps) {
    const handleSelect = (ranges: any) => {
        setSelectedDates({
            startDate: ranges[selectedDates.key].startDate,
            endDate: ranges[selectedDates.key].endDate,
            key: selectedDates.key,
        })
    }

    const [selectedDates, setSelectedDates] = useState<SelectedDates>({
        startDate: initialStartDate || moment().startOf('week').toDate(),
        endDate: initialEndDate || moment().endOf('week').toDate(),
        key: 'selection',
    })

    useEffect(() => {
        onChange({
            startDate: selectedDates.startDate ? selectedDates.startDate : new Date(),
            endDate: selectedDates.endDate ? selectedDates.endDate : new Date(),
        })
    }, [selectedDates])

    return (
        <div className="datePickerWrapper">
            <DateRange locale={locale} ranges={[selectedDates]} onChange={handleSelect} weekStartsOn={1} />
        </div>
    )
}
