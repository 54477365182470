import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import React from 'react'
import { Payment } from '../types'

interface PaymentDetailsProps {
    payment: Payment
}

function PaymentInfoTable({ payment }: PaymentDetailsProps) {
    return (
        <CTable>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>Info</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                <CTableRow>
                    <CTableDataCell>Fakturanummer</CTableDataCell>
                    <CTableDataCell>{payment.invoiceNumber}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                    <CTableDataCell>Betalingsmetode</CTableDataCell>
                    <CTableDataCell>{payment.psp}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                    <CTableDataCell>Feilkode</CTableDataCell>
                    <CTableDataCell>{payment.failureCode}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                    <CTableDataCell>Netto betalt</CTableDataCell>
                    <CTableDataCell>{payment.netAmount},-</CTableDataCell>
                </CTableRow>
                <CTableRow>
                    <CTableDataCell>Moms betalt</CTableDataCell>
                    <CTableDataCell>{payment.vatAmount},-</CTableDataCell>
                </CTableRow>
            </CTableBody>
        </CTable>
    )
}

export default PaymentInfoTable
