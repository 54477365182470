import { BundleAvailability } from '../store/inventoryApi'
import { Region } from '../../region/types'
import { CFormSwitch, CSmartTable } from '@coreui/react-pro'
import format from 'date-fns/format'
import { nb } from 'date-fns/locale'
import ProgressBar, { ProgressBarType } from '../../common/components/ProgressBar/ProgressBar'
import { useState } from 'react'
import { isPastDate } from '../../common/utils/dateUtils'

export function BundleProductAvailabilities({ item, regions }: { item: BundleAvailability; regions: Region[] }) {
    const [showAll, setShowAll] = useState(false)

    // map availability to be grouped by date
    const mappedAvailability: Record<string, Record<string, number>> = {}
    if (item.data) {
        for (const regionId in item.data) {
            for (const date in item.data[regionId].bundle) {
                if (!mappedAvailability[date]) {
                    mappedAvailability[date] = {}
                }
                mappedAvailability[date][regionId] = item.data[regionId].bundle[date]
            }
        }
    }

    const filteredAvailabilities = Object.entries(mappedAvailability).filter(([date]) => {
        return showAll || !isPastDate(date)
    })

    return (
        <div>
            <CFormSwitch
                label={'Vis historikk'}
                checked={showAll}
                onClick={(event) => {
                    event.stopPropagation()
                    setShowAll(!showAll)
                }}
            />
            <CSmartTable
                itemsPerPage={100}
                items={filteredAvailabilities}
                tableProps={{
                    responsive: true,
                    striped: true,
                    hover: true,
                }}
                columns={[
                    {
                        key: '0',
                        label: 'Dato',
                        _style: { width: '15%' },
                    },
                    {
                        key: '1',
                        label: 'Dag',
                        _style: { width: '10%' },
                    },
                    {
                        key: '',
                        children: regions.map((region) => {
                            return {
                                key: region.name,
                                label: region.name,
                                _props: { scope: 'regions' },
                                _style: { width: `${75 / regions.length}%` },
                            }
                        }),
                    },
                ]}
                scopedColumns={{
                    0: (item: [string, number]) => {
                        const date = format(new Date(item[0]), 'd. MMMM', { locale: nb })
                        const year = format(new Date(item[0]), 'yyyy')
                        return (
                            <td className={'font-monospace h-100 align-middle'}>
                                <span>{date}</span>

                                <br />
                                <span style={{ fontSize: '12px', color: '#6c757d' }}>{year}</span>
                            </td>
                        )
                    },
                    1: (item: [string, number]) => {
                        const day = new Date(item[0]).toLocaleDateString('nb-NO', {
                            weekday: 'long',
                        })
                        return <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                    },
                    ...Object.fromEntries(
                        regions.map((region) => {
                            return [
                                region.name,
                                (item: [string, number[]]) => {
                                    const theItem = item[1]
                                    const quantity = theItem?.[region.id] ?? 0
                                    return (
                                        <td
                                            style={{
                                                padding: '10px',
                                            }}
                                        >
                                            <div>
                                                <ProgressBar
                                                    progress={100}
                                                    type={
                                                        quantity == 0
                                                            ? ProgressBarType.Disabled
                                                            : ProgressBarType.Default
                                                    }
                                                    text={quantity.toString()}
                                                />
                                            </div>
                                        </td>
                                    )
                                },
                            ]
                        })
                    ),
                }}
            />
        </div>
    )
}
