import Barcode from 'react-barcode'
import React from 'react'
import { Input, Select } from '../../common/components/Input/Input'
import { BarcodeType } from '../../product/types'
import { CButton, CCol, CRow } from '@coreui/react-pro'

const BarcodeGenerator = ({
    index,
    onHover,
    isHovered,
}: {
    index: number
    onHover: (index: number | null) => any
    isHovered: boolean
}) => {
    const [barcodeValue, setBarcodeValue] = React.useState<string>('0')
    const [barcodeType, setBarcodeType] = React.useState<BarcodeType | undefined>(undefined)

    return (
        <div className={'mt-1'} onMouseEnter={() => onHover(index)} onMouseLeave={() => onHover(null)}>
            <CRow>
                <CCol>
                    <Input label={'Kode'} value={barcodeValue} onChange={(e) => setBarcodeValue(e.target.value)} />

                    <div className="mb-3">
                        <Select
                            label={'Strekkodetype'}
                            id="productGroup"
                            options={[
                                { value: 'EAN13', label: 'EAN 13' },
                                { value: 'EAN8', label: 'EAN 8' },
                                { value: 'UPC', label: 'UPC' },
                                { value: 'CODE128', label: 'CODE 128' },
                            ]}
                            value={barcodeType}
                            onChange={(e) => setBarcodeType(e.target.value as BarcodeType)}
                        />
                    </div>
                </CCol>
                <CCol lg={8}>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <div
                            style={{
                                border: '1px solid #ccc',
                                opacity: isHovered ? 1 : 0,
                            }}
                        >
                            <Barcode value={barcodeValue} format={barcodeType} height={100} />
                        </div>
                    </div>
                </CCol>
            </CRow>
        </div>
    )
}

export default function BarcodeGeneratorPage() {
    const [numberOfGeneratedBarcodes, setNumberOfGeneratedBarcodes] = React.useState<number>(1)
    const [hoveredBarcode, setHoveredBarcode] = React.useState<number | null>(null)

    const handleHover = (index: number | null) => {
        setHoveredBarcode(index)
    }

    return (
        <div className={'p-4'}>
            <CRow>
                <CCol>
                    <h1>Strekkodegenerator</h1>
                    <p>Her kan du generere strekkoder for enkel testing med BitLog</p>
                </CCol>
                <CCol lg={2}>
                    <CButton onClick={() => setNumberOfGeneratedBarcodes(numberOfGeneratedBarcodes + 1)}>
                        Legg til strekkode
                    </CButton>
                </CCol>
            </CRow>
            {Array.from({ length: numberOfGeneratedBarcodes }).map((_, index) => (
                <BarcodeGenerator
                    key={index}
                    index={index}
                    onHover={handleHover}
                    isHovered={hoveredBarcode === index || hoveredBarcode === null}
                />
            ))}
        </div>
    )
}
