import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WeekSelector } from '../../../common'
import { IRootState } from '../../../store'
import { useDistributionCompanies } from '../../store/distributionApi'
import { CapacityUsageView, CompanyCapacityUsage, fetchCapacityUsageView } from '../../store/distributionCalendarSlice'
import Capacity from '../Capacity/Capacity'
import css from './DistributionCalendar.module.scss'

interface DistributionCalendarProps {
    initialDate: moment.Moment
}

function DistributionCalendar({ initialDate }: DistributionCalendarProps) {
    const dispatch = useDispatch()

    const [currentWeekStart, setWeekStart] = useState(initialDate.clone().startOf('week'))

    const start = currentWeekStart.clone().subtract(1, 'day')

    useEffect(() => {
        dispatch(
            fetchCapacityUsageView({
                fromDate: currentWeekStart.format('yyyy-MM-DD'),
                toDate: currentWeekStart.clone().endOf('week').format('yyyy-MM-DD'),
            })
        )
    }, [currentWeekStart])

    return (
        <div className={css.calendar}>
            <div className={css.toolbar}>
                <WeekSelector
                    onNext={() => setWeekStart(currentWeekStart.clone().add(1, 'week'))}
                    onPrev={() => setWeekStart(currentWeekStart.clone().subtract(1, 'week'))}
                    weekStart={currentWeekStart}
                />
                <button className={css.resetButton} onClick={() => setWeekStart(moment().startOf('week'))}>
                    I dag
                </button>
            </div>
            <Week>
                {Array(7)
                    .fill(0)
                    .map(() => start.add(1, 'day').clone())
                    .map((d) => (
                        <Day key={d.dayOfYear()} day={d} />
                    ))}
            </Week>
        </div>
    )
}

interface WeekProps {
    children: JSX.Element[]
}

function Week({ children }: WeekProps) {
    return <div className={css.week}>{children}</div>
}

interface DayProps {
    day: moment.Moment
}

export function Day({ day }: DayProps) {
    const distributionCompanies = useDistributionCompanies()

    const distributionCapacities = useSelector<IRootState, CapacityUsageView[]>(
        (state) => state.distributionCalendar.capacityUsageView
    )
    const currentDayCompanies = distributionCapacities.find((v) => v.date == day.format('yyyy-MM-DD'))

    function getCompanyName(capacityUsageView: CompanyCapacityUsage): string {
        const distributionCompany = distributionCompanies.find((d) => d.id === capacityUsageView.distributionCompanyId)
        if (distributionCompany) {
            return distributionCompany.name
        }
        return capacityUsageView.distributionCompanyId + ''
    }

    return (
        <div className={css.day}>
            <div className={'dayName'}>{day.format('dddd')}</div>
            <div className={'date'}>{day.format('Do MMMM')}</div>
            {currentDayCompanies && (
                <>
                    {currentDayCompanies.companies.map((dc) => {
                        return (
                            <Capacity
                                key={dc.distributionCompanyId}
                                label={getCompanyName(dc)}
                                total={dc.capacity}
                                used={dc.confirmedReservationsSum}
                            />
                        )
                    })}
                    <Capacity
                        className={css.totalCapacity}
                        label="Totalt"
                        total={currentDayCompanies.companies.reduce((prev, current) => prev + current.capacity, 0)}
                        used={currentDayCompanies.companies.reduce(
                            (prev, current) => prev + current.confirmedReservationsSum,
                            0
                        )}
                    />
                </>
            )}
        </div>
    )
}

export default DistributionCalendar
