import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { Dispatch } from 'redux'
import { apiUrl } from '../../config'
import { addToast } from '../store/commonSlice'
import { ErrorResponse } from '../types'
import { login } from '../../services/identity'

export function getAuthorizedBaseQuery() {
    return getBaseQueryWithReauth(true)
}

export function getUnauthorizedBaseQuery() {
    return getBaseQueryWithReauth(false)
}

export function executeMutation(
    mutationPromise: Promise<any>,
    dispatch: Dispatch,
    successMessage: string,
    errorMessage: string,
    finallyFunc?: () => void,
    successFunc?: () => void,
    errorFunc?: () => void
) {
    return mutationPromise
        .then((result) => {
            if ('error' in result) {
                dispatch(
                    addToast({
                        message: errorMessage,
                        color: 'danger',
                    })
                )
                if (errorFunc) {
                    errorFunc()
                }
            } else {
                dispatch(
                    addToast({
                        message: successMessage,
                        color: 'success',
                    })
                )
                if (successFunc) {
                    successFunc()
                }
            }
        })
        .catch(() => {
            dispatch(
                addToast({
                    message: errorMessage,
                    color: 'danger',
                })
            )
            if (errorFunc) {
                errorFunc()
            }
        })
        .finally(() => {
            if (finallyFunc) {
                finallyFunc()
            }
        })
}

function getBaseQuery(useAuth: boolean) {
    return fetchBaseQuery({
        baseUrl: apiUrl,
        headers: {
            Accept: 'application/json',
        },
        prepareHeaders: (headers) => {
            if (useAuth) {
                const token = localStorage.getItem('token')
                if (token) {
                    headers.set('authorization', `Bearer ${token}`)
                }
            }
            return headers
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, ErrorResponse, Record<string, unknown>>
}

function getBaseQueryWithReauth(
    useAuth: boolean
): BaseQueryFn<string | FetchArgs, unknown, ErrorResponse, Record<string, unknown>> {
    const baseQuery = getBaseQuery(useAuth)

    return async (args: string | FetchArgs, api, extraOptions) => {
        const result = await baseQuery(args, api, extraOptions)
        const currentPath = window.location.pathname
        if (result.error && result.error.originalStatus === 401) {
            // Unauthorized error detected; call login function
            login(currentPath) // Pass the intended return path for post-login redirect
        }

        return result
    }
}
