import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'

export const regionAdminApi = createApi({
    reducerPath: 'regionAdmin',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['RegionFeatures'],
    endpoints: (builder) => ({
        getRegionFeature: builder.query<{ enabled: boolean }, { regionId: number; feature: string }>({
            query: ({ regionId, feature }) => ({
                url: `/admin/regions/${regionId}/features/${feature}`,
                method: 'GET',
            }),
            providesTags: ['RegionFeatures'],
        }),
        setRegionFeature: builder.mutation<void, { regionId: number; feature: string; enabled: boolean }>({
            query: ({ regionId, feature, enabled }) => ({
                url: `/admin/regions/${regionId}/features/${feature}`,
                method: 'PUT',
                body: { enabled },
            }),
            invalidatesTags: ['RegionFeatures'],
        }),
        deactivateBitlogWms: builder.mutation<void, { regionId: number; username: string; password: string }>({
            query: ({ regionId, username, password }) => ({
                url: `wms/regions/${regionId}/deactivate`,
                method: 'POST',
                body: {
                    username,
                    password,
                },
            }),
            invalidatesTags: ['RegionFeatures'],
        }),
        setDefaultPackageLocation: builder.mutation<void, { regionId: number; packageLocationName: string }>({
            query: ({ regionId, packageLocationName }) => ({
                url: `/admin/regions/${regionId}/default-packagelocation`,
                method: 'POST',
                body: { packageLocation: packageLocationName },
            }),
        }),
    }),
})
