import { useGetSupplier2Query } from '../store/supplierApi'
import { useParams } from 'react-router'
import { Page } from '../../common'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import {
    CButton,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import { useRegions } from '../../region/store/regionApi'
import AddSupplierRegionModal from '../components/AddSuplierRegionModal'
import React, { useState } from 'react'
import SupplierDetailsToolbar from '../components/SupplierDetailsToolbar'

export default function SupplierDetailsPage2() {
    const { supplierId } = useParams<{ supplierId: string }>()
    const regions = useRegions()
    const { data: supplier, isSuccess, isLoading, isError, error } = useGetSupplier2Query(supplierId)
    const [showAddRegionModal, setShowAddRegionModal] = useState(false)

    return (
        <Page title={supplier?.name || 'N/A'} preamble={supplier?.id}>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && supplier && (
                <>
                    <SupplierDetailsToolbar supplier={supplier} />
                    <p>{supplier.description}</p>
                    <CTable>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell>Region</CTableHeaderCell>
                                <CTableHeaderCell>Pakksted</CTableHeaderCell>
                                <CTableHeaderCell>Kontakter</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {supplier.regions.map((supplierRegion) => (
                                <CTableRow key={supplierRegion.regionId}>
                                    <CTableDataCell>
                                        {regions.find((r) => r.id == supplierRegion.regionId)?.name}
                                    </CTableDataCell>
                                    <CTableDataCell>{supplierRegion.packageLocation}</CTableDataCell>
                                    <CTableDataCell>
                                        <dl>
                                            {supplierRegion.contactInfos.map((contactInfo) => (
                                                <>
                                                    <dt>{contactInfo.name}</dt>
                                                    <dd>{contactInfo.email}</dd>
                                                </>
                                            ))}
                                        </dl>
                                    </CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                    <CButton
                        color={'success'}
                        onClick={() => {
                            setShowAddRegionModal(true)
                        }}
                    >
                        Legg til region
                    </CButton>
                    <AddSupplierRegionModal
                        show={showAddRegionModal}
                        supplier={supplier}
                        onClose={() => setShowAddRegionModal(false)}
                    />
                </>
            )}
        </Page>
    )
}
