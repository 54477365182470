import { RegisteredProduct } from '../types'
import { useExportProductMutation, useRefreshProductMutation } from '../store/productApi'
import { useDispatch } from 'react-redux'
import { executeMutation } from '../../common/utils/apiUtils'
import ProductStatusDropdown from './ProductStatusDropdown'
import { CButton } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilExternalLink, cilTransfer, cilVerticalAlignTop } from '@coreui/icons'
import React from 'react'
import { craftUrl } from '../../config'
import Toolbar from '../../common/components/Toolbar'
import { cilMoney } from '@coreui/icons/js/free'
import { AuthorizedContent } from '../../auth/AuthorizedContent'

interface ProductDetailsToolbarProps {
    product: RegisteredProduct
}

export default function ProductDetailsToolbar({ product }: ProductDetailsToolbarProps) {
    const [refreshProduct, { isLoading: isSyncing }] = useRefreshProductMutation()
    const [exportProduct, { isLoading: isExporting }] = useExportProductMutation()
    const dispatch = useDispatch()

    const handleRefresh = () => {
        executeMutation(
            refreshProduct({ productId: product.id }),
            dispatch,
            'Ny produktinfo hentet',
            'Kunne ikke oppfriske produktet',
            () => {}
        )
    }
    const handleExport = () => {
        executeMutation(
            exportProduct({ productId: product.id }),
            dispatch,
            'Produkt eksporteret',
            'Kunne ikke eksportere produktet',
            () => {}
        )
    }
    return (
        <Toolbar>
            <div className={'ml-auto mb-3 mr-1'}>
                <ProductStatusDropdown productId={product.id} status={product.status} />
            </div>
            <div className={'mr-1'}>
                <CButton
                    as={'a'}
                    href={`${craftUrl}/admin/entries/groceries/${product.externalProductId}`}
                    target={'_blank'}
                    type="button"
                    color="secondary"
                    variant="outline"
                    id="button-addon1"
                >
                    <CIcon className={'mr-1'} icon={cilExternalLink} size="sm" />
                    Åpne i Craft
                </CButton>
            </div>
            <div className={'mr-1'}>
                <CButton disabled={isSyncing} onClick={handleRefresh}>
                    <CIcon className={'mr-1'} icon={cilTransfer} size="sm" />
                    {isSyncing ? 'Laster...' : 'Synkroniser med Craft'}
                </CButton>
            </div>
            <div>
                <CButton
                    variant={'outline'}
                    disabled={isExporting || product.bundleProducts.length > 0}
                    onClick={handleExport}
                >
                    <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
                    {isExporting ? 'Eksporterer...' : 'Eksporter til WMS'}
                </CButton>
            </div>
            <AuthorizedContent requiredPermissions={['purchaseorders.write']}>
                <div className={'mr-1 ml-1'}>
                    <CButton
                        as={'a'}
                        href={`/innkjøp/ny?supplier=${product.supplierId}`}
                        type="button"
                        variant="outline"
                        disabled={product.bundleProducts.length > 0}
                    >
                        <CIcon className={'mr-1'} icon={cilMoney} size="sm" />
                        Opprett innkjøpsordre
                    </CButton>
                </div>
            </AuthorizedContent>
        </Toolbar>
    )
}
