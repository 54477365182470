import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../common/utils/apiUtils'
import { User } from './types'

export const authenticationApi = createApi({
    reducerPath: 'authentication',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getUser: builder.query<User, void>({
            query: () => ({
                url: '/admin/auth/user',
                method: 'GET',
            }),
        }),
    }),
})

export const useAuthenticatedUser = () => authenticationApi.useGetUserQuery().data
