import { PurchaseOrder } from '../types'
import moment from 'moment/moment'
import { purchaseOrderStatusText } from '../util'
import React from 'react'
import { CSmartTable } from '@coreui/react-pro'
import { useHistory } from 'react-router-dom'

interface PurchaseOrderTableProps {
    purchaseOrders: PurchaseOrder[]
}

export const PurchaseOrderTable = ({ purchaseOrders }: PurchaseOrderTableProps) => {
    const history = useHistory()
    const sortedOrders = [...purchaseOrders].sort((a, b) => {
        return a.orderDate > b.orderDate ? -1 : 1
    })
    return (
        <CSmartTable
            items={sortedOrders}
            clickableRows
            columnSorter
            columnFilter
            onRowClick={(item) => {
                history.push(`/innkjøp/${item.orderNo}`)
            }}
            pagination
            itemsPerPage={20}
            columns={[
                {
                    key: 'orderNo',
                    label: 'Ordrenummer',
                },
                {
                    key: 'supplierName',
                    label: 'Leverandør',
                },
                {
                    key: 'orderDate',
                    label: 'Dato',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
            ]}
            scopedColumns={{
                orderDate: (item: PurchaseOrder) => (
                    <td>{item.orderDate ? moment(item.orderDate).format('LL') : ''}</td>
                ),
                status: (item: PurchaseOrder) => <td>{purchaseOrderStatusText(item.status)}</td>,
            }}
        />
    )
}
