import { Page } from '../../common'
import { CButton, CCol, CRow, CSpinner } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import React from 'react'
import { purchaseOrderApi } from '../store/purchaseOrderApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { useHistory } from 'react-router-dom'
import { PurchaseOrderTable } from '../components/PurchaseOrderTable'
import { AuthorizedContent } from '../../auth/AuthorizedContent'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'

export default function PurchaseOrderListPage() {
    const { data: purchaseOrders, isLoading, isSuccess, isError, error } = purchaseOrderApi.useGetPurchaseOrdersQuery()
    const history = useHistory()

    const isWriteAuthorized = userHasSomePermissions(useAuthenticatedUser(), 'purchaseorders.write')

    const handleCreatePurchaseOrder = () => {
        history.push('/innkjøp/ny')
    }

    return (
        <Page title={'Innkjøpsordre (for WMS)'}>
            <CRow>
                <CCol
                    sm={12}
                    md={6}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <p>
                        {isWriteAuthorized ? 'Her kan du se og opprette innkjøpsordre' : 'Her kan du se innkjøpsordre'}
                    </p>
                </CCol>
                <AuthorizedContent requiredPermissions={['purchaseorders.write']}>
                    <CCol
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CButton
                            className={'mb-3 mr-1'}
                            color={'primary'}
                            onClick={handleCreatePurchaseOrder}
                            disabled={isLoading}
                        >
                            <CIcon className={'mr-1'} icon={cilPlus} size="sm" />
                            Opprett innkjøpsordre
                        </CButton>
                    </CCol>
                </AuthorizedContent>
            </CRow>
            {isError && <ErrorMessage error={error} />}
            {isLoading && <CSpinner />}
            {(isSuccess && purchaseOrders?.length && <PurchaseOrderTable purchaseOrders={purchaseOrders} />) || null}
        </Page>
    )
}
