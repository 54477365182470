import { executeMutation } from '../../common/utils/apiUtils'
import CIcon from '@coreui/icons-react'
import { cilVerticalAlignTop } from '@coreui/icons'
import { CButton } from '@coreui/react-pro'
import React from 'react'
import { useExportSupplierMutation } from '../store/supplierApi'
import { useDispatch } from 'react-redux'
import { Supplier2 } from '../types'
import Toolbar from '../../common/components/Toolbar'

interface SupplierDetailsToolbarProps {
    supplier: Supplier2
}

export default function SupplierDetailsToolbar({ supplier }: SupplierDetailsToolbarProps) {
    const [exportSupplier, { isLoading: isExporting }] = useExportSupplierMutation()
    const dispatch = useDispatch()
    const handleExport = () => {
        executeMutation(
            exportSupplier({ supplierId: supplier.id }),
            dispatch,
            'Leverandør eksportert',
            'Kunne ikke eksportere leverandør',
            () => {}
        )
    }

    return (
        <Toolbar>
            <CButton variant={'outline'} disabled={isExporting} onClick={handleExport}>
                <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
                {isExporting ? 'Eksporterer...' : 'Eksporter til WMS'}
            </CButton>
        </Toolbar>
    )
}
