import { CBadge } from '@coreui/react-pro'
import { PaymentStatus } from '../types'
import { Colors } from '@coreui/react-pro/dist/types'

export interface PaymentStatusBadgeProps {
    paymentStatus: PaymentStatus
}

export default function PaymentStatusBadge({ paymentStatus }: PaymentStatusBadgeProps) {
    return <CBadge color={getColorForPaymentStatus(paymentStatus)}>{paymentStatus}</CBadge>
}

export function getColorForPaymentStatus(paymentStatus: PaymentStatus): Colors {
    switch (paymentStatus) {
        case PaymentStatus.NEW:
            return 'info'
        case PaymentStatus.ABANDONED:
            return 'dark'
        case PaymentStatus.AUTHORIZED:
            return 'success'
        case PaymentStatus.CANCELLED:
            return 'warning'
        case PaymentStatus.CHALLENGE_REQUIRED:
            return 'info'
        case PaymentStatus.COMPLETE:
            return 'success'
        case PaymentStatus.CREDITED:
            return 'primary'
        case PaymentStatus.ERROR:
            return 'danger'
        case PaymentStatus.FAILED:
            return 'danger'
        case PaymentStatus.INITIALIZED:
            return 'info'
        case PaymentStatus.PENDING:
            return 'warning'
        case PaymentStatus.PENDING_AUTHORIZE:
            return 'warning'
        case PaymentStatus.PENDING_CANCEL:
            return 'warning'
        case PaymentStatus.PENDING_CAPTURE:
            return 'warning'
        case PaymentStatus.PENDING_COMPLETE:
            return 'warning'
        case PaymentStatus.PENDING_CREDIT:
            return 'warning'
        case PaymentStatus.UNKNOWN:
            return 'danger'
        case PaymentStatus.VERIFIED:
            return 'info'
        default:
            return 'light'
    }
}
