import moment from 'moment'
import { Page } from '../../common/'
import DistributionCalendar from '../components/DistributionCalendar/DistributionCalendar'

function DistributionCalendarPage() {
    return (
        <Page title="Distribusjonskalender" preamble="Oversiktskalender over enkeltdager">
            <DistributionCalendar initialDate={moment()} />
        </Page>
    )
}

export default DistributionCalendarPage
