import {
    CButton,
    CCol,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { Page } from '../../common'
import { executeMutation } from '../../common/utils/apiUtils'
import {
    useCreateOrderMonitoringPhoneNumberTriggerMutation,
    useDeleteOrderMonitoringPhoneNumberTriggerMutation,
    useOrderMonitoringPhoneNumberTriggers,
} from '../store/orderMonitoringApi'

export function OrderMonitoringPage() {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'ordermonitoring.write')
    const phoneNumbers = useOrderMonitoringPhoneNumberTriggers()
    const [deletePhoneNumber] = useDeleteOrderMonitoringPhoneNumberTriggerMutation()
    const [addPhoneNumber] = useCreateOrderMonitoringPhoneNumberTriggerMutation()

    const [phoneNumberIdToBeDeleted, setPhoneNumberIdToBeDeleted] = useState<number>()
    const [showAddPhoneNumberModal, setShowAddPhoneNumberModal] = useState(false)
    const [newPhoneNumber, setNewPhoneNumber] = useState<string>()
    const [newDescription, setNewDescription] = useState<string>()

    const confirmDelete = (id?: number) => {
        if (!id) {
            console.warn('Trying to delete phone number without id')
            return
        }

        executeMutation(
            deletePhoneNumber(id),
            dispatch,
            'Telefonnummeret ble slettet',
            'Kunne ikke slette telefonnummer',
            () => setPhoneNumberIdToBeDeleted(undefined)
        )
    }

    const add = () => {
        if (!newPhoneNumber) {
            console.warn('Trying to add phone number without number')
            return
        }

        executeMutation(
            addPhoneNumber({ phoneNumber: newPhoneNumber, description: newDescription }),
            dispatch,
            `${newPhoneNumber} ble lagt til`,
            'Kunne ikke legge til telefonnummer',
            () => {
                setShowAddPhoneNumberModal(false)
                setNewPhoneNumber(undefined)
                setNewDescription(undefined)
            }
        )
    }

    return (
        <Page
            title="Ordreovervåkning"
            preamble="Hvis en ordre legges inn med et av telefonnumrene i denne listen (avsender eller mottaker) vil det sendes en Slack-notifikasjon."
        >
            <CTable align="middle" borderless>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>Telefonnummer</CTableHeaderCell>
                        <CTableHeaderCell>Beskrivelse</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {[...phoneNumbers]
                        .sort((a, b) => a.phoneNumber.localeCompare(b.phoneNumber))
                        .map((p) => (
                            <CTableRow key={p.id}>
                                <CTableDataCell>{p.phoneNumber}</CTableDataCell>
                                <CTableDataCell>{p.description}</CTableDataCell>
                                <CTableDataCell className="ta-r">
                                    <CButton
                                        color="danger"
                                        onClick={() => setPhoneNumberIdToBeDeleted(p.id)}
                                        disabled={!canChange}
                                    >
                                        Slett
                                    </CButton>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                </CTableBody>
            </CTable>
            <div className="ta-c">
                <CButton color="primary" onClick={() => setShowAddPhoneNumberModal(true)} disabled={!canChange}>
                    Legg til telefonnummer
                </CButton>
            </div>
            <CModal
                visible={!!phoneNumberIdToBeDeleted}
                alignment="center"
                onClose={() => setPhoneNumberIdToBeDeleted(undefined)}
            >
                <CModalHeader>
                    <CModalTitle>
                        Slett {phoneNumbers.find((p) => p.id === phoneNumberIdToBeDeleted)?.phoneNumber}?
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>Er du sikker på at du vil slette telefonnummeret?</CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setPhoneNumberIdToBeDeleted(undefined)}>
                        Avbryt
                    </CButton>
                    <CButton color="danger" onClick={() => confirmDelete(phoneNumberIdToBeDeleted)}>
                        Slett telefonnummer
                    </CButton>
                </CModalFooter>
            </CModal>
            <CModal
                visible={showAddPhoneNumberModal}
                alignment="center"
                onClose={() => setShowAddPhoneNumberModal(false)}
            >
                <CModalHeader>
                    <CModalTitle>Legg til telefonnummer</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className="mb-1">
                        Legg til et telefonnummer som skal overvåkes. Ikke benytt mellomrom eller landskode.
                    </div>
                    <CRow className="mb-1/2">
                        <CFormLabel className="col-4 col-form-label">Telefonnummer</CFormLabel>
                        <CCol>
                            <CFormInput
                                type="text"
                                value={newPhoneNumber}
                                onChange={(e) => setNewPhoneNumber(e.currentTarget.value)}
                            />
                        </CCol>
                    </CRow>
                    <CRow className="mb-1/2">
                        <CFormLabel className="col-4 col-form-label">Beskrivelse (valgfritt)</CFormLabel>
                        <CCol>
                            <CFormInput
                                type="text"
                                value={newDescription}
                                onChange={(e) => setNewDescription(e.currentTarget.value)}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setShowAddPhoneNumberModal(false)}>
                        Avbryt
                    </CButton>
                    <CButton color="primary" disabled={!newPhoneNumber} onClick={add}>
                        Legg til telefonnummer
                    </CButton>
                </CModalFooter>
            </CModal>
        </Page>
    )
}
