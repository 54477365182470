import CardHeader from '../../common/components/CardHeader'
import { CButton, CCardBody, CCol, CForm, CRow } from '@coreui/react-pro'
import ProductAttributesCard from './ProductAttibutesCard'
import NutritionalInfoCards from './NutritionalInfoCards'
import Card from '../../common/components/Card'
import React, { FormEvent, useEffect, useState } from 'react'
import { ProductGroup, RegisteredProduct } from '../types'
import ProductBadgeCard from './ProductBadgeCard'
import { ProductImageCarousel } from './ProductImageCarousel'
import { useUpdateProductMutation } from '../store/productApi'
import { useDispatch } from 'react-redux'
import { addToast } from '../../common/store/commonSlice'
import { cilDescription, cilPencil, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import CategoriesCard from './CategoriesCard'
import { Input, Select, TextArea } from '../../common/components/Input/Input'

export default function ProductInfoCard({ product }: { product: RegisteredProduct }) {
    const [netWeight, setNetWeight] = useState<number>(product.netWeight)
    const [productGroup, setProductGroup] = useState<ProductGroup>(product.productGroup)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [updateProduct, updateProductResult] = useUpdateProductMutation()
    const dispatch = useDispatch()

    const isAnyChanges = netWeight !== product.netWeight || productGroup !== product.productGroup

    useEffect(() => {
        if (updateProductResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produktet ble oppdatert',
                    color: 'success',
                })
            )
            setIsEditing(false)
        }

        if (updateProductResult.isError) {
            dispatch(
                addToast({
                    message: 'Kunne ikke oppdatere produktet: ' + updateProductResult.error,
                    color: 'danger',
                })
            )
        }
    }, [updateProductResult])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const updatedProduct = {
            netWeight,
            productGroup,
        }

        updateProduct({ productId: product.id, data: updatedProduct })
    }

    const cancelChanges = () => {
        setNetWeight(product.netWeight)
        setProductGroup(product.productGroup)
    }

    const handleCardActionClick = () => {
        if (isEditing && isAnyChanges) {
            if (!window.confirm('Du har ulagrede endringer. Er du sikker på at du vil avbryte?')) {
                return
            }
            cancelChanges()
        }
        setIsEditing(!isEditing)
    }
    return (
        <Card onAction={handleCardActionClick} actionIcon={isEditing ? cilX : cilPencil}>
            <CardHeader>
                <CIcon icon={cilDescription} size={'xl'} className={'mr-1'} />
                Produktinfo
            </CardHeader>
            <CCardBody>
                <CRow>
                    <ProductImageCarousel product={product} />
                </CRow>
                <CForm onSubmit={handleSubmit}>
                    <div className={'mb-3'}>
                        <Input
                            disabled
                            type="text"
                            label="SKU"
                            invalid={!product.sku}
                            text="Stock Keeping Unit. Unik identifikator for produktet."
                            value={product.sku}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled
                            type="text"
                            label="Ekstern produktid"
                            invalid={!product.externalProductId}
                            text="Produktid fra Craft."
                            value={product.externalProductId}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled
                            type="text"
                            label="Tittel"
                            text="Tittel på produktet. Brukes på etikett (og nettside dersom ikke visningsnavn er gitt.)"
                            value={product.title}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled
                            type="text"
                            label="Visningsnavn"
                            text="Dersom visningsnavn er satt er dette navnet som vises på nettsiden. Dersom dette feltet er tomt vil tittelen vises."
                            value={product.displayName}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            type="text"
                            disabled
                            label="Leverandør"
                            text="Navn på leverandøren."
                            value={product.supplierName}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            type="text"
                            disabled
                            label="Rapporteringskategori"
                            text="Brukes til å gruppere produkter i rapporter."
                            value={product.category}
                            invalid={!product.category}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            type="text"
                            disabled
                            label="Bruttovekt"
                            text="Bruttovekt i gram. Dette er vekten av produktet inkludert emballasje. Brukes for å beregne fraktpris."
                            value={product.grossWeight}
                            invalid={!product.grossWeight}
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            type={'number'}
                            id="netWeight"
                            label="Nettovekt"
                            text={'Nettovekt i gram. Dette er vekten av produktet uten emballasje.'}
                            value={netWeight}
                            disabled={!isEditing}
                            onChange={(e) => setNetWeight(Number(e.target.value))}
                        />
                    </div>
                    <div className="mb-3">
                        <Select
                            id="productGroup"
                            label="Produktgruppe"
                            options={[
                                { value: undefined, label: 'Ikke valgt' },
                                { value: 'CHILLED', label: 'Kjøl' },
                                { value: 'CHILL_BEV', label: 'Kjølt drikke' },
                                { value: 'DRY', label: 'Tørr' },
                                { value: 'FRESH', label: 'Fersk' },
                                { value: 'FROZEN', label: 'Frys' },
                            ]}
                            text={'Brukes for å gi beskjed til lageret hvor produktet skal oppbevares.'}
                            disabled={!isEditing}
                            value={productGroup}
                            onChange={(e) => setProductGroup(e.target.value as ProductGroup)}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <TextArea
                            disabled
                            label="Beskrivelse"
                            text="Kort beskrivelse av produktet. Vises på nettsiden."
                            style={{ height: '200px' }}
                            value={product.description}
                            invalid={!product.description}
                        />
                    </div>
                    <CRow>
                        <CCol>
                            <ProductBadgeCard badge={product.badge} />
                        </CCol>
                        <CCol>
                            <ProductAttributesCard attributes={product.attributes} />
                        </CCol>
                    </CRow>
                    <NutritionalInfoCards additionalInformation={product.additionalInformation} />
                    <CRow>
                        <CCol className={'ml-1'}>
                            <CategoriesCard heading={'Salgskategorier'} categories={product.salesCategories} />
                        </CCol>
                    </CRow>
                    {isEditing && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CButton
                                type="submit"
                                color="primary"
                                disabled={updateProductResult.isLoading || !isAnyChanges}
                            >
                                {'Lagre'}
                            </CButton>
                        </div>
                    )}
                </CForm>
            </CCardBody>
        </Card>
    )
}
