import { Page } from '../../common'
import { useParams } from 'react-router'
import Barcode from 'react-barcode'
import { purchaseOrderApi } from '../store/purchaseOrderApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { CRow, CSmartTable, CSpinner } from '@coreui/react-pro'
import { PurchaseOrder } from '../types'
import { useEffect, useState } from 'react'
import PurchaseOrderStatusDropdown from './PurchaseOrderStatusDropdown'

interface PurchaseOrderDetailsPageParams {
    purchaseOrderNumber: string
}

export default function PurchaseOrderDetails() {
    const { purchaseOrderNumber } = useParams<PurchaseOrderDetailsPageParams>()
    const { data: purchaseOrders, isLoading, isSuccess, isError, error } = purchaseOrderApi.useGetPurchaseOrdersQuery()
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | undefined>()

    useEffect(() => {
        if (isSuccess && purchaseOrders?.length) {
            const purchaseOrder = purchaseOrders.find((purchaseOrder) => purchaseOrder.orderNo === purchaseOrderNumber)
            if (purchaseOrder) {
                setPurchaseOrder(purchaseOrder)
            }
        }
    }, [purchaseOrders])

    return (
        <Page title={`Innkjøpsordre #${purchaseOrderNumber}`}>
            {isError && <ErrorMessage error={error} />}
            {isLoading && <CSpinner />}
            {isSuccess && purchaseOrder && (
                <>
                    <CRow>
                        <div className={'d-flex justify-content-between'}>
                            <div className={'mb-3 mr-1'}>
                                <Barcode value={purchaseOrderNumber} format={'CODE128'} />
                            </div>
                            <div>
                                <PurchaseOrderStatusDropdown purchaseOrder={purchaseOrder} />
                            </div>
                        </div>
                    </CRow>
                    <CSmartTable
                        items={purchaseOrder.orderLines}
                        columns={[
                            {
                                key: 'title',
                                label: 'Tittel',
                            },
                            {
                                key: 'quantity',
                                label: 'Antall',
                            },
                            {
                                key: 'sku',
                                label: 'SKU',
                            },
                            {
                                key: 'warehouse',
                                label: 'Varehus',
                            },
                            {
                                key: 'description',
                                label: 'Beskrivelse',
                            },
                        ]}
                    />
                </>
            )}
        </Page>
    )
}
