import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Freight } from '../types'

export const freightApi = createApi({
    reducerPath: 'freight',
    tagTypes: ['Freight'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getFreight: builder.query<Freight[], void>({
            query: () => ({
                url: '/admin/freight',
                method: 'GET',
            }),
            providesTags: ['Freight'],
        }),
        updateFreight: builder.mutation<void, Freight>({
            query: (freight) => ({
                url: `/admin/freight/${freight.regionId}`,
                method: 'PUT',
                body: {
                    amount: freight.amount,
                    freeFreightOrderValue: freight.freeFreightOrderValue,
                },
            }),
            invalidatesTags: ['Freight'],
        }),
    }),
})

export const { useGetFreightQuery, useUpdateFreightMutation } = freightApi
