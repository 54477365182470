import { CCard, CCardBody, CCardTitle } from '@coreui/react-pro'
import { Customer } from '../types'
import OrderHistory from './OrderHistory/OrderHistory'

interface OrdersCardProps {
    customer: Customer
}

export default function OrdersCard({ customer }: OrdersCardProps): JSX.Element {
    return (
        <CCard>
            <CCardBody>
                <CCardTitle>Ordrehistorikk</CCardTitle>
                <OrderHistory customer={customer} />
            </CCardBody>
        </CCard>
    )
}
