import moment from 'moment'
import { HistoricOrder } from '../../types'

import flaskAndCroissant from '../../../assets/images/flaskAndCroissant.svg'
import clock from '../../../assets/images/clock.svg'

import style from './OrderGroupHeader.module.scss'
import { CBadge } from '@coreui/react-pro'

interface OrderGroupHeaderProps {
    orderGroup: HistoricOrder
    onClick?: () => void
}

export default function OrderGroupHeader({ orderGroup, onClick }: OrderGroupHeaderProps) {
    const { payment, deliveries } = orderGroup

    const deliveryDate = moment(deliveries[0].deliveryDate)

    const numDeliveries = deliveries.length
    const numItemsPerDelivery = deliveries[0].products.map((product) => product.quantity).reduce((a, b) => a + b)

    const allFailed = deliveries.every((delivery) => delivery.orderStatus === 'FAILED')
    const allCancelled = deliveries.every((delivery) => delivery.orderStatus === 'CANCELLED')

    return (
        <div onClick={onClick} className={style.container}>
            <p>
                <img src={clock} width={24} height={24} className={style.inlineSvg} />
                {deliveryDate.format('Do MMMM YYYY')}
            </p>
            <p>
                <img src={flaskAndCroissant} width={24} height={24} className={style.inlineSvg} />
                {`${numItemsPerDelivery} vare${numItemsPerDelivery === 1 ? '' : 'r'}`}
                {numDeliveries > 1 ? ` ( \u00D7${numDeliveries} leveringer)` : null}
            </p>
            <p>
                {payment
                    ? `Totalpris: ${(payment.netAmount + payment.vatAmount).toFixed(2)} NOK`
                    : 'Ordre uten betaling'}
            </p>
            {allFailed && <CBadge color="danger">Feilet</CBadge>}
            {allCancelled && <CBadge color="warning">Kansellert</CBadge>}
        </div>
    )
}
