import { useState } from 'react'
import { DateChangeEvent, DatePicker, Page } from '../../common'
import css from './common.module.scss'
import { DownloadSalesReportButton } from '../components/DownloadSalesReportButton'
import { CButton, CCol, CRow } from '@coreui/react-pro'
import authenticatedClient from '../../services/axios'
import { apiUrl } from '../../config'
import format from 'date-fns/format'
import { SalesReportChart } from '../components/SalesReportChart'

function SalesReportPage() {
    const [selectedDates, setSelectedDates] = useState<DateChangeEvent>({ startDate: new Date(), endDate: new Date() })
    const [graphData, setGraphData] = useState<any[]>([])
    const handleDateChange = (selectedDates: DateChangeEvent) => {
        setSelectedDates(selectedDates)
    }

    const toDateGroupedArray = (data: any) => {
        const dataByDateMap: Record<string, any> = {}
        data.forEach((item: any) => {
            if (!dataByDateMap[item.deliveryDate]) {
                dataByDateMap[item.deliveryDate] = {
                    date: item.deliveryDate,
                }
            }

            dataByDateMap[item.deliveryDate] = {
                ...dataByDateMap[item.deliveryDate],
                [item.product]: item.amount,
            }
        })
        return Object.values(dataByDateMap)
    }

    const handleShowGraph = () => {
        if (!selectedDates.startDate || !selectedDates.endDate) {
            return
        }
        authenticatedClient
            .get(
                `${apiUrl}/reports/shipped-count-per-product-item?fromDate=${format(
                    selectedDates.startDate,
                    'yyyy-MM-dd'
                )}&toDate=${format(selectedDates.endDate, 'yyyy-MM-dd')}`
            )
            .then(({ data }) => {
                setGraphData(toDateGroupedArray(data))
            })
    }

    console.log(graphData)

    return (
        <Page
            title="Salgstall"
            preamble="Denne rapporten inneholder antall solgte produkter av hver type (fra bookede B2C-ordre) til valgt leveringsdag."
        >
            <CRow>
                <CCol>
                    <div className={css.datePickerWrapper}>
                        <h2>Velg periode</h2>
                        <DatePicker onChange={handleDateChange} />
                        <CRow>
                            <CCol>
                                <DownloadSalesReportButton
                                    startDate={selectedDates.startDate}
                                    endDate={selectedDates.endDate}
                                />
                            </CCol>
                            <CCol>
                                <CButton
                                    onClick={handleShowGraph}
                                    disabled={!selectedDates.startDate || !selectedDates.endDate}
                                >
                                    Vis som graf
                                </CButton>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
                <CCol className={'align-content-center'}>
                    <SalesReportChart graphData={graphData} />
                </CCol>
            </CRow>
        </Page>
    )
}

export default SalesReportPage
