import { CButton, CCallout, CCard, CCardBody, CCardTitle, CSpinner } from '@coreui/react-pro'
import { Customer, PaymentMethod } from '../types'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../store'
import { fetchPaymentMethodsForCustomer } from '../store/paymentMethodSlice'
import { Status } from '../../common/types'

interface PaymentMethodsCardProps {
    customer: Customer
}

export default function PaymentMethodsCard({ customer }: PaymentMethodsCardProps): JSX.Element {
    const status = useSelector<IRootState, Status>((state) => state.paymentMethod.status)
    const paymentMethods = useSelector<IRootState, PaymentMethod[] | undefined>(
        (state) => state.paymentMethod.paymentMethods
    )
    const loading = Status.Loading == status

    const dispatch = useDispatch()

    if (loading) return <CSpinner />

    return (
        <CCard>
            <CCardBody>
                <CCardTitle>Betalingsmiddel</CCardTitle>
                <CCard>
                    <CCardBody>
                        {status == Status.Idle && (
                            <CButton
                                onClick={() => dispatch(fetchPaymentMethodsForCustomer(customer.customerId.toString()))}
                            >
                                Hent betalingsmiddel
                            </CButton>
                        )}
                        {paymentMethods && (
                            <div>
                                {paymentMethods.map((pm) => {
                                    return (
                                        <CCallout color="primary" key={pm.id}>
                                            ID: {pm.id}
                                            <br />
                                            Details: {pm.description}
                                            <br />
                                            UseCase: {pm.useCase}
                                            <br />
                                            Status: {pm.status}
                                        </CCallout>
                                    )
                                })}
                            </div>
                        )}
                    </CCardBody>
                </CCard>
            </CCardBody>
        </CCard>
    )
}
