import CardHeader from '../../common/components/CardHeader'
import { CAlert, CButton, CCardBody, CCardText, CCol, CRow, CSmartTable, CSpinner } from '@coreui/react-pro'
import moment from 'moment/moment'
import Card from '../../common/components/Card'
import React from 'react'
import { distributionApi, ShipmentItem } from '../store/distributionApi'
import CIcon from '@coreui/icons-react'
import { cilExternalLink } from '@coreui/icons'
import { diMainUrl, flowUrl } from '../../config'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import { AuthorizedContent } from '../../auth/AuthorizedContent'

export default function ShipmentCard({ orderId, bookingTime }: { orderId: number; bookingTime: string }) {
    const { data: shipment, isLoading, error } = distributionApi.useGetShipmentByOrderIdQuery(orderId)
    const [bookShipment] = distributionApi.useBookShipmentsMutation()
    const dispatch = useDispatch()
    const handleBookNow = () => {
        executeMutation(bookShipment([orderId]), dispatch, 'Sending opprettet', 'Kunne ikke opprette sending')
    }
    if (isLoading) {
        return <CSpinner />
    }
    if (error) {
        if ('data' in error) {
            if (error.status === 404) {
                return (
                    <Card>
                        <CardHeader>Sending</CardHeader>
                        <CCardBody>
                            <p>Sending ikke opprettet, bookes {moment(bookingTime).format('LLLL')}</p>
                            <AuthorizedContent requiredPermissions={['distribution.write']}>
                                <CButton onClick={handleBookNow}>Book nå</CButton>
                            </AuthorizedContent>
                        </CCardBody>
                    </Card>
                )
            }
        } else {
            return <CAlert color={'warning'}>{JSON.stringify(error)}</CAlert>
        }
    }
    if (shipment) {
        return (
            <Card>
                <CardHeader>
                    <CRow>
                        <CCol>Sending</CCol>
                        <CCol sm={6}>{/*<CBadge color="success">Levert</CBadge>*/}</CCol>
                    </CRow>
                </CardHeader>
                <CCardBody>
                    <CCardText>
                        <p>
                            <strong>Sendingsnummer:</strong> {shipment.shipment.shipmentNumber}
                        </p>
                        <p>
                            <strong>Hovedbil:</strong> {shipment.shipment.mainCarNumber}
                        </p>
                        <p>
                            <strong>Budsentral:</strong> {shipment.shipment.routeDescription}
                        </p>
                        <p>
                            <strong>Transportselskap:</strong> {shipment.shipment.externalTransportCompanyId}
                        </p>
                        <p>
                            <strong>Transportprodukt:</strong> {shipment.shipment.transportProductName}
                        </p>
                        <p>
                            <strong>Detaljert rute:</strong> {shipment.shipment.routingDetailed}
                        </p>
                        <p>
                            <strong>Rutenummer:</strong> {shipment.shipment.routeName}
                        </p>
                        <p>
                            <strong>Rute:</strong> {shipment.shipment.routing}
                        </p>
                        <CSmartTable
                            items={shipment.shipmentItems}
                            columns={[
                                {
                                    key: 'trackingNumber',
                                    label: 'Sporingsnummer',
                                    filter: false,
                                    sorter: false,
                                    _style: { width: '40%' },
                                },
                                {
                                    key: 'jsonArticles',
                                    label: 'Artikler',
                                    _style: { width: '50%' },
                                },
                            ]}
                            scopedColumns={{
                                trackingNumber: (item: ShipmentItem) => {
                                    return (
                                        <td>
                                            <a
                                                href={`${diMainUrl}/app/Customer.do?action=searchCustomerDelivery&elasticCustomer.externalDeliveryId=00${item.trackingNumber}&elasticCustomer.active=2`}
                                                target="_blank"
                                                rel={'noreferrer'}
                                            >
                                                {item.trackingNumber} (DI) <CIcon icon={cilExternalLink} />
                                            </a>
                                            <a
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                                href={`${flowUrl}/orderSearch/00${item.trackingNumber}`}
                                            >
                                                {item.trackingNumber} (Flow) <CIcon icon={cilExternalLink} />
                                            </a>
                                        </td>
                                    )
                                },
                                jsonArticles: (item: ShipmentItem) => {
                                    return (
                                        <td>
                                            {item.jsonArticles?.map((article) => {
                                                return (
                                                    <span style={{ display: 'block' }} key={article.articleNumber}>
                                                        {article.quantity} x {article.articleName}
                                                    </span>
                                                )
                                            })}
                                        </td>
                                    )
                                },
                            }}
                        />
                    </CCardText>
                </CCardBody>
            </Card>
        )
    }

    return null
}
