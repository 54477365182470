import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { CreatePackageLocation, EditSupplier, Supplier, Supplier2 } from '../types'

export const supplierApi = createApi({
    reducerPath: 'supplier',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['Supplier', 'Suppliers', 'Supplier2', 'Suppliers2', 'PackageLocations'],
    endpoints: (builder) => ({
        getSuppliers: builder.query<Supplier[], void>({
            query: () => ({
                url: '/admin/supplier',
                method: 'GET',
            }),
            providesTags: ['Suppliers'],
        }),
        getSuppliers2: builder.query<Supplier2[], void>({
            query: () => ({
                url: '/admin/supplier/v2',
                method: 'GET',
            }),
            providesTags: ['Suppliers2'],
        }),
        getSupplier2: builder.query<Supplier2, string | number>({
            query: (supplierId) => ({
                url: `/admin/supplier/v2/${supplierId}`,
                method: 'GET',
            }),
            providesTags: (result, error, supplierId) => [{ type: 'Supplier2', id: supplierId }],
        }),
        syncSuppliers: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/supplier/v2/sync`,
                method: 'POST',
                body: {},
            }),
            invalidatesTags: ['Suppliers2'],
        }),
        getSupplier: builder.query<Supplier, string | number>({
            query: (supplierId) => ({
                url: `/admin/supplier/${supplierId}`,
                method: 'GET',
            }),
            providesTags: (result, error, supplierId) => [{ type: 'Supplier', id: supplierId }],
        }),
        createPackageLocation: builder.mutation<void, CreatePackageLocation>({
            query: (body) => ({
                url: '/admin/package-location',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['PackageLocations'],
        }),
        updateSupplier: builder.mutation<void, EditSupplier>({
            query: (edit) => {
                const { supplierId, ...body } = edit
                return {
                    url: `/admin/supplier/${supplierId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Supplier', id: arg.supplierId }, 'Suppliers'],
        }),
        exportSupplier: builder.mutation<void, { supplierId: string }>({
            query: ({ supplierId }) => {
                return {
                    url: `/admin/supplier/v2/${supplierId}/wms/export`,
                    method: 'POST',
                }
            },
        }),
        exportSuppliers: builder.mutation<void, void>({
            query: () => {
                return {
                    url: `/admin/supplier/v2/wms/export`,
                    method: 'POST',
                }
            },
        }),
    }),
})

export const {
    useGetSuppliersQuery,
    useGetSupplierQuery,
    useGetSupplier2Query,
    useGetSuppliers2Query,
    useSyncSuppliersMutation,
    useUpdateSupplierMutation,
    useCreatePackageLocationMutation,
    useExportSupplierMutation,
    useExportSuppliersMutation,
} = supplierApi
