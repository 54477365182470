import { CCarousel, CCarouselCaption, CCarouselItem, CImage } from '@coreui/react-pro'
import React from 'react'
import { RegisteredProduct } from '../types'
import no_image from '../../assets/images/no_image.svg'

interface ProductImageCarouselProps {
    product: RegisteredProduct
}

export function ProductImageCarousel({ product }: ProductImageCarouselProps) {
    return (
        <CCarousel className={'mb-3'} controls indicators dark pause={'hover'}>
            <CCarouselItem>
                {product.detailImageUrl == null && (
                    <CImage className={'d-block m-auto'} height={300} src={no_image} alt="Mangler detaljbilde" />
                )}
                {product.detailImageUrl != null && (
                    <CImage className={'d-block m-auto'} height={300} src={product.detailImageUrl} alt="Detaljbilde" />
                )}
                <CCarouselCaption className="d-none d-md-block">
                    <span className={'p-1'} style={{ background: 'white' }}>
                        Detaljbilde
                    </span>
                </CCarouselCaption>
            </CCarouselItem>
            <CCarouselItem>
                {product.listImageUrl == null && (
                    <CImage className={'d-block m-auto'} height={300} src={no_image} alt="Mangler listebilde" />
                )}
                {product.listImageUrl != null && (
                    <CImage className={'d-block m-auto'} height={300} src={product.listImageUrl} alt="Listebilde" />
                )}
                <CCarouselCaption className="d-none d-md-block">
                    <span className={'p-1'} style={{ background: 'white' }}>
                        Listebilde
                    </span>
                </CCarouselCaption>
            </CCarouselItem>
        </CCarousel>
    )
}
