import { cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
    CButton,
    CCol,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { Page } from '../../common'
import { executeMutation } from '../../common/utils/apiUtils'
import { CreateUserModal } from '../components/CreateUserModal'
import { useAdminRoles } from '../store/adminRoleApi'
import {
    useAdminUsers,
    useCreateAdminUserMutation,
    useDeleteAdminUserMutation,
    useUpdateAdminUserMutation,
} from '../store/adminUserApi'

export function AdminUserPage() {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'users.write')
    const canAssignElevatedRoles = userHasSomePermissions(useAuthenticatedUser(), 'users.elevatedwrite')
    const adminUsers = useAdminUsers()
    const roles = useAdminRoles()
    const [createAdminUser] = useCreateAdminUserMutation()
    const [updateAdminUser] = useUpdateAdminUserMutation()
    const [deleteAdminUser] = useDeleteAdminUserMutation()

    const [showCreateUserModal, setShowCreateUserModal] = useState(false)
    const [userToBeUpdated, setUserToBeUpdated] = useState<number>()
    const [updatedRole, setUpdatedRole] = useState<string>()
    const [userToBeDeleted, setUserToBeDeleted] = useState<number>()

    useEffect(() => {
        const user = adminUsers.find((u) => u.id === userToBeUpdated)
        if (user && user.roles.length > 0) {
            setUpdatedRole(user.roles[0])
        } else {
            setUpdatedRole(undefined)
        }
    }, [adminUsers, userToBeUpdated])

    const createUser = (email: string, role: string, resetFormFunc: () => void) => {
        executeMutation(
            createAdminUser({ email: email, roles: [role] }),
            dispatch,
            `${email} ble lagt til`,
            'Kunne ikke legge til bruker',
            () => {
                setShowCreateUserModal(false)
                resetFormFunc()
            }
        )
    }

    const updateUser = () => {
        if (!userToBeUpdated) {
            console.warn('No user to update')
            return
        }

        if (!updatedRole) {
            console.warn('No role to update')
            return
        }

        executeMutation(
            updateAdminUser({ id: userToBeUpdated, roles: [updatedRole] }),
            dispatch,
            `Brukeren ble oppdatert`,
            'Kunne ikke oppdatere bruker',
            () => {
                setUserToBeUpdated(undefined)
            }
        )
    }

    const deleteUser = (id: number) => {
        executeMutation(deleteAdminUser(id), dispatch, 'Brukeren ble slettet', 'Kunne ikke slette bruker', () =>
            setUserToBeDeleted(undefined)
        )
    }

    return (
        <Page title="Brukere" preamble="Legg til eller fjern brukere på Morgenlevering Admin.">
            <CTable align="middle" borderless>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>E-post</CTableHeaderCell>
                        <CTableHeaderCell>Schibsted Account ID</CTableHeaderCell>
                        <CTableHeaderCell>Rolle</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {[...adminUsers]
                        .sort((a, b) => a.email.localeCompare(b.email))
                        .map((u) => (
                            <CTableRow key={u.email}>
                                <CTableDataCell>{u.email}</CTableDataCell>
                                <CTableDataCell>{u.externalId}</CTableDataCell>
                                <CTableDataCell>
                                    {u.roles.join(', ')}
                                    <CButton
                                        color="link"
                                        onClick={() => setUserToBeUpdated(u.id)}
                                        disabled={!canChange}
                                    >
                                        <CIcon icon={cilPencil} aria-label="Edit role" />
                                    </CButton>
                                </CTableDataCell>
                                <CTableDataCell className="ta-r">
                                    <CButton
                                        color="danger"
                                        onClick={() => setUserToBeDeleted(u.id)}
                                        disabled={!canChange}
                                    >
                                        Slett
                                    </CButton>
                                </CTableDataCell>
                            </CTableRow>
                        ))}
                </CTableBody>
            </CTable>
            <div className="ta-c">
                <CButton color="primary" onClick={() => setShowCreateUserModal(true)} disabled={!canChange}>
                    Legg til bruker
                </CButton>
            </div>
            <CreateUserModal
                visible={showCreateUserModal}
                onClose={() => setShowCreateUserModal(false)}
                onCreate={createUser}
            />
            <CModal visible={!!userToBeUpdated} alignment="center" onClose={() => setUserToBeUpdated(undefined)}>
                <CModalHeader>
                    <CModalTitle>Endre rolle for {adminUsers.find((u) => u.id === userToBeUpdated)?.email}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CFormLabel className="col-4 col-form-label">Rolle</CFormLabel>
                        <CCol>
                            <CFormSelect
                                options={[
                                    {},
                                    ...roles
                                        .filter((r) => canAssignElevatedRoles || !r.elevated)
                                        .map((r) => ({
                                            value: r.role,
                                            label: r.description ? `${r.role} (${r.description})` : r.role,
                                        })),
                                ]}
                                onChange={(e) => setUpdatedRole(e.currentTarget.value)}
                                value={updatedRole}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setUserToBeUpdated(undefined)}>
                        Avbryt
                    </CButton>
                    <CButton color="primary" disabled={!updatedRole} onClick={updateUser}>
                        Lagre
                    </CButton>
                </CModalFooter>
            </CModal>
            <CModal visible={!!userToBeDeleted} alignment="center" onClose={() => setUserToBeDeleted(undefined)}>
                <CModalHeader>
                    <CModalTitle>Slett {adminUsers.find((u) => u.id === userToBeDeleted)?.email}?</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    Brukeren vil ikke lenger kunne logge på Morgenlevering Admin. Er du sikker på at du vil slette
                    brukeren?
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setUserToBeDeleted(undefined)}>
                        Avbryt
                    </CButton>
                    <CButton
                        color="danger"
                        onClick={() => {
                            if (!userToBeDeleted) {
                                console.warn('No user to delete')
                                return
                            }
                            deleteUser(userToBeDeleted)
                        }}
                    >
                        Slett bruker
                    </CButton>
                </CModalFooter>
            </CModal>
        </Page>
    )
}
