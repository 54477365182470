import { WarehouseAvailability } from '../store/inventoryApi'
import { Region } from '../../region/types'
import { CSmartTable } from '@coreui/react-pro'
import { AvailabilityProgressBar } from './AvailabilityProgressBar'

export function StockProductAvailabilities({ item, regions }: { item: WarehouseAvailability; regions: Region[] }) {
    const availability = item.data || {}

    return (
        <CSmartTable
            items={[availability]}
            tableProps={{
                responsive: true,
                striped: true,
                hover: true,
                bordered: true,
            }}
            columns={[
                {
                    group: 'Regioner',
                    key: '3',
                    children: regions.map((region) => {
                        return {
                            key: region.name,
                            label: region.name,
                            _props: { scope: 'regions' },
                            _style: { width: '10%' },
                        }
                    }),
                },
            ]}
            scopedColumns={{
                ...Object.fromEntries(
                    regions.map((region) => {
                        return [
                            region.name,
                            (item: [string, any]) => {
                                const availableQuantity = item[region.id]?.stock || 0
                                let reservedQuantity = 0
                                for (const key in item[region.id]?.reserved) {
                                    if (item[region.id]?.reserved[key] !== null) {
                                        reservedQuantity += item[region.id]?.reserved[key] || 0
                                    }
                                }
                                return (
                                    <td>
                                        <AvailabilityProgressBar
                                            reservedQuantity={reservedQuantity}
                                            availableQuantity={availableQuantity}
                                        />
                                    </td>
                                )
                            },
                        ]
                    })
                ),
            }}
        />
    )
}
