import { OrderLine, PurchaseOrder, PurchaseOrderStatus } from '../types'
import { useState } from 'react'
import { Supplier2 } from '../../product/types'

const createPurchaseOrderNo = () => {
    const date = new Date()
    const year = date.getFullYear().toString().slice(-2)
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return `${year}${month}${day}${hour}${minute}${second}`
}

const createEmptyPurchaseOrder: () => PurchaseOrder = () => ({
    packageLocationId: 'sampakkøst',
    supplierId: '',
    supplierName: '',
    orderNo: createPurchaseOrderNo(),
    status: 'OPEN',
    orderLines: [],
    orderDate: '',
})

export const usePurchaseOrder = (initialPurchaseOrder: PurchaseOrder = createEmptyPurchaseOrder()) => {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>(initialPurchaseOrder)

    const setPackageLocationId = (packageLocationId: string) => {
        setPurchaseOrder({
            ...purchaseOrder,
            packageLocationId,
        })
    }

    const setSupplier = (supplier: Supplier2) => {
        setPurchaseOrder({
            ...purchaseOrder,
            supplierId: supplier.externalId,
            supplierName: supplier.name,
        })
    }

    const changeSupplier = (supplier: Supplier2) => {
        setPurchaseOrder({
            ...purchaseOrder,
            orderLines: [],
            supplierId: supplier.externalId,
            supplierName: supplier.name,
        })
    }

    const setOrderNo = (orderNo: string) => {
        setPurchaseOrder({
            ...purchaseOrder,
            orderNo: orderNo.slice(0, 20),
        })
    }

    const setStatus = (status: PurchaseOrderStatus) => {
        setPurchaseOrder({
            ...purchaseOrder,
            status,
        })
    }

    const addOrUpdateOrderLine = (orderLine: OrderLine) => {
        const existingOrderLine = purchaseOrder.orderLines.find((ol) => ol.sku === orderLine.sku)

        if (orderLine.quantity <= 0) {
            setPurchaseOrder({
                ...purchaseOrder,
                orderLines: purchaseOrder.orderLines.filter((ol) => ol.sku !== orderLine.sku),
            })
            return
        }

        if (existingOrderLine) {
            const newOrderLines = purchaseOrder.orderLines
                .map((ol) => {
                    if (ol.sku === orderLine.sku) {
                        return orderLine
                    }
                    return ol
                })
                .filter((ol) => ol.quantity > 0)
            setPurchaseOrder({
                ...purchaseOrder,
                orderLines: newOrderLines,
            })
            return
        }

        setPurchaseOrder({
            ...purchaseOrder,
            orderLines: [...purchaseOrder.orderLines, orderLine],
        })
    }

    const reset = () => {
        setPurchaseOrder(initialPurchaseOrder)
    }

    const isValid = () => {
        return purchaseOrder.packageLocationId && purchaseOrder.supplierId && purchaseOrder.orderLines.length > 0
    }

    return {
        purchaseOrder,
        setPackageLocationId,
        setSupplier,
        changeSupplier,
        setOrderNo,
        setStatus,
        addOrUpdateOrderLine,
        reset,
        isValid,
    }
}
