import { FeatureState } from '../types'
import {
    CButton,
    CFormSwitch,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CTableDataCell,
    CTableRow,
} from '@coreui/react-pro'
import { useState } from 'react'

interface FeatureToggleProps {
    feature: FeatureState
    onToggle: (featureName: string, enable: boolean) => Promise<void> | void
    disabled?: boolean
}

export function FeatureToggle({ feature, onToggle, disabled }: FeatureToggleProps) {
    const [isToggling, setIsToggling] = useState(false)

    const handleToggle = () => {
        setIsToggling(true)
    }

    const handleConfirmToggle = async () => {
        await onToggle(feature.feature, !feature.enabled)
        setIsToggling(false)
    }

    const ConfirmModal = ({ feature }: { feature: FeatureState }) => {
        return (
            <CModal visible={isToggling} onClose={() => setIsToggling(false)}>
                <CModalHeader closeButton>Bekreft endring</CModalHeader>
                <CModalBody>
                    Er du sikker på at du vil {feature.enabled ? 'deaktivere' : 'aktivere'}{' '}
                    <b>{feature.title || prettifyFeatureName(feature.feature)}?</b>
                </CModalBody>
                <CModalFooter>
                    <CButton color={feature.enabled ? 'danger' : 'success'} onClick={handleConfirmToggle}>
                        {feature.enabled ? 'Deaktiver' : 'Aktiver'}{' '}
                        {feature.title || prettifyFeatureName(feature.feature)}
                    </CButton>
                </CModalFooter>
            </CModal>
        )
    }

    return (
        <>
            <ConfirmModal feature={feature} />
            <CTableRow key={feature.feature}>
                <CTableDataCell>{feature.title || prettifyFeatureName(feature.feature)}</CTableDataCell>
                <CTableDataCell>
                    <CFormSwitch checked={feature.enabled} onChange={handleToggle} disabled={disabled || isToggling} />
                </CTableDataCell>
            </CTableRow>
        </>
    )
}

function prettifyFeatureName(feature: string): string {
    return feature.charAt(0).toUpperCase() + feature.slice(1).replaceAll('_', ' ').toLowerCase()
}
