import authenticatedClient from '../../services/axios'
import { apiUrl } from '../../config'
import format from 'date-fns/format'
import { FC } from 'react'
import { CButton } from '@coreui/react-pro'

interface DownloadSalesReportButtonProps {
    startDate?: Date
    endDate?: Date
}
export const DownloadSalesReportButton: FC<DownloadSalesReportButtonProps> = ({ startDate, endDate }) => {
    const downloadFile = () => {
        if (!startDate || !endDate) {
            return
        }

        authenticatedClient
            .get(
                `${apiUrl}/reports/shipped-count-per-product-item?fromDate=${format(
                    startDate,
                    'yyyy-MM-dd'
                )}&toDate=${format(endDate, 'yyyy-MM-dd')}`,
                {
                    headers: {
                        Accept: 'text/csv',
                    },
                }
            )
            .then(({ data }) => {
                const blob = new Blob([data])
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.setAttribute('href', url)
                anchor.setAttribute(
                    'download',
                    `salgstall_${format(startDate, 'yyyy-MM-dd')}-${format(endDate, 'yyyy-MM-dd')}.csv`
                )
                anchor.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => alert(error.response.data))
    }

    return (
        <CButton onClick={downloadFile} disabled={!startDate || !endDate}>
            Last ned som CSV
        </CButton>
    )
}
