import {
    CBadge,
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { DatePicker } from '../../common'
import { Status } from '../../common/types'
import { executeMutation } from '../../common/utils/apiUtils'
import { IRootState } from '../../store'
import {
    usePauseSubscriptionMutation,
    useStopSubscriptionMutation,
    useSubscriptions,
    useUnpauseSubscriptionMutation,
} from '../store/subscriptionApi'

function formatDate(date: string) {
    return moment(date).format('DD. MMM YYYY')
}

function isCurrentlyPaused(dates: string[]) {
    return dates.some((date) => moment().isSame(moment(date), 'day'))
}

function getPausePeriodString(dates: string[]) {
    if (dates.length === 1) {
        return isCurrentlyPaused(dates) ? `Til og med ${formatDate(dates[0])}` : formatDate(dates[0])
    }

    const sortedDates = [...dates].sort((a, b) => (a > b ? 1 : -1))
    return isCurrentlyPaused(dates)
        ? `Til og med ${formatDate(sortedDates[sortedDates.length - 1])}`
        : `${formatDate(sortedDates[0])} - ${formatDate(sortedDates[sortedDates.length - 1])}`
}

interface SubscriptionHistoryProps {
    customerId: string
}

function SubscriptionHistory({ customerId }: SubscriptionHistoryProps) {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'customers.write')
    const subscriptions = useSubscriptions(customerId)
    const [pauseSubscription] = usePauseSubscriptionMutation()
    const [unPauseSubscription] = useUnpauseSubscriptionMutation()
    const [stopSubscription] = useStopSubscriptionMutation()

    const loading = useSelector((state: IRootState) => state.customer.status === Status.Loading)

    const [showPauseModal, setShowPauseModal] = useState(false)
    const [subscriptionIdToPause, setSubscriptionIdToPause] = useState<number | undefined>(undefined)
    const [pauseStartDate, setPauseStartDate] = useState<string | undefined>(undefined)
    const [pauseEndDate, setPauseEndDate] = useState<string | undefined>(undefined)

    const handlePause = () => {
        if (!subscriptionIdToPause || !pauseStartDate || !pauseEndDate) {
            return
        }

        executeMutation(
            pauseSubscription({
                subscriptionId: subscriptionIdToPause,
                startDate: pauseStartDate,
                endDate: pauseEndDate,
            }),
            dispatch,
            'Fast levering ble pauset',
            'Kunne ikke pause fast levering'
        )
    }

    const handleUnpause = (subscriptionId: number) => {
        executeMutation(
            unPauseSubscription(subscriptionId),
            dispatch,
            'Fast levering ble gjenopptatt',
            'Kunne ikke gjenoppta fast levering'
        )
    }

    const handleStop = (subscriptionId: number) => {
        executeMutation(
            stopSubscription(subscriptionId),
            dispatch,
            'Fast levering ble avsluttet',
            'Kunne ikke avslutte fast levering'
        )
    }

    return (
        <>
            {loading && <CSpinner />}
            {!loading && subscriptions.length == 0 && <p>Ingen faste leveringer</p>}
            {!loading && subscriptions.length > 0 && (
                <CTable>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell>Fast leverings-ID</CTableHeaderCell>
                            <CTableHeaderCell>Gyldig fra</CTableHeaderCell>
                            <CTableHeaderCell>Gyldig til</CTableHeaderCell>
                            <CTableHeaderCell>Status</CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {subscriptions.map((subscription) => {
                            return (
                                <CTableRow key={subscription.subscriptionId}>
                                    <CTableDataCell>{subscription.subscriptionId}</CTableDataCell>
                                    <CTableDataCell>{formatDate(subscription.validFrom)}</CTableDataCell>
                                    <CTableDataCell>
                                        {subscription.validBefore && formatDate(subscription.validBefore)}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {subscription.validBefore ? (
                                            subscription.stopReason ? (
                                                <CBadge color="danger">{subscription.stopReason}</CBadge>
                                            ) : (
                                                <CBadge color="danger">UKJENT</CBadge>
                                            )
                                        ) : subscription.pauseDates.length > 0 ? (
                                            <>
                                                <CBadge color="warning">
                                                    {isCurrentlyPaused(subscription.pauseDates)
                                                        ? 'PAUSET'
                                                        : 'KOMMENDE PAUSE'}
                                                </CBadge>
                                                <span className="ml-1/2">
                                                    {getPausePeriodString(subscription.pauseDates)}
                                                </span>
                                            </>
                                        ) : (
                                            <CBadge color="success">AKTIV</CBadge>
                                        )}
                                    </CTableDataCell>
                                    <CTableDataCell className="ta-r">
                                        {!subscription.validBefore &&
                                            (subscription.pauseDates.length === 0 ? (
                                                <>
                                                    <CButton
                                                        onClick={() => {
                                                            setSubscriptionIdToPause(subscription.subscriptionId)
                                                            setShowPauseModal(true)
                                                        }}
                                                        color="primary"
                                                        disabled={!canChange}
                                                    >
                                                        Sett pause
                                                    </CButton>
                                                    <CButton
                                                        onClick={() => handleStop(subscription.subscriptionId)}
                                                        color={'danger'}
                                                        className="ml-1/2"
                                                        disabled={!canChange}
                                                    >
                                                        Avslutt
                                                    </CButton>
                                                </>
                                            ) : (
                                                <CButton
                                                    onClick={() => handleUnpause(subscription.subscriptionId)}
                                                    color="primary"
                                                    disabled={!canChange}
                                                >
                                                    Gjenoppta
                                                </CButton>
                                            ))}
                                    </CTableDataCell>
                                </CTableRow>
                            )
                        })}
                    </CTableBody>
                </CTable>
            )}
            <CModal visible={showPauseModal} alignment="center" onClose={() => setShowPauseModal(false)}>
                <CModalHeader>
                    <CModalTitle>Sett fast levering på pause</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Velg perioden du ønsker å sette fast levering på pause:</p>
                    <DatePicker
                        onChange={(dateChange) => {
                            setPauseStartDate(moment(dateChange.startDate).format('YYYY-MM-DD'))
                            setPauseEndDate(moment(dateChange.endDate).format('YYYY-MM-DD'))
                        }}
                        initialStartDate={moment().add(1, 'days').toDate()}
                        initialEndDate={moment().endOf('week').toDate()}
                    />
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setShowPauseModal(false)}>
                        Avbryt
                    </CButton>
                    <CButton
                        color="primary"
                        onClick={() => {
                            handlePause()
                            setShowPauseModal(false)
                        }}
                    >
                        Sett pause
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default SubscriptionHistory
