import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Toast } from '../types'

export interface CommonState {
    showSidebar: boolean
    toasts: Toast[]
}

const initialState: CommonState = {
    showSidebar: true,
    toasts: [],
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        toggleSidebar: (state, action) => {
            state.showSidebar = action.payload
        },
        addToast: (state, action: PayloadAction<Toast>) => {
            state.toasts.push(action.payload)
        },
    },
})

export const { toggleSidebar, addToast } = commonSlice.actions

export default commonSlice.reducer
