import { CButton } from '@coreui/react-pro'
import format from 'date-fns/format'
import { useState } from 'react'
import { DateChangeEvent, DatePicker, Page } from '../../common'
import { apiUrl } from '../../config'
import authenticatedClient from '../../services/axios'
import css from './common.module.scss'

function OrderLinesPage() {
    const [selectedDates, setSelectedDates] = useState<DateChangeEvent>({
        startDate: new Date(),
        endDate: new Date(),
    })

    const downloadFile = () =>
        authenticatedClient
            .get(
                `${apiUrl}/reports/order-lines`.concat(
                    `?fromDate=${format(selectedDates.startDate, 'yyyy-MM-dd')}`,
                    `&toDate=${format(selectedDates.endDate, 'yyyy-MM-dd')}`
                )
            )
            .then(({ data }) => {
                const blob = new Blob([data])
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.setAttribute('href', url)
                anchor.setAttribute(
                    'download',
                    `order-lines${format(selectedDates.startDate, 'yyyy-MM-dd')}-${format(
                        selectedDates.endDate,
                        'yyyy-MM-dd'
                    )}.csv`
                )
                anchor.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => alert(error.response.data))

    const handleDateChange = (selectedDates: DateChangeEvent) => {
        setSelectedDates(selectedDates)
    }

    return (
        <Page
            title="Orderlines"
            preamble="Denne rapporten inneholder alle ordrelinjer for aktive B2C-ordre (også ikke-bookede fremtidige ordre) til valgt leveringsdato."
        >
            <div className={css.datePickerWrapper}>
                <h2>Velg leveringsdatoer</h2>
                <DatePicker onChange={handleDateChange} initialStartDate={new Date()} initialEndDate={new Date()} />
                <CButton onClick={downloadFile}>Last ned CSV</CButton>
            </div>
        </Page>
    )
}

export default OrderLinesPage
