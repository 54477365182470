import { CCard, CCardBody, CCardTitle } from '@coreui/react-pro'
import { Customer } from '../types'
import DataDeletion from './DataDeletion/DataDeletion'
import DataTakeout from './DataTakeout'

interface PrivacyCardProps {
    customer: Customer
}

export default function PrivacyCard({ customer }: PrivacyCardProps) {
    return (
        <CCard>
            <CCardBody>
                <CCardTitle>Personvern</CCardTitle>
                <CCard>
                    <CCardBody>
                        <CCardTitle>Datanedlasting</CCardTitle>
                        <DataTakeout customer={customer} />
                    </CCardBody>
                </CCard>
                <CCard>
                    <CCardBody>
                        <CCardTitle>Datasletting</CCardTitle>
                        <DataDeletion customer={customer} />
                    </CCardBody>
                </CCard>
            </CCardBody>
        </CCard>
    )
}
