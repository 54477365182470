import { CAlert, CButton, CSpinner } from '@coreui/react-pro'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Status } from '../../common/types'
import { IRootState } from '../../store'
import { downloadCustomerTakeout, resetTakeoutDownload } from '../store/customerSlice'
import { Customer } from '../types'

interface DataTakeoutProps {
    customer: Customer
}

export default function DataTakeout({ customer }: DataTakeoutProps): JSX.Element {
    const dispatch = useDispatch()

    const downloadUrl = useSelector<IRootState, string | null>((state) => state.customer.takeoutDownloadBlobUrl)
    const downloadStatus = useSelector<IRootState, Status>((state) => state.customer.takeoutDownloadStatus)
    const downloadError = useSelector<IRootState, string | null>((state) => state.customer.takeoutDownloadError)

    const isSpidCustomer = !!customer.userId

    useEffect(() => {
        return () => {
            if (downloadUrl) {
                URL.revokeObjectURL(downloadUrl)
                dispatch(resetTakeoutDownload())
            }
        }
    }, [downloadUrl])

    switch (downloadStatus) {
        case Status.Idle:
            return isSpidCustomer ? (
                <CButton
                    onClick={() => dispatch(downloadCustomerTakeout(customer.customerId.toString()))}
                    disabled={!isSpidCustomer}
                >
                    Opprett nedlasting
                </CButton>
            ) : (
                <CAlert color="warning">Datanedlasting er kun mulig for kunder tilknyttet Schibsted Account.</CAlert>
            )
        case Status.Loading:
            return <CSpinner />
        case Status.Failed:
            return <CAlert color="warning">{`Opprettelse av nedlasting feilet: ${downloadError}`}</CAlert>
        case Status.Succeeded:
            return downloadUrl ? (
                <a href={downloadUrl} download={`takeout_spid_${customer.userId}.zip`}>
                    <CButton color="success">Last ned</CButton>
                </a>
            ) : (
                <CAlert color="warning">Noe gikk galt under nedlasting</CAlert>
            )
    }
}
