import { Route, Switch, useRouteMatch } from 'react-router'
import OrderDetailsPage from './OrderDetailsPage'
import OrderSearchPage from './OrderSearchPage'

function OrderPage() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${path}/:orderId`}>
                <OrderDetailsPage />
            </Route>
            <Route exact path={`${path}`}>
                <OrderSearchPage />
            </Route>
        </Switch>
    )
}

export default OrderPage
