import { CAlert, CSpinner } from '@coreui/react-pro'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Status } from '../../../common/types'
import { IRootState } from '../../../store'
import { fetchOrderHistory } from '../../store/orderHistorySlice'
import { Customer, HistoricOrder } from '../../types'
import ExpandableOrderGroup from './ExpandableOrderGroup'

import style from './OrderHistory.module.scss'

interface OrderHistoryProps {
    customer: Customer
}

export default function OrderHistory({ customer }: OrderHistoryProps): JSX.Element {
    const dispatch = useDispatch()
    const status = useSelector<IRootState, Status>((state) => state.orderHistory.status)
    const error = useSelector<IRootState, string | null | undefined>((state) => state.orderHistory.error)
    const loading = Status.Loading == status

    const orderHistory = useSelector<IRootState, HistoricOrder[]>((state) => state.orderHistory.orderHistory)

    useEffect(() => {
        dispatch(fetchOrderHistory(customer.customerId.toString()))
    }, [customer.customerId])

    if (loading) return <CSpinner />
    if (error) return <CAlert color="warning">{`Henting av ordrehistorikk feilet: ${error}`}</CAlert>
    if (orderHistory.length == 0) return <p>Ingen ordre</p>

    return (
        <div className={style.cardList}>
            {orderHistory.map((order) => (
                <ExpandableOrderGroup orderGroup={order} key={order.orderGroupId} />
            ))}
        </div>
    )
}
