import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Page } from '../../common'
import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CButton,
    CCol,
    CFormInput,
    CFormLabel,
    CRow,
} from '@coreui/react-pro'
import { capacityApi, ListCapacitiesResponse } from '../store/capacityApi'
import style from './PackingCapacityPage.module.scss'
import moment from 'moment/moment'
import { executeMutation } from '../../common/utils/apiUtils'
import Capacity from '../../distribution/components/Capacity/Capacity'
import Toolbar from '../../common/components/Toolbar'
import CIcon from '@coreui/icons-react'
import { cilVerticalAlignTop } from '@coreui/icons'
import { packageLocationApi } from '../store/packageLocationApi'

export default function PackageLocationPage() {
    const packageLocationCapacities = capacityApi.useListQuery().data || []
    const [exportToWms, { isLoading: isExporting }] = packageLocationApi.useExportToWmsMutation()
    const dispatch = useDispatch()
    const handleExport = () => {
        executeMutation(exportToWms(), dispatch, 'Pakksteder eksportert', 'Kunne ikke eksportere pakksteder')
    }
    return (
        <>
            <Page title="Pakksteder" preamble="Her kan man styre kapasiteten til alle pakkstedene">
                <Toolbar>
                    <div>
                        <CButton variant={'outline'} disabled={isExporting} onClick={handleExport}>
                            <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
                            {isExporting ? 'Eksporterer...' : 'Eksporter'}
                        </CButton>
                    </div>
                </Toolbar>
                <CAccordion className="mb-4">
                    {[...packageLocationCapacities].map((company) => (
                        <CAccordionItem key={company.packageLocation} itemKey={company.packageLocation}>
                            <CAccordionHeader>{company.packageLocation}</CAccordionHeader>
                            <CAccordionBody>
                                <CRow xs={{ gutter: 5 }}>
                                    <CCol>
                                        <h2 className={style.heading}>Antall bestilt</h2>
                                        {company.orderedAmounts.length === 0 && <div>Ingen bestillinger</div>}
                                        {company.orderedAmounts.map((capacity) => (
                                            <CRow
                                                className="d-flex align-items-center justify-content-center"
                                                key={capacity.dayOfWeek}
                                            >
                                                <CCol xs={6} className={style.day}>
                                                    <div className={'dayName'}>
                                                        {moment(capacity.date).format('dddd')}
                                                    </div>
                                                    <div className={'date'}>
                                                        {moment(capacity.date).format('Do MMMM')}
                                                    </div>
                                                </CCol>

                                                <CCol xs={6}>
                                                    <Capacity
                                                        className={'p-1 m-2'}
                                                        label={''}
                                                        total={
                                                            company.defaultCapacities.find(
                                                                (c) => c.dayOfWeek === capacity.dayOfWeek
                                                            )?.capacity || 0
                                                        }
                                                        used={capacity.amount}
                                                    />
                                                </CCol>
                                            </CRow>
                                        ))}
                                    </CCol>

                                    <CCol>
                                        <StandardCapacityForm
                                            packagelocation={company.packageLocation}
                                            capacity={company}
                                            // TODO permissions
                                            //disabled={!canChange}
                                        />
                                    </CCol>
                                </CRow>
                            </CAccordionBody>
                        </CAccordionItem>
                    ))}
                </CAccordion>
            </Page>
        </>
    )
}

interface StandardCapacityFormProps {
    packagelocation: string
    capacity: ListCapacitiesResponse
}

export function StandardCapacityForm({ packagelocation, capacity }: StandardCapacityFormProps) {
    return (
        <div className={style.form}>
            <h2 className={style.heading}>Standardkapasitet</h2>
            {capacity.defaultCapacities.map((defaultCapacity) => (
                <CRow className="mb-1/2" key={defaultCapacity.dayOfWeek}>
                    <CFormLabel className={style.dayLabel + ' col-6 col-form-label'}>
                        {moment.weekdays(defaultCapacity.dayOfWeek)}
                    </CFormLabel>
                    <CCol xs={6}>
                        <StandardCapacity
                            id={capacity.id}
                            packageLocation={packagelocation}
                            dayOfWeek={defaultCapacity.dayOfWeek}
                            initialCapacity={defaultCapacity.capacity}
                        />
                    </CCol>
                </CRow>
            ))}
        </div>
    )
}

interface StandardCapacityProps {
    id: string
    packageLocation: string
    dayOfWeek: number
    initialCapacity: number
}

function StandardCapacity({ id, dayOfWeek, initialCapacity }: StandardCapacityProps) {
    const dispatch = useDispatch()
    // TODO permissions
    //const canChange = userHasSomePermissions(useAuthenticatedUser(), 'distribution.write')
    const [updateStandardCapacity] = capacityApi.useEditCapacitiesMutation()
    const [capacity, setCapacity] = useState(initialCapacity)

    const updateCapacity = (newCapacity: number) => {
        executeMutation(
            updateStandardCapacity({
                id: id,
                capacities: { [dayOfWeek]: newCapacity },
            }),
            dispatch,
            'Kapasiteten ble oppdatert',
            'Det skjedde en feil ved oppdatering av kapasitet'
        )
    }

    return (
        <CFormInput
            type="number"
            value={capacity}
            min={0}
            onKeyDown={(e) => {
                if (e.key == 'Enter') updateCapacity(e.currentTarget.valueAsNumber)
            }}
            onBlur={(e) => {
                if (initialCapacity != capacity) updateCapacity(e.currentTarget.valueAsNumber)
            }}
            onChange={(e) => setCapacity(e.currentTarget.valueAsNumber)}
            // TODO permissions
            //disabled={!canChange}
        />
    )
}
