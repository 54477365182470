import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react-pro'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../../auth/authUtils'
import { useAuthenticatedUser } from '../../../auth/authenticationApi'
import { executeMutation } from '../../../common/utils/apiUtils'
import { useRegions } from '../../../region/store/regionApi'
import {
    useDeleteDistributionCompanyMutation,
    useUpdateDistributionCompanyMutation,
    useUpdateDistributionCompanyOrderDeadlineMutation,
} from '../../store/distributionApi'
import { DistributionCompany } from '../../types'
import { DistributionCompanyData, DistributionCompanyForm } from '../DistributionCompanyForm/DistributionCompanyForm'
import style from './DistributionCompanySettings.module.scss'

interface DistributionCompanySettingsProps {
    company: DistributionCompany
}

export function DistributionCompanySettings({ company }: DistributionCompanySettingsProps) {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'distribution.write')
    const canMakeElevatedChanges = userHasSomePermissions(useAuthenticatedUser(), 'distribution.elevatedwrite')
    const [updateDistributionCompany] = useUpdateDistributionCompanyMutation()
    const [updateDistributionCompanyOrderDeadline] = useUpdateDistributionCompanyOrderDeadlineMutation()
    const [deleteDistributionCompany] = useDeleteDistributionCompanyMutation()
    const regionName = useRegions().find((r) => r.id === company.regionId)?.name

    const [isEditMode, setIsEditMode] = useState(false)
    const [editedCompany, setEditedCompany] = useState<DistributionCompanyData>({ ...company })
    const [canSave, setCanSave] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        const allFieldsHaveValue =
            !!editedCompany.name &&
            editedCompany.externalId > 0 &&
            editedCompany.regionId > 0 &&
            !!editedCompany.orderDeadline

        const changesHaveBeenMade =
            editedCompany.name != company.name ||
            editedCompany.externalId != company.externalId ||
            editedCompany.regionId != company.regionId ||
            editedCompany.orderDeadline != company.orderDeadline

        setCanSave(allFieldsHaveValue && changesHaveBeenMade)
    }, [editedCompany])

    const saveChanges = () => {
        executeMutation(
            canMakeElevatedChanges
                ? updateDistributionCompany({ id: company.id, ...editedCompany })
                : updateDistributionCompanyOrderDeadline({
                      id: company.id,
                      orderDeadline: editedCompany.orderDeadline,
                  }),
            dispatch,
            `${editedCompany.name} ble oppdatert`,
            'Kunne ikke oppdatere distribusjonsselskap',
            () => setIsEditMode(false)
        )
    }

    const deleteCompany = () => {
        executeMutation(
            deleteDistributionCompany(company.id),
            dispatch,
            `${company.name} ble slettet`,
            'Kunne ikke slette distribusjonsselskap',
            () => {
                setShowDeleteModal(false)
                setIsEditMode(false)
            }
        )
    }

    return (
        <>
            <div className={style.settings}>
                <h2 className={style.heading}>Selskapsinfo</h2>
                {isEditMode ? (
                    <>
                        <DistributionCompanyForm
                            className={style.content}
                            company={editedCompany}
                            onChange={setEditedCompany}
                            onlyAllowDeadlineChange={!canMakeElevatedChanges}
                        />
                        <div className={style.buttons}>
                            <CButton color="secondary" onClick={() => setIsEditMode(false)}>
                                Avbryt
                            </CButton>
                            <CButton color="primary" disabled={!canSave} onClick={saveChanges}>
                                Lagre
                            </CButton>
                            <CButton
                                color="danger"
                                onClick={() => setShowDeleteModal(true)}
                                disabled={!canMakeElevatedChanges}
                            >
                                Slett selskap
                            </CButton>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.content}>
                            <CRow className="pb-3">
                                <CCol>Id hos DI:</CCol>
                                <CCol>{company.externalId}</CCol>
                            </CRow>
                            <CRow className="pb-3">
                                <CCol>Region:</CCol>
                                <CCol>{regionName}</CCol>
                            </CRow>
                            <CRow className="pb-3">
                                <CCol>Bestillingsfrist:</CCol>
                                <CCol>{moment(company.orderDeadline, 'HH:mm').format('HH:mm')}</CCol>
                            </CRow>
                        </div>
                        <div className={style.buttons}>
                            <CButton color="primary" onClick={() => setIsEditMode(true)} disabled={!canChange}>
                                Endre
                            </CButton>
                        </div>
                    </>
                )}
            </div>
            <CModal visible={showDeleteModal} alignment="center" onClose={() => setShowDeleteModal(false)}>
                <CModalHeader>
                    <CModalTitle>Slett {company.name}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    Sletting fører til at ingen kan bestille til dette distribusjonsselskapet. Er du helt sikker på at
                    du vil slette det?
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setShowDeleteModal(false)}>
                        Avbryt
                    </CButton>
                    <CButton color="danger" onClick={deleteCompany}>
                        Slett selskap
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}
