import { CCard, CCardBody, CCardTitle } from '@coreui/react-pro'
import SubscriptionHistory from './SubscriptionHistory'

interface SubscriptionCardProps {
    customerId: string
}

export default function SubscriptionCard({ customerId }: SubscriptionCardProps) {
    return (
        <CCard>
            <CCardBody>
                <CCardTitle>Fast levering</CCardTitle>
                <CCard>
                    <CCardBody>
                        <CCardTitle>Historikk</CCardTitle>
                        <SubscriptionHistory customerId={customerId} />
                    </CCardBody>
                </CCard>
            </CCardBody>
        </CCard>
    )
}
