import { OrderStatus } from './types'
import { Colors } from '@coreui/react-pro/dist/types'

export function getColorForOrderStatus(orderStatus: OrderStatus): Colors {
    switch (orderStatus) {
        case OrderStatus.ACTIVE:
            return 'success'
        case OrderStatus.CANCELLED:
            return 'warning'
        case OrderStatus.CREDITED:
            return 'info'
        case OrderStatus.FAILED:
            return 'danger'
        default:
            return ''
    }
}
