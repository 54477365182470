import React, { useState } from 'react'
import {
    CFormInput,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
} from '@coreui/react-pro'
import { sortingApi } from '../store/sortingApi'
import { Page } from '../../common'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

export default function SortingPage() {
    const { data: sortingAliases } = sortingApi.useListQuery()
    const [alias, setAlias] = useState('')
    const [aliasFor, setAliasFor] = useState('')
    const [createAlias] = sortingApi.useCreateSortingAliasMutation()
    const [deleteAlias] = sortingApi.useDeleteSortingAliasMutation()
    const dispatch = useDispatch()
    const handleCreateAlias = () => {
        executeMutation(createAlias({ alias, aliasFor }), dispatch, 'Alias opprettet', 'Kunne ikke opprette alias')
    }
    return (
        <Page
            title={'Utsortering'}
            preamble={
                'Her kan du koble bilnummer til alias. Aliaset vil dukke opp på etiketten slik at det blir enklere å\n' +
                '                sortere ut'
            }
        >
            <CTable>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>Alias</CTableHeaderCell>
                        <CTableHeaderCell>Bilnummer</CTableHeaderCell>
                        <CTableHeaderCell>Handling</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {sortingAliases?.map((sortingAlias) => {
                        return (
                            <CTableRow key={sortingAlias.id}>
                                <CTableDataCell>{sortingAlias.alias}</CTableDataCell>
                                <CTableDataCell>{sortingAlias.aliasFor}</CTableDataCell>
                                <CTableDataCell>
                                    <CButton color={'danger'} onClick={() => deleteAlias({ id: sortingAlias.id })}>
                                        Slett
                                    </CButton>
                                </CTableDataCell>
                            </CTableRow>
                        )
                    })}
                    <CTableRow>
                        <CTableDataCell>
                            <CFormInput placeholder={'Alias'} onChange={(e) => setAlias(e.target.value)} />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormInput placeholder={'Bilnummer'} onChange={(e) => setAliasFor(e.target.value)} />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CButton color={'success'} onClick={handleCreateAlias}>
                                Legg til
                            </CButton>
                        </CTableDataCell>
                    </CTableRow>
                </CTableBody>
            </CTable>
        </Page>
    )
}
