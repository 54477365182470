import '@coreui/coreui-pro/dist/css/coreui.min.css'
import { cilMenu } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CContainer, CHeader, CHeaderToggler } from '@coreui/react-pro'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthenticatedUser } from '../../../auth/authenticationApi'
import { logout } from '../../../services/identity'
import { IRootState } from '../../../store'
import { toggleSidebar } from '../../store/commonSlice'
import Router from '../Router/Router'
import Sidebar from '../Sidebar/Sidebar'
import Toaster from '../Toast/Toaster'
import css from './Layout.module.scss'

export function Layout() {
    const dispatch = useDispatch()

    const email = useAuthenticatedUser()?.email
    const sidebarShow = useSelector<IRootState, boolean>((state) => state.common.showSidebar)

    return (
        <div className={css.layout}>
            <Sidebar />
            <div style={{ width: '100%', position: 'relative' }}>
                <CHeader position={'sticky'}>
                    <CContainer fluid>
                        <CHeaderToggler onClick={() => dispatch(toggleSidebar(!sidebarShow))}>
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <div>
                            <span style={{ marginRight: '1rem' }}>{email}</span>
                            <CButton type="submit" color="danger" variant="outline" onClick={logout}>
                                Logg ut
                            </CButton>
                        </div>
                    </CContainer>
                </CHeader>
                <CContainer>
                    <Router />
                </CContainer>
                <Toaster />
            </div>
        </div>
    )
}
