import { Page } from '../../common'
import { FreightTable } from '../components/FreightTable'
import { useGetFreightQuery } from '../store/freightApi'

export function FreightPage() {
    const { data: freight } = useGetFreightQuery()

    if (!freight) {
        return null
    }

    return (
        <Page title="Frakt" preamble="Sett fraktpris og minimumsgrense for gratis frakt per region.">
            <FreightTable freight={freight} />
        </Page>
    )
}
