import { useGetSuppliers2Query } from '../../product/store/supplierApi'
import { useGetProductsQuery } from '../../product/store/productApi'
import { packageLocationApi } from '../../packagelocation/store/packageLocationApi'
import { useDispatch } from 'react-redux'
import { usePurchaseOrder } from './usePurchaseOrder'
import { purchaseOrderApi } from '../store/purchaseOrderApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useHistory } from 'react-router-dom'

export function useCreatePurchaseOrder() {
    const { data: suppliers, isLoading: isSuppliersLoading, error: supplierError } = useGetSuppliers2Query()
    const { data: products, isLoading: isProductsLoading, error: productsError } = useGetProductsQuery()
    const {
        data: locations,
        isLoading: isLocationsLoading,
        error: locationsError,
    } = packageLocationApi.useGetPackageLocationsQuery()
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        purchaseOrder,
        changeSupplier,
        reset: resetPurchaseOrder,
        isValid: isPurchaseOrderValid,
        setPackageLocationId,
        addOrUpdateOrderLine,
        setOrderNo,
    } = usePurchaseOrder()
    const [createPurchaseOrder] = purchaseOrderApi.useCreatePurchaseOrderMutation()
    const isLoading = isSuppliersLoading || isProductsLoading || isLocationsLoading
    const error = supplierError || productsError || locationsError
    const supplierProducts = products?.filter((product) => product.externalSupplierId === purchaseOrder?.supplierId)

    const handleCreatePurchaseOrder = () => {
        if (!isPurchaseOrderValid()) {
            return
        }

        return executeMutation(
            createPurchaseOrder(purchaseOrder),
            dispatch,
            'Opprettet innkjøpsordre',
            'Kunne ikke opprette innkjøpsordre',
            () => {
                resetPurchaseOrder()
                history.push('/innkjøp')
            }
        )
    }

    return {
        isLoading,
        error,
        createPurchaseOrder: handleCreatePurchaseOrder,
        addOrUpdateOrderLine,
        setPackageLocationId,
        setSupplier: changeSupplier,
        packageLocations: locations,
        products: supplierProducts?.filter((product) => !!product.sku && product.bundleProducts.length === 0),
        setOrderNo,
        suppliers,
        purchaseOrder,
    }
}
