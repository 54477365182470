import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { CAlert } from '@coreui/react-pro'

function getErrorMessage(error: FetchBaseQueryError | SerializedError) {
    if ('data' in error) {
        return `Status: ${error.status}\nMelding: ${JSON.stringify(error.data, null, 2)}`
    } else {
        return JSON.stringify(error, null, 2)
    }
}

interface ErrorMessageProps {
    error: FetchBaseQueryError | SerializedError
}

export default function ErrorMessage({ error }: ErrorMessageProps) {
    return (
        <CAlert color={'danger'}>
            <>
                <span>Det skjedde en feil!</span>
                <pre style={{ margin: '1rem 0' }}>{getErrorMessage(error)}</pre>
            </>
        </CAlert>
    )
}
