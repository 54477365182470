import style from './Page.module.scss'

interface PageProps {
    title: string
    preamble?: string
    children: React.ReactNode
}

export function Page({ title, preamble, children }: PageProps) {
    return (
        <div className={style.page}>
            <div className={style.pageLead}>
                <h1 className={style.heading}>{title}</h1>
                {preamble && <p className={style.preamble}>{preamble}</p>}
            </div>
            {children}
        </div>
    )
}
