import { RegisteredProduct } from '../types'
import CardHeader from '../../common/components/CardHeader'
import React, { FormEvent, useEffect, useState } from 'react'
import {
    CButton,
    CCardBody,
    CCardText,
    CCol,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react-pro'
import Card from '../../common/components/Card'
import { addToast } from '../../common/store/commonSlice'
import { useUpdateProductMutation } from '../store/productApi'
import { useDispatch } from 'react-redux'
import { cilCursorMove, cilPencil, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

export default function ProductDimensionsCard({ product }: { product: RegisteredProduct }) {
    const [height, setHeight] = useState<number>(product.dimensions?.height)
    const [length, setLength] = useState<number>(product.dimensions?.length)
    const [width, setWidth] = useState<number>(product.dimensions?.width)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [updateProduct, updateProductResult] = useUpdateProductMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        setHeight(product.dimensions?.height)
        setLength(product.dimensions?.length)
        setWidth(product.dimensions?.width)
        setIsEditing(false)
    }, [product])

    const isAnyChanges =
        height !== product.dimensions?.height ||
        length !== product.dimensions?.length ||
        width !== product.dimensions?.width

    useEffect(() => {
        if (updateProductResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Dimensjoner ble oppdatert',
                    color: 'success',
                })
            )
            setIsEditing(false)
        }

        if (updateProductResult.isError) {
            dispatch(
                addToast({
                    message: 'Kunne ikke oppdatere dimensjonene: ' + updateProductResult.error,
                    color: 'danger',
                })
            )
        }
    }, [updateProductResult])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const updatedProduct = {
            dimensions: {
                height,
                length,
                width,
            },
        }

        updateProduct({ productId: product.id, data: updatedProduct })
    }

    const cancelChanges = () => {
        setHeight(product.dimensions?.height)
        setLength(product.dimensions?.length)
        setWidth(product.dimensions?.width)
    }

    const handleCardActionClick = () => {
        if (isEditing && isAnyChanges) {
            if (!window.confirm('Du har ulagrede endringer. Er du sikker på at du vil avbryte?')) {
                return
            }
            cancelChanges()
        }
        setIsEditing(!isEditing)
    }

    return (
        <Card onAction={handleCardActionClick} actionIcon={isEditing ? cilX : cilPencil}>
            <CardHeader>
                <CIcon icon={cilCursorMove} size={'xl'} className={'mr-1'} />
                Dimensjoner (cm)
            </CardHeader>
            <CCardBody className="pt-2">
                <CForm onSubmit={handleSubmit}>
                    <CRow>
                        <CCol md={4}>
                            {isEditing ? (
                                <CInputGroup className="mb-3" size="sm">
                                    <CInputGroupText style={{ width: '38%' }}>Høyde</CInputGroupText>
                                    <CFormInput
                                        type={'number'}
                                        id="height"
                                        value={height}
                                        onChange={(e) => setHeight(Number(e.target.value))}
                                    />
                                </CInputGroup>
                            ) : (
                                <CCardText>
                                    {height === undefined ? 'Høyde: Ikke satt' : `Høyde: ${height} cm`}
                                </CCardText>
                            )}
                        </CCol>
                        <CCol md={4}>
                            {isEditing ? (
                                <CInputGroup className="mb-3" size="sm">
                                    <CInputGroupText style={{ width: '38%' }}>Lengde</CInputGroupText>
                                    <CFormInput
                                        type={'number'}
                                        id="length"
                                        value={length}
                                        onChange={(e) => setLength(Number(e.target.value))}
                                    />
                                </CInputGroup>
                            ) : (
                                <CCardText>
                                    {length === undefined ? 'Lengde: Ikke satt' : `Lengde: ${length} cm`}
                                </CCardText>
                            )}
                        </CCol>
                        <CCol md={4}>
                            {isEditing ? (
                                <CInputGroup className="mb-3" size="sm">
                                    <CInputGroupText style={{ width: '38%' }}>Bredde</CInputGroupText>
                                    <CFormInput
                                        size={'sm'}
                                        type={'number'}
                                        id="width"
                                        value={width}
                                        onChange={(e) => setWidth(Number(e.target.value))}
                                    />
                                    <CInputGroupText style={{ width: '23%' }}>cm</CInputGroupText>
                                </CInputGroup>
                            ) : (
                                <CCardText>
                                    {width === undefined ? 'Bredde: Ikke satt' : `Bredde: ${width} cm`}
                                </CCardText>
                            )}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol md={4}>
                            <CCardText className="mt-2">
                                {width === undefined || height === undefined || length === undefined
                                    ? 'Volum: -'
                                    : `Volum: ${height * length * width} cm³`}
                            </CCardText>
                        </CCol>
                        <CCol md={8}>
                            {isEditing && (
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <CButton
                                        disabled={updateProductResult.isLoading || !isAnyChanges}
                                        type="submit"
                                        color="primary"
                                        size="sm"
                                    >
                                        {'Lagre'}
                                    </CButton>
                                </div>
                            )}
                        </CCol>
                    </CRow>
                </CForm>
            </CCardBody>
        </Card>
    )
}
