import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { SalesByDeliveryDate } from '../types'
import moment from 'moment'

export const reportsApi = createApi({
    reducerPath: 'reports',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getSalesByDeliveryDate: builder.query<SalesByDeliveryDate[], Date>({
            query: (date) => ({
                url: `/reports/dashboard/${moment(date).format('YYYY-MM-DD')}`,
                method: 'GET',
            }),
        }),
    }),
})

export const useSalesByDeliveryDate = (date: Date) =>
    reportsApi.useGetSalesByDeliveryDateQuery(date, {
        pollingInterval: 1000 * 10,
    })
