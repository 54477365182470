import { CFormInput, CModal, CModalBody, CModalHeader } from '@coreui/react-pro'
import { useState } from 'react'
import { Region } from '../types'
import { useDispatch } from 'react-redux'
import { executeMutation } from '../../common/utils/apiUtils'
import { regionAdminApi } from '../store/regionAdminApi'

interface AbortWMSDeployButtonProps {
    region: Region
    disabled?: boolean
}
export const DeactivateBitlogWmsButton = ({ region, disabled }: AbortWMSDeployButtonProps) => {
    const dispatch = useDispatch()
    const [deactivateBitlogWms] = regionAdminApi.useDeactivateBitlogWmsMutation()
    const [modalState, setModalState] = useState('CLOSED')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const handleButtonClick = () => {
        setModalState('CONFIRM')
    }

    const handleDeactivate = () => {
        executeMutation(
            deactivateBitlogWms({ regionId: region.id, username, password }),
            dispatch,
            'BitLog WMS ble deaktivert',
            'Kunne ikke deaktivere BitLog WMS',
            () => {},
            () => {
                setModalState('CONFIRMED')
            }
        )
    }

    return (
        <>
            <CModal
                visible={modalState !== 'CLOSED'}
                alignment="center"
                onClose={() => {
                    setModalState('CLOSED')
                }}
            >
                <CModalHeader>Avbryt bruk av BitLog WMS for region {region.id}</CModalHeader>
                <CModalBody>
                    {modalState !== 'CONFIRMED' && (
                        <>
                            <p>Du velger nå å deaktivere BitLog WMS for {region.name}. Dette vil:</p>
                            <ul>
                                <li> Skru av all synkronisering til BitLog WMS.</li>
                                <li> Booke alle pakker med passert bookingtid.</li>
                                <li> Bruke Craft for inventar og pakkeflyt.</li>
                                <li> Og mer.</li>
                            </ul>
                            <p>Alle pakker som ikke er ferdig pakket med etikett må pakkes uten BitLog WMS. </p>
                            <p>Er du sikker på at du vil avbryte WMS-deploy for region {region.id}?</p>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-primary"
                                    style={{ marginRight: 'auto', width: '100px' }}
                                    onClick={() => setModalState('CLOSED')}
                                >
                                    Nei
                                </button>
                                <button className="btn btn-danger" onClick={() => setModalState('CONFIRMED')}>
                                    Ja
                                </button>
                            </div>
                        </>
                    )}
                    {modalState === 'CONFIRMED' && (
                        <>
                            <p>Skriv passord og brukernavn for BitLog sin DI bruker:</p>
                            <div className={'p-1'}>
                                <CFormInput placeholder={'Brukernavn'} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className={'p-1'}>
                                <CFormInput
                                    placeholder={'Passord'}
                                    type={'password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <p>Er du sikker på at du vil avbryte WMS-deploy for region {region.id}?</p>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-primary"
                                    style={{ marginRight: 'auto' }}
                                    onClick={() => setModalState('CLOSED')}
                                >
                                    Avbryt
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={handleDeactivate}
                                    disabled={!username || !password}
                                >
                                    Deaktiver WMS for region {region.name}
                                </button>
                            </div>
                        </>
                    )}
                </CModalBody>
            </CModal>
            <button className="btn btn-danger" onClick={handleButtonClick} disabled={disabled}>
                Deaktiver BitLog WMS for {region.name}
            </button>
        </>
    )
}
