import { ProductAvailability } from '../store/inventoryApi'
import { Region } from '../../region/types'
import { ProduceProductAvailabilities } from './ProduceProductAvailabilities'
import { StockProductAvailabilities } from './StockProductAvailabilities'
import { AvailabilityHeader } from './AvailabilityHeader'
import { BundleProductAvailabilities } from './BundleProductAvailabilities'

export function Availabilities(props: { item: ProductAvailability; regions: Region[] }) {
    const { item, regions } = props

    if (isEmpty(item.data)) {
        return <p>Ingen data</p>
    }

    if (item.type === 'PRODUCE') {
        return (
            <div>
                <AvailabilityHeader item={item} title={'Lagerstatus'} />
                <ProduceProductAvailabilities item={item} regions={regions} />
            </div>
        )
    }

    if (item.type === 'BUNDLE') {
        return (
            <div>
                <AvailabilityHeader item={item} title={'Lagerstatus'} />
                <BundleProductAvailabilities item={item} regions={regions} />
            </div>
        )
    }

    if (item.type === 'STOCK') {
        return (
            <div>
                <AvailabilityHeader item={item} title={'Lagerstatus'} />
                <StockProductAvailabilities item={item} regions={regions} />
            </div>
        )
    }

    return <p>Det har skjedd en feil</p>
}

function isEmpty(obj?: any) {
    if (!obj) {
        return true
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object
}
