export const productStatusColor = (status: string) => {
    switch (status) {
        case 'ACTIVE':
            return 'success'
        case 'INACTIVE':
            return 'secondary'
        case 'DELETED':
            return 'danger'
        case 'CREATED':
            return 'warning'
    }
}
export const productStatusText = (status: string) => {
    switch (status) {
        case 'ACTIVE':
            return 'Aktiv'
        case 'INACTIVE':
            return 'Inaktiv'
        case 'DELETED':
            return 'Slettet'
        case 'CREATED':
            return 'Opprettet'
    }
}
