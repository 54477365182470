import {
    CButton,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CMultiSelect,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { Supplier2 } from '../types'
import { useRegions } from '../../region/store/regionApi'
import { packageLocationApi } from '../../packagelocation/store/packageLocationApi'

export default function AddSupplierRegionModal({
    show,
    onClose,
}: {
    show: boolean
    supplier: Supplier2
    onClose: () => void
}) {
    const [visible, setVisible] = useState(false)
    const regions = useRegions()
    const { data: packageLocations } = packageLocationApi.useGetPackageLocationsQuery()

    useEffect(() => {
        setVisible(show)
    }, [show])

    const handleClose = () => {
        setVisible(false)
        onClose()
    }

    const [packageLocation, setPackageLocation] = useState<string | undefined>()

    return (
        <CModal visible={visible} onClose={handleClose}>
            <CModalHeader>
                <CModalTitle>Legg til leverandør region</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CFormSelect label={'Region'}>
                    {regions.map((region) => (
                        <option key={region.id} value={region.id}>
                            {region.name}
                        </option>
                    ))}
                </CFormSelect>
                <CMultiSelect
                    label={'Pakksted'}
                    multiple={false}
                    optionsStyle={'text'}
                    onChange={(e) => setPackageLocation(e.filter((o) => o.selected == true)[0].value as string)}
                    options={packageLocations?.map((pl) => ({ value: pl.name, text: pl.name })) || []}
                />
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => setVisible(false)}>
                    Lukk
                </CButton>
                <CButton color="primary" onClick={() => alert('Ikke implementert')}>
                    Lagre
                </CButton>
            </CModalFooter>
        </CModal>
    )
}
