import { HistoricOrder } from '../../types'
import Delivery from './Delivery'

import style from './OrderGroupDetails.module.scss'

interface OrderGroupDetailsProps {
    orderGroup: HistoricOrder
    onClick?: () => void
}

export default function OrderGroupDetails({ orderGroup, onClick }: OrderGroupDetailsProps) {
    const { orderGroupId, payment } = orderGroup

    return (
        <>
            <table className={style.table} onClick={onClick}>
                <tbody>
                    <tr>
                        <th>OrdergruppeID</th>
                        <td>{orderGroupId}</td>
                    </tr>
                    <tr>
                        <th>Betalingsstatus</th>
                        <td>{!payment ? 'Ingen betaling tilkyttet ordre' : payment.status}</td>
                    </tr>
                    {payment && (
                        <>
                            <tr>
                                <th>MVA</th>
                                <td>{payment.vatAmount.toFixed(2)} NOK</td>
                            </tr>
                            <tr>
                                <th>Rabatt</th>
                                <td>{payment.discountedAmount.toFixed(2)} NOK</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
            <div className={style.cardList}>
                {orderGroup.deliveries.map((delivery) => (
                    <Delivery key={delivery.externalOrderId} delivery={delivery} />
                ))}
            </div>
        </>
    )
}
