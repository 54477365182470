import { CModalBody, CModalFooter, CButton, CSpinner, CAlert } from '@coreui/react-pro'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Status } from '../../../common/types'
import { IRootState } from '../../../store'
import { deleteCustomer, resetCustomerDeletion } from '../../store/customerSlice'
import { Customer } from '../../types'
import CustomerCard from '../CustomerCard'

interface DeleteCustomerModalContentProps {
    customer: Customer
    closeModal: () => void
}

export function DeleteCustomerModalContent({ customer, closeModal }: DeleteCustomerModalContentProps): JSX.Element {
    const deleteStatus = useSelector<IRootState, Status>((state) => state.customer.deleteStatus)
    const deleteError = useSelector<IRootState, string | null>((state) => state.customer.deleteError)

    const dispatch = useDispatch()
    const deleteCustomerHandler = () => dispatch(deleteCustomer(customer.customerId.toString()))

    useEffect(() => {
        return () => {
            if (deleteStatus === Status.Failed || deleteStatus === Status.Succeeded) {
                dispatch(resetCustomerDeletion())
            }
        }
    }, [deleteStatus])

    switch (deleteStatus) {
        case Status.Idle:
            return (
                <>
                    <CModalBody>Du har valgt å slette følgende kunde:</CModalBody>
                    <CustomerCard customer={customer} />
                    <CModalBody>
                        Er du sikker på at du vil slette denne kunden? Sletting er <em>permanent</em> og kan ikke
                        angres.
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="light" onClick={closeModal}>
                            Avbryt
                        </CButton>
                        <CButton color="danger" onClick={deleteCustomerHandler}>
                            Ja, slett kunde
                        </CButton>
                    </CModalFooter>
                </>
            )
        case Status.Loading:
            return <CSpinner />
        case Status.Failed:
            return <CAlert color="warning">{`Noe gikk galt under sletting av kunde: ${deleteError}`}</CAlert>
        case Status.Succeeded:
            return (
                <>
                    <CModalBody>Sletting vellykket</CModalBody>
                    <CButton color="success" onClick={closeModal}>
                        Lukk
                    </CButton>
                </>
            )
    }
}
