import {
    CButton,
    CCol,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react-pro'
import { useState } from 'react'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { useAdminRoles } from '../store/adminRoleApi'

interface CreateUserModalProps {
    visible: boolean
    onClose: () => void
    onCreate: (email: string, role: string, resetFormFunc: () => void) => void
}

export function CreateUserModal({ visible, onClose, onCreate }: CreateUserModalProps) {
    const roles = useAdminRoles()
    const canAssignElevatedRoles = userHasSomePermissions(useAuthenticatedUser(), 'users.elevatedwrite')

    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')

    const reset = () => {
        setEmail('')
        setRole('')
    }

    const create = () => {
        onCreate(email, role, reset)
    }

    return (
        <CModal visible={visible} alignment="center" onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Legg til bruker</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="mb-1">
                    Legg til en ny bruker på Morgenlevering Admin. Brukeren må ha en Schibsted-konto knyttet til
                    e-postadressen.
                </div>
                <CRow className="mb-1/2">
                    <CFormLabel className="col-4 col-form-label">E-post</CFormLabel>
                    <CCol>
                        <CFormInput type="text" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                    </CCol>
                </CRow>
                <CRow>
                    <CFormLabel className="col-4 col-form-label">Rolle</CFormLabel>
                    <CCol>
                        <CFormSelect
                            options={[
                                {},
                                ...roles
                                    .filter((r) => canAssignElevatedRoles || !r.elevated)
                                    .map((r) => ({
                                        value: r.role,
                                        label: r.description ? `${r.role} (${r.description})` : r.role,
                                    })),
                            ]}
                            onChange={(e) => setRole(e.currentTarget.value)}
                            value={role}
                        />
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={onClose}>
                    Avbryt
                </CButton>
                <CButton color="primary" disabled={!email || !role} onClick={create}>
                    Legg til bruker
                </CButton>
            </CModalFooter>
        </CModal>
    )
}
