import { CAlert } from '@coreui/react-pro'
import React from 'react'
import { ProductBadge } from '../types'
interface ProductBadgeCardProps {
    badge?: ProductBadge
}

export default function ProductBadgeCard({ badge }: ProductBadgeCardProps) {
    if (!badge) {
        return <CAlert color={'light'}>Badge mangler</CAlert>
    }
    return (
        <div className={'mb-3'}>
            <h5>Badge</h5>
            <img src={badge.imageUrl} height={'100px'} />
        </div>
    )
}
