import { inventoryApi } from '../store/inventoryApi'
import { CButton, CCollapse } from '@coreui/react-pro'
import React, { useState } from 'react'

interface InventoryHistoryProps {
    id: string
}

export default function InventoryHistory({ id }: InventoryHistoryProps) {
    const [showHistory, setShowHistory] = useState(false)
    const handleShowHistory = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setShowHistory(!showHistory)
    }
    return (
        <>
            <CButton size={'sm'} onClick={handleShowHistory}>
                Vis/skjul historikk
            </CButton>
            <CCollapse visible={showHistory}>{showHistory && <InventoryHistoryList id={id} />}</CCollapse>
        </>
    )
}

function InventoryHistoryList({ id }: InventoryHistoryProps) {
    const { data, isLoading, isSuccess } = inventoryApi.useGetInventoryHistoryQuery(id)
    return (
        <>
            {isLoading && <p>Loading...</p>}
            {isSuccess && (
                <div>
                    {data.map((ih) => (
                        <pre style={{ maxWidth: '100%', whiteSpace: 'pre-wrap' }} key={ih.time}>
                            {ih.data}
                        </pre>
                    ))}{' '}
                </div>
            )}
        </>
    )
}
