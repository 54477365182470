import { useState } from 'react'
import { HistoricOrder } from '../../types'
import { AnimatePresence, motion } from 'framer-motion'
import OrderGroupDetails from './OrderGroupDetails'
import OrderGroupHeader from './OrderGroupHeader'
import { CCard, CCardBody } from '@coreui/react-pro'

import style from './ExpandableOrderGroup.module.scss'

import chevronNext from '../../../assets/images/down_button.svg'

interface ExpandableOrderGroupProps {
    orderGroup: HistoricOrder
}

export default function ExpandableOrderGroup({ orderGroup }: ExpandableOrderGroupProps) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    return (
        <>
            <CCard className={style.orderCard}>
                <CCardBody>
                    <button onClick={() => setIsExpanded(!isExpanded)} className={style.condensedContentContainer}>
                        <OrderGroupHeader orderGroup={orderGroup} />
                        <motion.div animate={{ rotate: isExpanded ? 0 : 180 }} className={style.chevronContainer}>
                            <img src={chevronNext} width={32} height={32} />
                        </motion.div>
                    </button>
                    <AnimatePresence initial={false}>
                        {isExpanded && (
                            <motion.div
                                initial="collapsed"
                                className={style.details}
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: 'auto' },
                                    collapsed: { opacity: 0, height: 0 },
                                }}
                            >
                                <OrderGroupDetails orderGroup={orderGroup} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </CCardBody>
            </CCard>
        </>
    )
}
