import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import SalesReportPage from './SalesReportPage'
import OrderLinesPage from './OrderLinesPage'
import DistributionReportPage from './DistributionReportPage'
import RouteExportPage from './RouteExportPage'

function ReportsPage() {
    const { path } = useRouteMatch()
    return (
        <div>
            <Switch>
                <Redirect exact from={path} to={`${path}/salgstall`} />
                <Route exact path={`${path}/salgstall`}>
                    <SalesReportPage />
                </Route>
                <Route exact path={`${path}/orderlines`}>
                    <OrderLinesPage />
                </Route>
                <Route exact path={`${path}/distribusjon`}>
                    <DistributionReportPage />
                </Route>
                <Route exact path={`${path}/ruteeksport`}>
                    <RouteExportPage />
                </Route>
            </Switch>
        </div>
    )
}

export default ReportsPage
