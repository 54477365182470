import { AuthenticatedContent } from './auth/AuthenticatedContent'
import { Layout } from './common'

function App() {
    return (
        <div className="App">
            <AuthenticatedContent>
                <Layout />
            </AuthenticatedContent>
        </div>
    )
}

export default App
