import { CFormInput, CSmartTable } from '@coreui/react-pro'
import { RegisteredProduct } from '../../product/types'
import { useState } from 'react'
import CIcon from '@coreui/icons-react'
import { cilImage } from '@coreui/icons'
import { OrderLine, PurchaseOrder } from '../types'

interface OrderLineTableProps {
    noItemsLabel?: string
    products: RegisteredProduct[]
    purchaseOrder: PurchaseOrder
    onOrderLineChange: (orderLine: OrderLine) => void
}

export default function OrderLineTable({
    products,
    purchaseOrder,
    onOrderLineChange,
    noItemsLabel = 'Ingen produkter',
}: OrderLineTableProps) {
    const [productQuantities, setProductQuantities] = useState<{ [key: string]: number }>({})
    const [productDescriptions, setProductDescriptions] = useState<{ [key: string]: string }>({})

    const handleOrderLineQuantityChange = (product: RegisteredProduct, quantity: number) => {
        if (quantity < 0) {
            quantity = 0
        }
        setProductQuantities((prev) => ({ ...prev, [product.id]: quantity }))
        onOrderLineChange({
            sku: product.sku,
            title: product.title,
            quantity,
            warehouse: purchaseOrder.packageLocationId,
            description: productDescriptions[product.id] ?? '',
            lineNo: 0,
        })
    }

    const handleProductDescriptionChange = (product: RegisteredProduct, description: string) => {
        setProductDescriptions((prev) => ({ ...prev, [product.id]: description }))
        const quantity = productQuantities[product.id] ?? 0
        if (!quantity) {
            return
        }

        onOrderLineChange({
            sku: product.sku,
            title: product.title,
            quantity: purchaseOrder.orderLines.find((ol) => ol.sku === product.sku)?.quantity ?? 0,
            warehouse: purchaseOrder.packageLocationId,
            description,
            lineNo: 0,
        })
    }

    return (
        <CSmartTable
            items={products}
            itemsPerPage={10}
            noItemsLabel={noItemsLabel}
            columns={[
                {
                    key: 'quantity',
                    label: 'Antall fPak',
                    _style: { width: '10%' },
                    filter: false,
                    sorter: false,
                },
                {
                    key: 'listImageUrl',
                    label: '',
                    filter: false,
                    sorter: false,
                    _style: { width: '5%' },
                },
                {
                    key: 'externalProductId',
                    label: 'Ekstern produktid',
                    _style: { width: '15%' },
                },
                {
                    key: 'title',
                    label: 'Tittel',
                },
                {
                    key: 'orderLineDescription',
                    label: 'Beskrivelse',
                    filter: false,
                    sorter: false,
                },
            ]}
            columnFilter
            columnSorter
            pagination
            scopedColumns={{
                listImageUrl: (item: RegisteredProduct) => (
                    <td style={{ textAlign: 'center' }}>
                        {!item.listImageUrl && <CIcon size={'xl'} icon={cilImage} className="text-danger m-2" />}
                        {item.listImageUrl && (
                            <img src={item.listImageUrl} width={34} height={34} style={{ borderRadius: '50%' }} />
                        )}
                    </td>
                ),
                quantity: (product: RegisteredProduct) => (
                    <td>
                        <CFormInput
                            size={'sm'}
                            type={'number'}
                            value={productQuantities[product.id] ?? 0}
                            disabled={!product.sku}
                            onChange={(event) => handleOrderLineQuantityChange(product, parseInt(event.target.value))}
                            style={{ width: '4rem' }}
                        />
                    </td>
                ),
                orderLineDescription: (product: RegisteredProduct) => (
                    <td>
                        <CFormInput
                            size={'sm'}
                            type={'text'}
                            value={productDescriptions[product.id] ?? ''}
                            onChange={(event) => handleProductDescriptionChange(product, event.target.value)}
                            style={{ width: '10rem' }}
                        />
                    </td>
                ),
            }}
        />
    )
}
