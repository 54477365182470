import { inventoryApi, ProductAvailability } from '../store/inventoryApi'
import { useDispatch } from 'react-redux'
import { executeMutation } from '../../common/utils/apiUtils'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilOptions } from '@coreui/icons'

interface AvailabilityHeaderProps {
    item: ProductAvailability
    title: string
}

export const AvailabilityHeader = ({ item, title }: AvailabilityHeaderProps) => {
    const dispatch = useDispatch()

    const [deleteInventoryManagement] = inventoryApi.useDeleteInventoryManagementMutation()

    const handleDelete = (id: string) => {
        executeMutation(
            deleteInventoryManagement({ id }),
            dispatch,
            'Lagerstyring slettet',
            'Kunne ikke slette lagerstyring',
            () => {}
        )
    }

    return (
        <div className="d-grid gap-2 d-md-flex justify-content-between">
            <h4>{title}</h4>
            <CDropdown>
                <CDropdownToggle color="transparent" caret={false} className="p-2">
                    <CIcon icon={cilOptions} className="fill" size={'lg'} />
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem
                        onClick={() => {
                            handleDelete(item.id)
                        }}
                    >
                        Slett
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </div>
    )
}
