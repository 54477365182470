import {
    CButton,
    CCol,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSwitch,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react-pro'
import { useState } from 'react'
import { useAdminPermissions } from '../store/adminRoleApi'

interface CreateRoleModalProps {
    visible: boolean
    onClose: () => void
    onCreate: (
        role: string,
        description: string,
        elevated: boolean,
        permissions: string[],
        resetFormFunc: () => void
    ) => void
}

export function CreateRoleModal({ visible, onClose, onCreate }: CreateRoleModalProps) {
    const permissions = useAdminPermissions()

    const [role, setRole] = useState('')
    const [description, setDescription] = useState('')
    const [elevated, setElevated] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

    const permissionIsSelected = (permission: string) => selectedPermissions.indexOf(permission) > -1

    const togglePermission = (permission: string) => {
        if (permissionIsSelected(permission)) {
            setSelectedPermissions(selectedPermissions.filter((p) => p !== permission))
        } else {
            setSelectedPermissions([...selectedPermissions, permission])
        }
    }

    const reset = () => {
        setRole('')
        setDescription('')
        setElevated(false)
        setSelectedPermissions([])
    }

    const create = () => {
        onCreate(role, description, elevated, selectedPermissions, reset)
    }

    return (
        <CModal scrollable size="lg" visible={visible} alignment="center" onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Opprett rolle</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="mb-1">Opprett en ny rolle på Morgenlevering Admin.</div>
                <CRow className="mb-1/2">
                    <CFormLabel className="col-3 col-form-label">Navn</CFormLabel>
                    <CCol>
                        <CFormInput
                            type="text"
                            value={role}
                            onChange={(e) => setRole(e.currentTarget.value.toLowerCase().replace(/[^a-z0-9-_]/g, ''))}
                        />
                    </CCol>
                </CRow>
                <CRow className="mb-1">
                    <CFormLabel className="col-3 col-form-label">Beskrivelse</CFormLabel>
                    <CCol>
                        <CFormInput
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.currentTarget.value)}
                        />
                    </CCol>
                </CRow>
                <CRow className="mb-2">
                    <CCol className="offset-3">
                        <CFormSwitch
                            checked={elevated}
                            onChange={(e) => setElevated(e.currentTarget.checked)}
                            label="Krev forhøyede rettigheter for å tildele denne rollen"
                        />
                    </CCol>
                </CRow>
                <CRow className="mb-1/2">
                    <CFormLabel className="col-3 col-form-label">Rettigheter</CFormLabel>
                    <CCol>
                        {[...permissions]
                            .sort((a, b) => a.permission.localeCompare(b.permission))
                            .map((p) => (
                                <CFormCheck
                                    key={p.permission}
                                    label={`${p.description} (${p.permission})`}
                                    checked={permissionIsSelected(p.permission)}
                                    onChange={() => togglePermission(p.permission)}
                                />
                            ))}
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={onClose}>
                    Avbryt
                </CButton>
                <CButton color="primary" disabled={!role.length} onClick={create}>
                    Opprett
                </CButton>
            </CModalFooter>
        </CModal>
    )
}
