import { CCard, CCardBody, CCardTitle } from '@coreui/react-pro'
import { Customer } from '../types'

interface CustomerCardProps {
    customer: Customer
}

export default function CustomerCard({ customer }: CustomerCardProps) {
    return (
        <CCard>
            <CCardBody>
                <CCardTitle>Kundeinfo</CCardTitle>
                <dl>
                    <dt>ID</dt>
                    <dd>{customer.customerId}</dd>

                    <dt>E-post</dt>
                    <dd>{customer.email}</dd>

                    <dt>Schibsted Account ID</dt>
                    <dd>{customer.userId}</dd>
                </dl>
            </CCardBody>
        </CCard>
    )
}
