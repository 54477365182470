import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'
import { CCardBody, CNav, CNavItem, CNavLink, CSpinner } from '@coreui/react-pro'
import { ComposedChart, Line, Tooltip, XAxis, YAxis } from 'recharts'
import React, { useEffect, useState } from 'react'
import { useGetSalesHistoryQuery } from '../store/productApi'
import { SalesByDate } from '../types'
import { cilChartLine } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

export default function SalesHistoryChart(productId: { productId: string }) {
    const { data, isLoading, isSuccess } = useGetSalesHistoryQuery(productId)
    const [activeDataType, setActiveDataType] = useState('salesByPurchaseDate')
    const [chartData, setChartData] = useState<SalesByDate[]>([])
    useEffect(() => {
        if (activeDataType == 'salesByPurchaseDate' && data) {
            setChartData(data.salesByPurchaseDate)
        }
        if (activeDataType == 'salesByDeliveryDate' && data) {
            setChartData(data.salesByDeliveryDate)
        }
    }, [data, activeDataType])
    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilChartLine} size={'xl'} className={'mr-1'} />
                Salgshistorikk
            </CardHeader>
            <CCardBody>
                {isLoading && <CSpinner />}
                {isSuccess && chartData.length == 0 && <p>Ingen salgshistorikk</p>}
                {isSuccess && chartData.length > 0 && (
                    <>
                        <CNav variant="tabs" className={'mb-3'}>
                            <CNavItem>
                                <CNavLink
                                    onClick={() => setActiveDataType('salesByPurchaseDate')}
                                    active={activeDataType == 'salesByPurchaseDate'}
                                >
                                    Salg etter kjøpsdato
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    onClick={() => setActiveDataType('salesByDeliveryDate')}
                                    active={activeDataType == 'salesByDeliveryDate'}
                                >
                                    Salg etter leveringsdato
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <ComposedChart width={500} height={300} data={chartData}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="quantity" stroke="#738DFF" strokeWidth={2} connectNulls />
                        </ComposedChart>
                    </>
                )}
            </CCardBody>
        </Card>
    )
}
