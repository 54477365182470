import { RegisteredProduct } from '../types'
import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'
import { CButton, CCardBody, CMultiSelect, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilBasket, cilImage, cilPencil, cilX } from '@coreui/icons'
import React, { useEffect, useState } from 'react'
import { Option } from '@coreui/react-pro/dist/components/multi-select/types'
import { useUpdateProductBundleMutation } from '../store/productApi'
import { addToast } from '../../common/store/commonSlice'
import { useDispatch } from 'react-redux'

interface BundleProductCardProps {
    product: RegisteredProduct
    products: RegisteredProduct[]
}

export default function ProductBundleCard({ product, products }: BundleProductCardProps) {
    const dispatch = useDispatch()
    const [bundledProducts, setBundledProducts] = useState<string[]>(product.bundleProducts ?? [])
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [updateBundle, updateBundleResult] = useUpdateProductBundleMutation()

    useEffect(() => {
        setBundledProducts(product.bundleProducts ?? [])
        setIsEditing(false)
    }, [product, products])

    //TODO implement correct logic
    const isAnyChanges = true

    const cancelChanges = () => {
        setBundledProducts(product.bundleProducts ?? [])
    }

    const handleCardActionClick = () => {
        if (isEditing && isAnyChanges) {
            if (!window.confirm('Du har ulagrede endringer. Er du sikker på at du vil avbryte?')) {
                return
            }
            cancelChanges()
        }
        setIsEditing(!isEditing)
    }

    const handleMultiSelectChange = (values: Option[]) => {
        setBundledProducts(values.sort((a, b) => a.text.localeCompare(b.text)).map((option) => option.value as string))
    }

    useEffect(() => {
        if (updateBundleResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Bundle ble oppdatert',
                    color: 'success',
                })
            )
            setIsEditing(false)
        }

        if (updateBundleResult.isError) {
            //TODO implement correct error message
            if ('data' in updateBundleResult.error) {
                dispatch(
                    addToast({
                        message: 'Kunne ikke oppdatere bundle: ' + updateBundleResult.error.data.message,
                        color: 'danger',
                    })
                )
            }
        }
    }, [updateBundleResult])

    const handleSubmit = () => {
        const updatedBundle = {
            bundleProducts: bundledProducts,
        }

        updateBundle({ productId: product.id, data: updatedBundle })
    }

    const handleRowClick = (product: RegisteredProduct) => {
        window.location.href = `/produkter/${product.id}`
    }

    const columns = [
        { key: 'listImageUrl', label: '' },
        { key: 'externalProductId', label: 'Ekstern produktid' },
        { key: 'title', label: 'Navn' },
        { key: 'supplierName', label: 'Leverandør' },
    ]

    return (
        <Card onAction={handleCardActionClick} actionIcon={isEditing ? cilX : cilPencil}>
            <CardHeader>
                <CIcon icon={cilBasket} size={'xl'} className={'mr-1'} />
                Produkt bundle
            </CardHeader>
            <CCardBody>
                {!isEditing && product.containedInBundles.length === 0 && bundledProducts.length === 0 && (
                    <p style={{ color: 'var(--cui-red)' }}>Denne bundlen mangler produkter</p>
                )}
                {isEditing && (
                    <>
                        <label className={'form-label'} style={{ color: 'var(--cui-gray)' }}>
                            Velg produkter til produktpakke
                        </label>
                        <CMultiSelect
                            options={products
                                .filter(
                                    (product) => product.status === 'ACTIVE' && !(product.bundleProducts.length > 0)
                                )
                                .map((product) => ({
                                    value: product.id,
                                    text: product.title + ' - ' + product.externalProductId,
                                    selected: bundledProducts.includes(product.id),
                                }))}
                            text="Produktene må være aktivert og kan ikke være en produktpakke."
                            placeholder="Velg produkt"
                            clearSearchOnSelect
                            searchNoResultsLabel={'Ingen produkter funnet'}
                            selectAll={false}
                            virtualScroller
                            onChange={handleMultiSelectChange}
                        />
                    </>
                )}
                {!isEditing && bundledProducts.length > 0 && (
                    <>
                        <p style={{ color: 'var(--cui-gray)' }}>Dette produktet er en bundle av andre produkter:</p>
                        <CTable columns={columns} borderless small hover caption="top" align={'middle'}>
                            <CTableBody>
                                {bundledProducts.map((productId) => {
                                    const product = products.find((p) => p.id === productId)
                                    if (!product) {
                                        return null
                                    }
                                    return (
                                        <CTableRow
                                            key={productId}
                                            onClick={() => handleRowClick(product)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <CTableDataCell>
                                                {!product.listImageUrl && (
                                                    <CIcon size={'xxl'} icon={cilImage} className="text-danger m-2" />
                                                )}
                                                {product.listImageUrl && (
                                                    <img
                                                        src={product.listImageUrl}
                                                        width={56}
                                                        height={56}
                                                        style={{ borderRadius: '50%' }}
                                                        alt="Et bilde av produktet"
                                                    />
                                                )}
                                            </CTableDataCell>
                                            <CTableDataCell>{product?.externalProductId}</CTableDataCell>
                                            <CTableDataCell>{product?.title}</CTableDataCell>
                                            <CTableDataCell>{product?.supplierName}</CTableDataCell>
                                        </CTableRow>
                                    )
                                })}
                            </CTableBody>
                        </CTable>
                    </>
                )}
                {isEditing && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CButton type="button" color="primary" disabled={!isAnyChanges} onClick={handleSubmit}>
                            {'Lagre'}
                        </CButton>
                    </div>
                )}
            </CCardBody>
        </Card>
    )
}
