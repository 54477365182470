import { Redirect, Route, Switch } from 'react-router'
import { userHasSomePermissions } from '../../../auth/authUtils'
import { useAuthenticatedUser } from '../../../auth/authenticationApi'
import routes from './routes'

function Router() {
    const user = useAuthenticatedUser()

    return (
        <Switch>
            {userHasSomePermissions(user, 'distribution.read') && <Redirect exact from="/" to="/distribusjon" />}
            {routes.map((route, i) => {
                return <Route key={i} path={route.path} component={route.component} />
            })}
        </Switch>
    )
}

export default Router
