import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userHasSomePermissions } from '../../../auth/authUtils'
import { useAuthenticatedUser } from '../../../auth/authenticationApi'
import { Capacities } from '../../../common/types'
import { executeMutation } from '../../../common/utils/apiUtils'
import { useDistributionCapacities, useUpdateStandardCapacityMutation } from '../../store/distributionApi'
import style from './StandardCapacityForm.module.scss'

interface StandardCapacityFormProps {
    companyId: number
}

export function StandardCapacityForm({ companyId }: StandardCapacityFormProps) {
    const allCapacity = useDistributionCapacities()
    const [capacity, setCapacity] = useState<Capacities>()

    useEffect(() => {
        setCapacity(allCapacity.find((c) => c.companyId === companyId))
    }, [allCapacity, companyId])

    return (
        <div className={style.form}>
            <h2 className={style.heading}>Standardkapasitet</h2>
            {capacity?.capacities.map((capacity) => (
                <CRow className="mb-1/2" key={capacity.dayOfWeek}>
                    <CFormLabel className={style.dayLabel + ' col-6 col-form-label'}>
                        {moment.weekdays(capacity.dayOfWeek)}
                    </CFormLabel>
                    <CCol xs={6}>
                        <StandardCapacity
                            companyId={companyId}
                            dayOfWeek={capacity.dayOfWeek}
                            initialCapacity={capacity.capacity}
                        />
                    </CCol>
                </CRow>
            ))}
        </div>
    )
}

interface StandardCapacityProps {
    companyId: number
    dayOfWeek: number
    initialCapacity: number
}

function StandardCapacity({ companyId, dayOfWeek, initialCapacity }: StandardCapacityProps) {
    const dispatch = useDispatch()
    const canChange = userHasSomePermissions(useAuthenticatedUser(), 'distribution.write')
    const [updateStandardCapacity] = useUpdateStandardCapacityMutation()
    const [capacity, setCapacity] = useState(initialCapacity)

    const updateCapacity = (newCapacity: number) => {
        executeMutation(
            updateStandardCapacity({
                companyId,
                dayOfWeek,
                capacity: newCapacity,
            }),
            dispatch,
            'Kapasiteten ble oppdatert',
            'Det skjedde en feil ved oppdatering av kapasitet'
        )
    }

    return (
        <CFormInput
            type="number"
            value={capacity}
            min={0}
            onKeyDown={(e) => {
                if (e.key == 'Enter') updateCapacity(e.currentTarget.valueAsNumber)
            }}
            onBlur={(e) => {
                if (initialCapacity != capacity) updateCapacity(e.currentTarget.valueAsNumber)
            }}
            onChange={(e) => setCapacity(e.currentTarget.valueAsNumber)}
            disabled={!canChange}
        />
    )
}
