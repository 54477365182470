export enum Status {
    Idle,
    Loading,
    Succeeded,
    Failed,
}

export interface Toast {
    message: string
    color: 'info' | 'success' | 'warning' | 'danger'
}

export interface Capacities {
    companyId: number
    capacities: Capacity[]
}

export interface Capacity {
    dayOfWeek: number
    capacity: number
}

export interface ErrorResponse {
    data: {
        message: string
    }
    originalStatus?: number
    status: number
}

export type EmptyObject = Record<string, never>
