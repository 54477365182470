import { PurchaseOrderStatus } from './types'

export const purchaseOrderStatusColor = (status: PurchaseOrderStatus) => {
    switch (status) {
        case 'OPEN':
            return 'warning'
        case 'RECEIVED':
            return 'success'
        case 'CANCELLED':
            return 'danger'
        case 'UNKNOWN':
            return 'secondary'
    }
}

export const purchaseOrderStatusText = (status: PurchaseOrderStatus) => {
    switch (status) {
        case 'OPEN':
            return 'Åpen'
        case 'RECEIVED':
            return 'Levert'
        case 'CANCELLED':
            return 'Kansellert'
        case 'UNKNOWN':
            return 'Ukjent'
    }
}
