import { AdditionalProductInformation } from '../types'
import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CAlert,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableRow,
} from '@coreui/react-pro'
import React from 'react'

interface AdditionalInformationTableProps {
    additionalInformation?: AdditionalProductInformation
}

export default function NutritionalInfoCards({ additionalInformation }: AdditionalInformationTableProps) {
    if (!additionalInformation) {
        return <CAlert color={'light'}>Næringsinformasjon mangler</CAlert>
    }
    return (
        <CAccordion flush alwaysOpen>
            {additionalInformation.ingredienser && (
                <CAccordionItem>
                    <CAccordionHeader>Ingredienser</CAccordionHeader>
                    <CAccordionBody>
                        <div dangerouslySetInnerHTML={{ __html: additionalInformation.ingredienser }}></div>
                    </CAccordionBody>
                </CAccordionItem>
            )}
            {additionalInformation.allergener && (
                <CAccordionItem>
                    <CAccordionHeader>Allergener</CAccordionHeader>
                    <CAccordionBody>
                        <div dangerouslySetInnerHTML={{ __html: additionalInformation.allergener }}></div>
                    </CAccordionBody>
                </CAccordionItem>
            )}
            {additionalInformation.nutritionPer100grams && (
                <CAccordionItem>
                    <CAccordionHeader>Næringsinnhold per 100 gram</CAccordionHeader>
                    <CAccordionBody>
                        <CTable>
                            <CTableHead></CTableHead>
                            <CTableBody>
                                {additionalInformation.nutritionPer100grams.map((nutrition, index) => {
                                    return (
                                        <CTableRow key={index}>
                                            <CTableDataCell>{nutrition.type}</CTableDataCell>
                                            <CTableDataCell>{nutrition.value}</CTableDataCell>
                                            <CTableDataCell>{nutrition.unit}</CTableDataCell>
                                        </CTableRow>
                                    )
                                })}
                            </CTableBody>
                        </CTable>
                    </CAccordionBody>
                </CAccordionItem>
            )}
        </CAccordion>
    )
}
