import { CAlert, CBadge } from '@coreui/react-pro'
import React from 'react'
import { ProductAttribute } from '../types'
import { cilTag } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

interface ProductAttributesCardProps {
    attributes: ProductAttribute[]
}

export default function ProductAttributesCard({ attributes }: ProductAttributesCardProps) {
    if (!attributes || attributes.length === 0) {
        return <CAlert color={'light'}>Attributter mangler</CAlert>
    }
    return (
        <div className={'mb-3'}>
            <h5>Attributter</h5>
            {attributes.map((attribute) => {
                return (
                    <CBadge
                        key={attribute.name}
                        color={'secondary'}
                        className={'mb-1'}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {attribute.imageUrl && (
                            <img
                                className={'me-1'}
                                src={attribute.imageUrl}
                                style={{ width: '24px' }}
                                alt={attribute.name}
                            />
                        )}
                        {!attribute.imageUrl && <CIcon icon={cilTag} style={{ width: '24px' }} />}
                        <span style={{ lineHeight: '24px' }}>{attribute.name}</span>
                    </CBadge>
                )
            })}
        </div>
    )
}
