import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import { Supplier2 } from '../../product/types'

interface SelectPackageLocationDropdownProps {
    suppliers?: Supplier2[]
    onSelect: (supplier: Supplier2) => void
    selected?: Supplier2
}

export const SelectSuppliersDropdown = ({ suppliers, onSelect, selected }: SelectPackageLocationDropdownProps) => {
    const sortedSuppliers = [...(suppliers || [])]
    sortedSuppliers?.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    return (
        <CDropdown>
            <CDropdownToggle color={'secondary'}>{selected?.name || 'Velg en leverandør'}</CDropdownToggle>
            <CDropdownMenu
                style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                }}
            >
                {sortedSuppliers.map((location) => (
                    <CDropdownItem key={location.id} onClick={() => onSelect(location)}>
                        {location.name}
                    </CDropdownItem>
                ))}
            </CDropdownMenu>
        </CDropdown>
    )
}
