import React from 'react'
import TestAllNotificationsForm from '../components/TestAllNotificationsForm'
import { Page } from '../../common'

export default function NotificationSettingsPage() {
    return (
        <Page title={'Notifikasjonsinstillinger'}>
            <h2>Eposter</h2>
            <p>
                Her kan du teste alle e-poster som vi sender ut for å se at de blir riktige. I dev og pre blir ikke
                e-poster sendt ut, men kan sees i mandrill
            </p>
            <TestAllNotificationsForm />
        </Page>
    )
}
