import { userHasSomePermissions } from './authUtils'
import { useAuthenticatedUser } from './authenticationApi'

interface AuthorizedContentProps {
    requiredPermissions: string[]
    children: React.ReactNode
}

export function AuthorizedContent({ requiredPermissions, children }: AuthorizedContentProps) {
    return userHasSomePermissions(useAuthenticatedUser(), ...requiredPermissions) ? <>{children}</> : <></>
}
