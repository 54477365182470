import {
    CButton,
    CForm,
    CFormInput,
    CInputGroup,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchCustomer } from '../store/customerSlice'
import { IRootState } from '../../store'
import { Customer } from '../types'
import { useHistory } from 'react-router-dom'
import { Page } from '../../common'

function CustomerSearchPage() {
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('')
    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(searchCustomer(searchTerm))
    }
    const history = useHistory()
    const searchResults = useSelector<IRootState, Customer[]>((state) => state.customer.searchResult)
    return (
        <Page title="Kundesøk">
            <CForm onSubmit={handleSearch}>
                <CInputGroup className="mb-3">
                    <CFormInput
                        placeholder="Søk etter kunde"
                        aria-label="Søk etter kunde"
                        aria-describedby="button-addon2"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <CButton type="submit" color="secondary" variant="outline" id="button-addon2">
                        Søk
                    </CButton>
                </CInputGroup>
            </CForm>
            {searchResults.length > 0 && (
                <CTable hover>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell>Kunde ID</CTableHeaderCell>
                            <CTableHeaderCell>Epost</CTableHeaderCell>
                            <CTableHeaderCell>Schibsted Account ID</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {searchResults.map((customer) => {
                            return (
                                <CTableRow
                                    key={customer.customerId}
                                    onClick={() => history.push(`/kunde/${customer.customerId}`)}
                                >
                                    <CTableDataCell>{customer.customerId}</CTableDataCell>
                                    <CTableDataCell>{customer.email}</CTableDataCell>
                                    <CTableDataCell>{customer.userId}</CTableDataCell>
                                </CTableRow>
                            )
                        })}
                    </CTableBody>
                </CTable>
            )}
        </Page>
    )
}

export default CustomerSearchPage
