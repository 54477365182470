import { CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import style from '../../freight/components/FreightTable.module.scss'
import { FeatureState } from '../types'
import { featureApi } from '../store/featureApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import { FeatureToggle } from './FeatureToggle'

interface FeatureTableProps {
    features: FeatureState[]
}

export function FeaturesTable({ features }: FeatureTableProps) {
    const [toggleFeature] = featureApi.useSetStateMutation()
    const dispatch = useDispatch()
    const toggle = (feature: string, enabled: boolean) => {
        return executeMutation(
            toggleFeature({ feature: feature, enabled: enabled }),
            dispatch,
            `${feature} ble ${enabled ? 'aktivert' : 'deaktivert'}`,
            `Kunne ikke oppdatere ${feature}`
        )
    }

    return (
        <CTable align="middle" borderless className={style.table}>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>Feature</CTableHeaderCell>
                    <CTableHeaderCell>Aktivert</CTableHeaderCell>
                    <CTableHeaderCell />
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {[...features]
                    .sort((a, b) => a.feature.localeCompare(b.feature))
                    .map((f) => (
                        <FeatureToggle key={f.feature} feature={f} onToggle={toggle} />
                    ))}
            </CTableBody>
        </CTable>
    )
}
