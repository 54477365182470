import { CBadge } from '@coreui/react-pro'
import { OrderStatus } from '../types'
import { getColorForOrderStatus } from '../orderstatus'

export interface OrderStatusBadgeProps {
    orderStatus: OrderStatus
}

function getLocalizedOrderStatus(orderStatus: OrderStatus) {
    switch (orderStatus) {
        case OrderStatus.ACTIVE:
            return 'Aktiv'
        case OrderStatus.CANCELLED:
            return 'Kansellert'
        case OrderStatus.CREDITED:
            return 'Kreditert'
        case OrderStatus.FAILED:
            return 'Feilet'
        default:
            return 'Ukjent'
    }
}

export default function OrderStatusBadge({ orderStatus }: OrderStatusBadgeProps) {
    return <CBadge color={getColorForOrderStatus(orderStatus)}>{getLocalizedOrderStatus(orderStatus)}</CBadge>
}
