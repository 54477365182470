import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Status } from '../../common/types'
import authenticatedClient from '../../services/axios'
import { apiUrl } from '../../config'

export interface CompanyCapacityUsage {
    distributionCompanyId: number
    confirmedReservationsSum: number
    capacity: number
}

export interface CapacityUsageView {
    date: string
    companies: CompanyCapacityUsage[]
}

export interface DistributionCalendarState {
    capacityUsageView: CapacityUsageView[]
    status: Status
    error: string | null | undefined
}

const initialState: DistributionCalendarState = {
    capacityUsageView: [],
    status: Status.Idle,
    error: null,
}

interface FetchCapacityUsageViewParams {
    fromDate: string
    toDate: string
}

export const fetchCapacityUsageView = createAsyncThunk<CapacityUsageView[], FetchCapacityUsageViewParams>(
    'distribution/fetchCapacityUsageView',
    async ({ fromDate, toDate }) => {
        const response = await authenticatedClient.get(
            `${apiUrl}/admin/distribution/distribution-companies/capacity-usage-view?fromDate=${fromDate}&toDate=${toDate}`
        )
        return response.data
    }
)

const distributionCalendarSlice = createSlice({
    name: 'distribution-calendar',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCapacityUsageView.pending, (state) => {
            state.status = Status.Loading
        })
        builder.addCase(fetchCapacityUsageView.fulfilled, (state, action) => {
            state.status = Status.Succeeded
            state.capacityUsageView = action.payload
        })
        builder.addCase(fetchCapacityUsageView.rejected, (state, action) => {
            state.status = Status.Failed
            state.error = action.error.message
        })
    },
})

export default distributionCalendarSlice.reducer
