import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { PackageLocation } from '../types'

export const packageLocationApi = createApi({
    reducerPath: 'packageLocation',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['packageLocations'],
    endpoints: (builder) => ({
        getPackageLocations: builder.query<PackageLocation[], void>({
            query: () => ({
                url: `/admin/package-location`,
                method: 'GET',
            }),
            providesTags: ['packageLocations'],
        }),
        exportToWms: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/package-location/wms/export`,
                method: 'POST',
            }),
        }),
    }),
})

export const usePackageLocations = () => packageLocationApi.useGetPackageLocationsQuery().data || []
