import { CBadge } from '@coreui/react-pro'
import { productStatusColor, productStatusText } from '../util'

interface ProductStatusBadgeProps {
    status: string
}

export default function ProductStatusBadge({ status }: ProductStatusBadgeProps) {
    return <CBadge color={productStatusColor(status)}>{productStatusText(status)}</CBadge>
}
