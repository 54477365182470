import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'
import { RegisteredProduct } from '../types'
import { CCardBody } from '@coreui/react-pro'
import { cilTruck } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'

interface ProductPackingInfoCardProps {
    product: RegisteredProduct
}

export default function ProductPackingInfoCard({ product }: ProductPackingInfoCardProps) {
    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilTruck} size={'xl'} className={'mr-1'} />
                Pakkeinformasjon
            </CardHeader>
            <CCardBody>
                {product.isBundle && <p>Produktet kan sampakkes</p>}
                {!product.isBundle && <p>Produktet pakkes alene</p>}
                <p>
                    Navn på etikett: <strong>{product.title}</strong>
                </p>
            </CCardBody>
        </Card>
    )
}
