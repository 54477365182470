import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { PurchaseOrder, PurchaseOrderRequest } from '../types'

export const purchaseOrderApi = createApi({
    reducerPath: 'purchaseOrder',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['PurchaseOrder'],
    endpoints: (builder) => ({
        getPurchaseOrders: builder.query<PurchaseOrder[], void>({
            query: () => ({
                url: '/admin/purchase-orders',
                method: 'GET',
            }),
            providesTags: ['PurchaseOrder'],
        }),
        createPurchaseOrder: builder.mutation<void, PurchaseOrderRequest>({
            query: (purchaseOrder) => ({
                url: '/admin/purchase-orders',
                method: 'POST',
                body: purchaseOrder,
            }),
            invalidatesTags: ['PurchaseOrder'],
        }),
        updatePurchaseOrder: builder.mutation<void, PurchaseOrder>({
            query: (purchaseOrder) => ({
                url: `/admin/purchase-orders/${purchaseOrder.orderNo}`,
                method: 'PUT',
                body: purchaseOrder,
            }),
            invalidatesTags: ['PurchaseOrder'],
        }),
    }),
})

export const { useGetPurchaseOrdersQuery, useCreatePurchaseOrderMutation, useUpdatePurchaseOrderMutation } =
    purchaseOrderApi
