import { ProductCategory } from '../types'
import { CBadge } from '@coreui/react-pro'

interface CategoriesCardProps {
    categories: ProductCategory[]
    heading: string
}

export default function CategoriesCard({ categories, heading }: CategoriesCardProps): JSX.Element {
    function randomColor(id: string) {
        const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark']
        return colors[parseInt(id) % colors.length]
    }

    return (
        <div>
            <h5>{heading}</h5>
            {/* flex row */}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {categories.length === 0 && <CBadge color={'light'}>Kategorier mangler</CBadge>}
                {categories.map((category) => (
                    <CBadge
                        key={category.id}
                        color={randomColor(category.id)}
                        className={'mb-1'}
                        style={{ display: 'flex', alignItems: 'center', width: 'fit-content', marginRight: '5px' }}
                    >
                        <span style={{ lineHeight: '24px' }}>{category.name}</span>
                    </CBadge>
                ))}
            </div>
        </div>
    )
}
