import CardHeader from '../../common/components/CardHeader'
import { CCardBody, CForm } from '@coreui/react-pro'
import Card from '../../common/components/Card'
import React from 'react'
import { RegisteredProduct } from '../types'
import { cilMoney } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import PriceHistoryChart from './PriceHistoryChart'
import { Input } from '../../common/components/Input/Input'

export default function ProductPriceCard({ product }: { product: RegisteredProduct }) {
    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilMoney} size={'xl'} className={'mr-1'} />
                Pris
            </CardHeader>
            <CCardBody>
                <CForm>
                    <div className="mb-3">
                        <Input
                            id="id"
                            label={'Utsalgspris'}
                            value={product.price?.toLocaleString('no-NO', {
                                style: 'currency',
                                currency: 'NOK',
                            })}
                            disabled
                        />
                    </div>
                    <div className="mb-3">
                        <Input
                            id="id"
                            label={'Kostpris'}
                            value={product.costPriceExVat?.toLocaleString('no-NO', {
                                style: 'currency',
                                currency: 'NOK',
                            })}
                            disabled
                        />
                    </div>
                    <div className="mb-3">
                        <Input id="id" value={`${product.vatRate}%`} disabled label={'Moms'} />
                    </div>
                </CForm>
                <PriceHistoryChart productId={product.id} />
            </CCardBody>
        </Card>
    )
}
